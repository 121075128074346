import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useFetch from '../../../hook/hookFetch/fetchHookPost';
import swal from 'sweetalert';
import "./addCompany.css";
import axios  from "axios"
import EditCompany from './editCompany/editcopnay';
import { useDispatch, useSelector } from 'react-redux'
import { getMyCompany } from '../../../redux/reducer/getCompany/action/action';

export default function AddNewCompany() {
  
  const [loading,setLoading] = useState(false)
  const [isCompany,setisCompany] = useState(false)
  const [data,setData] = useState(false)
  const getCompany = useSelector(getCompany=>getCompany.userReducerCompany)
//здесь не получаем данные пользователя через redux
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getMyCompany("/mycopany/getallcompany"))

},[dispatch])

//здесь мы отправляем данные компании в базы данных
    const handleCreatNewCompany=async(e)=>{
      const token = await localStorage.getItem("token")
            e.preventDefault()
           setLoading(true)
            axios.post('/mycopany/api', {
              opf:e.target.opf.value,
              inn:e.target.inn.value,
              address:e.target.addresss.value,
              token:token
            })
            .then(function (response) {
           
             
               swal("отлично!", response.data.message, "success");
               setLoading(false)
             
               dispatch(getMyCompany("/mycopany/getallcompany"))
               axios({
                method: 'post',
                url: '/mycopany/checked',
               
              })
                .then(function (response) {
             
                  console.log(response,"response");
                  dispatch(getMyCompany("/mycopany/getallcompany"))
                });
                
               return
            })
            .catch(function (error) {
             if (error) {
              swal({
                title:error.response.data.message ,
                text: "вы не можете создать компанию!",
                icon: "warning",
              
                dangerMode: true,
              })
             }
              
          
              setLoading(false)
            }).finally(()=>{
              setLoading(false)
              e.target.opf.value =""
              e.target.inn.value=""
              e.target.addresss.value=""
            })    
    }

  

////тут проверяем есть ли уже созданная компания и потом показываем данные
console.log(getCompany,"asd");
  return (
    <div className="addCompanyCont ">
       {getCompany.users.isCompany?
        //компонент уже создан компанией
        <EditCompany data={getCompany.users}/>
       
       :
       <form onSubmit={handleCreatNewCompany}>
       <h1>создание новой компании</h1>
      <input type="text" placeholder="ОПФ" name='opf' required={true} />
      <input type="text" placeholder="ИНН"  name='inn'  required={true}/>
      <input type="text" placeholder="Адрес регистрации" name='addresss'  required={true}/>
      <>

      {loading?
       <Button variant="primary" disabled>
       <Spinner
         as="span"
         animation="border"
         size="sm"
         role="status"
         aria-hidden="true"
       />
     
     </Button>
      :
      <Button variant="primary"   type='submit' onClick={()=> dispatch(getMyCompany("/mycopany/api"))}>подтвердить  </Button>
   

      }
   
      
    
  
    
  </>
    </form>
       }
      
     
    </div>
  );
}
