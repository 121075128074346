import { Switch,FormControl,FormLabel} from "@chakra-ui/react";
export default function BlockUsers({data}) {
  return (
    <>
      <FormControl style={{marginBottom:"10px",marginTop:"10px"}}>
        <FormLabel htmlFor="email-alerts" mb="0">
        Заблокировать этого пользователя
        </FormLabel>
        <Switch id="email-alerts" name="blockUser" defaultChecked={data.amiblocked}/>
      </FormControl>
    </>
  );
}
