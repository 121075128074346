import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Input,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useSelector, useDispatch } from "react-redux";
import { getCaompanyAllAction } from "../../../../../redux/reducer/getAllCompanyForAdmin/action/action";
import { Circle } from "@chakra-ui/react"; // Импортируем Circle из Chakra UI

import "./modalEditUsers.css";
import BlockUsers from "../blockUsers/blockUsers";
import { getUsersAllAction } from "../../../../../redux/reducer/getAllUsersForAdmin/action/action";
import { useToast } from '@chakra-ui/react'
export default function ModalEditUsers({ shows, setShows, data, currentPage }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShows(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const getCompany = useSelector((company) => company);
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();


  useEffect(() => {
    setShow(shows);
    if (shows === true) {
      onOpen()
    } else {
      onClose()
    }
  }, [shows, setShows]);

  const editUser = (e) => {

    e.preventDefault();

    setLoading(true)
    axios
      .patch("/updateusers/api", {
        data: {
          name: e.target.name.value,
          tel: e.target.tel.value,
          email: e.target.email.value,
          userid: data._id,
          blockUser: e.target.blockUser.checked
        },
      })
      .then((rezult) => {
        dispatch(getUsersAllAction(`/getallusers/api?page=${currentPage}`));
        setLoading(false)
        setShows(false)
      }).catch(error => {
        setLoading(false)
        toast({
          position: "top",
          title: 'Account created.',
          description: error.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  };

  function handleCloses() {
    onClose()
    setShows(false)
  }


  return (
    <>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={"full"}>
        <ModalOverlay />
        <ModalContent>


          <ModalHeader>Редактировать пользователей</ModalHeader>
          <ModalBody>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as='span' flex='1' textAlign='left'>
                      Все данные пользователя
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box>
                  <Text fontWeight='bold'>ID:</Text>
                  <Text>{data._id}</Text>

                    <Text fontWeight='bold'>Имя:</Text>
                    <Text>{data.name?data.name:"не указан"}</Text>

                    <Text fontWeight='bold'>Email:</Text>
                    <Text>{data.email?data.email:"не указан"}</Text>

                    <Text fontWeight='bold'>Телефон:</Text>
                    <Text>{data.tel?data.tel:"не указан"}</Text>

                    <Text fontWeight='bold'>telegram:</Text>
                    <Text>{data.telegram?data.telegram:"не указан"}</Text>

                    <Text fontWeight='bold'>whatsUp:</Text>
                    <Text>{data.whatsUp?data.whatsUp:"не указан"}</Text>

                    <Text fontWeight='bold'>Пол:</Text>
                    <Text>{data.gender || "Не указано"}</Text>

                    <Text fontWeight='bold'>Дата регистрации:</Text>
                    <Text>{new Date(data.dateRegistartion).toLocaleDateString()}</Text>
                    <Text fontWeight='bold'>Статус:</Text>
                    <Box display={"flex"} alignContent={"center"} alignItems={"center"} gap={"10px"}>
                      <Text>{data.online ? "Онлайн" : "Офлайн"} </Text>
                      <Circle size='10px' bg={data.online ? 'green.500' : 'red.500'} color='white'> </Circle>
                    </Box>
                    <Text fontWeight='bold'>Блокировка:</Text>
                    <Text>{data.amiblocked ? "Заблокирован" : "Не заблокирован"}</Text>
                    <Text fontWeight='bold'>Первое посещение:</Text>
                    <Text>{data.firstVisit ? "уже был" : "не был вообще"}</Text>
                    <Text fontWeight='bold'>Изображение:</Text>
                    {data.img && (
                      <Box mt={2}>
                        <Avatar size='2xl' name='Kent Dodds' src={`/${data.img}`} />{' '}
                      </Box>
                    )}
                    <Text fontWeight='bold'>Компания:</Text>
                    {data?.mycomanyIDS?.length > 0 ? (
                      <List spacing={3}>
                        {data.mycomanyIDS.map((company, index) => (
                          <div key={index}>
                            <Accordion defaultIndex={[0]} allowMultiple>


                              <AccordionItem>
                                <h2>
                                  <AccordionButton>
                                    <Box as='span' flex='1' textAlign='left'>
                                      Компания#{index + 1}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <ListItem >
                                    <ListIcon as={CheckCircleIcon} color='green.500' />
                                    <Text fontWeight='bold'>_ID</Text> (_ID: {company.companyId})
                                    <Text fontWeight='bold'>Эта компания принадлежит этому пользователю:</Text> ( {data._id==company.adminID?"Да":"Нет"})
                                    <Text fontWeight='bold'>Наимерование продавца</Text> (Наимерование продавца: {company.sellersname})
                                    <Text fontWeight='bold'>ИНН:</Text> (ИНН: {company.inn})
                                  </ListItem>
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>

                          </div>
                        ))}
                      </List>
                    ) : (
                      <Text>Нет компаний</Text>
                    )}


                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <div className="adminSettingsCont">

              <form onSubmit={editUser}>
                <div className="formchildCont1">
                  <label htmlFor="">Имя</label>
                  <Input
                    placeholder="Имя"
                    name="name"
                    defaultValue={data.name}
                    key={data.tel + "1"}
                  />
                  <label htmlFor="">Тел.</label>
                  <Input
                    placeholder="Тел."
                    name="tel"
                    defaultValue={data.tel}
                    key={data.tel + "2"}
                    type="tel"

                  />
                  <div className="chekcbox" color="#5E80E4">
                    {" "}

                  </div>




                </div>

                <div className="formchildCont1">
                  <label htmlFor="">Электронная почта</label>
                  <Input
                    placeholder="Электронная почта"
                    name="email"
                    defaultValue={data.email}
                    key={data.email + "5"}
                  />


                  <BlockUsers data={data} />
                  <div className={"loadingbtn"}>
                    {loading ? (
                      <Button
                        variant="primary"
                        disabled
                        style={{ backgroundColor: "#5E83E0" }}
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        загрузка...
                      </Button>
                    ) : (
                      <Button
                        colorScheme="blue"
                        type="submit"
                        style={{ backgroundColor: "#5E80E4" }}
                        className="creatCompany"
                      >
                        Редактировать
                      </Button>
                    )}

                  </div>

                </div>

              </form>

              {/* <button>Редактировать данные продавца</button> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleCloses}>
              Закрывать
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
