import { useEffect, useState } from 'react';
import {
    Button,
    Heading,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';


import axios from 'axios';
import './myPageAdmin.css';
import { useNavigate } from 'react-router';

export default function MyPageAdmin() {
    const [fileName, setFileName] = useState('');
    const [admingInfo, setAdminInfo] = useState({});
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        tel: ''
    });
    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const editUser = async (e) => {
        e.preventDefault();
    
        const data = new FormData();
        data.append('data', JSON.stringify({
            name: formData.name,
            email: formData.email,
            tel: formData.tel
        }));
    
        if (file) {
            data.append('image', file);
        }
    
        try {
            const response = await axios.patch("/updateMyData/api", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        axios
          .get("/getadmininfo/admin/api")
          .then((response) => {
            setAdminInfo(response.data.data);
          })
          .catch((error) => {
            navigate("/admin");
            console.error(error);
          });
      }, []);
    

    return (
        <>
            <Flex flexDirection={"column"}>
                <Heading w="100%" textAlign={'center'} fontWeight="normal" mb="2%">
                    Данные моего аккаунта
                </Heading>
                <Flex>
                    <FormControl mr="5%">
                        <FormLabel htmlFor="name" fontWeight={'normal'}>
                            Имя Фамилия
                        </FormLabel>
                        <Input 
                            id="name" 
                            name="name" 
                            placeholder="Имя Фамилия" 
                            defaultValue={admingInfo.name}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Flex>
                <FormControl mt="2%">
                    <FormLabel htmlFor="email" fontWeight={'normal'}>
                        Электронная почта
                    </FormLabel>
                    <Input 
                        id="email" 
                        name="email" 
                        type="email" 
                        placeholder='Электронная почта' 
                        defaultValue={admingInfo.email}
                        onChange={handleInputChange}
                    />
                </FormControl>

                <FormControl mt="2%">
                    <FormLabel htmlFor="tel" fontWeight={'normal'}>
                        Тел.
                    </FormLabel>
                    <InputGroup>
                        <InputLeftElement pointerEvents='none'>
                            <PhoneIcon color='gray.300' />
                        </InputLeftElement>
                        <Input 
                            id="tel" 
                            name="tel" 
                            type='tel' 
                            placeholder='Тел.' 
                            value={admingInfo.tel}
                            onChange={handleInputChange}
                            disabled
                        />
                    </InputGroup>
                </FormControl>

                <div className="file-upload">
                    <label className="file-upload-label">
                        {fileName || 'Выберите файл...'}
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="file-upload-input"
                        />
                    </label>
                </div>
            </Flex>
            <Flex marginTop={"10px"}>
                <Button
                    w="7rem"
                    colorScheme="blue"
                    variant="solid"
                    onClick={editUser}
                >
                    Сохранять
                </Button>
            </Flex>
        </>
    );
}
