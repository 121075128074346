export default function PriceFilter({ minPrice, setMinPrice, maxPrice, setMaxPrice }) {
    const clearPrice = () => {
        setMinPrice(0)
        setMaxPrice(0)
    }
    return (
        <div className="priceFilterCont">
            <div>
                <p>Цена</p>
                <p onClick={clearPrice}>Очистить</p>
            </div>
            <div>
                <label htmlFor="priceStart">
                    <p>От</p>
                    <input type="number" value={minPrice} id="priceStart" onChange={(e) => setMinPrice(e.target.value)} />
                </label>
                <label htmlFor="priceEnd">
                    <p>До</p>
                    <input type="number" value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} id="priceEnd" />
                </label>
            </div>
        </div>
    )
}