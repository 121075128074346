const getmyCompanySatate = {
     company: [],
    loading: false,
    error: null
  };
  
  const getMyCompanyAPIReducer = (state = getmyCompanySatate, action) => {
    
    switch (action.type) {
      case 'FETCH_CHANGEAPI_REQUEST':
       
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_CHANGEAPI_SUCCESS':
        
        return {
          ...state,
          loading: false,
          company: action.payload
        };
        
      case 'FETCH_CHANGEAPI_FAILURE':
        return {
          ...state,
          loading: false,
          error: "error"
        };
      default:
        return state;
    }
  };
  
  export default getMyCompanyAPIReducer;
  