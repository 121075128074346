
import { useRef, useState, useEffect, lazy, Suspense } from "react"
import otherFilre from "./iconUpload/otherFilre.png"
import axios from "axios";
import UploadFileEditList from "../uploadFile/downloadBtnFile/downloadBtnFile";
import CommentFileEditList from "../CommentFileEditList/commentFileEditList";



export default function FileRenderComment({ files, deletFile, index, allFiles,item, update, allitem }) {

    const modalRefFile = useRef(null)
    const [slideIndex, setSlideIndex] = useState(index);

    const openModalForShow = () => {
        modalRefFile.current.style.display = "flex"
        console.log(allFiles);
    }

    const closeModal = () => {
        modalRefFile.current.style.display = "none"
    }

    useEffect(() => {
        modalRefFile.current.style.display = "none";
        console.log(allFiles[slideIndex].originalname)
    }, [])


    const next = () => {
        if (slideIndex < allFiles.length - 1) {
            setSlideIndex((prevIndex) => prevIndex + 1);
        }
    }

    const prev = () => {
        if (slideIndex > 0) {
            setSlideIndex((prevIndex) => prevIndex - 1);
        }
    }
    const handleDownload = (item) => {

        const fileURL = `/${item.filename}`; // Adjust path as needed
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = item.filename || 'downloaded_file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

    const downloadFileForLocal = (item) => {

        const fileURL = URL.createObjectURL(item);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = item.name || 'downloaded_file';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(fileURL);

    };
    const handleDeleteLocal = (itemToDelete, event) => {
        deletFile(itemToDelete, event)
    };


    const handleDeleteFile = (itemToDelete, event) => {
        event.stopPropagation()
        axios.post('/deleteFileInComment/api', {
            allitem,
            item,
            itemToDelete
        })
            .then(function (response) {
                console.log(response);
                update()
            })
            .catch(function (error) {
                console.error(error.response);
            });

    };

    return (
        <div className="FilRenderCreat">
            <div onClick={openModalForShow} >
                {
                    (() => {
                        if (files.type == "image/png" || files.type == "image/jpeg") {
                            return <img src={URL.createObjectURL(files)} alt="" onClick={openModalForShow} className={"slide-item"} loading="lazy" />
                        } else if (files.type == "video/mp4") {
                            return <video src={URL.createObjectURL(files)} onClick={openModalForShow} className={"slide-item"} controls loading="lazy"></video>
                        } else if (files.mimetype == "image/png" || files.mimetype == "image/jpeg") {
                            return <img src={`/${files.filename}`} alt="" onClick={openModalForShow} className={"slide-item"} loading="lazy" />
                        } else if (files.mimetype == "video/mp4") {
                            return <video src={`/${files.filename}`} onClick={openModalForShow} className={"slide-item"} loading="lazy"></video>
                        }
                        else {
                            return <img src={otherFilre} alt="" loading="lazy" onClick={openModalForShow} />

                        }
                    })()
                }
                {
                    files && <CommentFileEditList item={files} handleDeleteLocal={handleDeleteLocal} handleDownload={handleDownload} handleDeleteFile={handleDeleteFile} index={index} />
                }
            </div>

            {/* modal open */}
            <div>
                <div>
                    <div className="myModalSliderComment" ref={modalRefFile}>
                        <div className="modal-contentSliderComment">
                            <div className="modal-header">


                                {
                                    (() => {
                                        if (files.type == "image/png" || files.type == "image/jpeg") {
                                            return <p>{allFiles[slideIndex].name}</p>
                                        } else if (files.type == "video/mp4") {
                                            return <p>{allFiles[slideIndex].name}</p>
                                        } else if (files.mimetype == "image/png" || files.mimetype == "image/jpeg") {
                                            return <p>{allFiles[slideIndex].originalname}</p>
                                        } else if (files.mimetype == "video/mp4") {
                                            return <p>{allFiles[slideIndex].originalname}</p>
                                        }
                                        else {
                                            return <p>{allFiles[slideIndex].originalname}</p>
                                        }
                                    })()
                                }

                                <div>
                                    {
                                        files.mimetype ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={() => handleDownload(allFiles[slideIndex].filename)}>
                                                <path d="M1 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            :
                                            ""
                                    }

                                    {
                                        files.type ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none" onClick={() => downloadFileForLocal(files)}>
                                                <path d="M1 17H13" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7 13L3.5 9.5M7 1V13V1ZM7 13L10.5 9.5L7 13Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            :
                                            ""
                                    }

                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" onClick={closeModal}>
                                        <path d="M1.5 1L15.5 15M1.50003 15L8.50003 8L15.5 1" stroke="#767676" stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="slider">


                                    {
                                        (() => {
                                            if (allFiles[slideIndex].type == "image/png" || allFiles[slideIndex].type == "image/jpeg") {
                                                return <img src={URL.createObjectURL(files)} alt="" onClick={openModalForShow} />
                                            } else if (allFiles[slideIndex].type == "video/mp4") {
                                                return <video src={URL.createObjectURL(files)} onClick={openModalForShow} controls ></video >
                                            } else if (allFiles[slideIndex].mimetype == "image/png" || allFiles[slideIndex].mimetype == "image/jpeg") {
                                                return <img src={`/${allFiles[slideIndex].filename}`} alt={`Slide ${slideIndex + 1}`} className="slide-item" onClick={openModalForShow} />
                                            } else if (allFiles[slideIndex].mimetype == "video/mp4") {
                                                return <video src={`/${files.filename}`} onClick={openModalForShow} controls ></video>
                                            }
                                            else {
                                                return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" onClick={openModalForShow} />
                                            }
                                        })()
                                    }
                                    <button className="prev" onClick={() => prev("prev")}>&#10094;</button>
                                    <button className="next" onClick={() => next("next")}>&#10095;</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}   