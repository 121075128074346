const getAllUsersForAdminSatate = {
    allUsers: "",
    loading: false,
    error: null
  };
  
  const getAllUsersForAdminReducer = (state = getAllUsersForAdminSatate, action) => {
    switch (action.type) {
      case 'FETCH_GETUSERSFORADMIN_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_GETUSERSFORADMIN_SUCCESS':
    
        return {
          ...state,
          loading: false,
          allUsers: action.payload
        };
        
      case 'GETUSERSFORADMIN_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getAllUsersForAdminReducer;
  