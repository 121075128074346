
import { useState } from 'react';
import QRGeneratorinterior from '../../../aouth/qrGeneratorFolderInternal/QRGeneratorinterior';
import QRScannerInner from '../qrScannerInnerS/qrScannerInner';
import "./containerForQrAutorizationScannerLight.css"
import "./containerForQrAutorizationScannerDark.css"
import { useSelector } from 'react-redux';
import ModalForInstructionsLight from '../modalForInstructions/modalForInstructions';

export default function ModalForQrAutorizationComponent() {
    const [isScannerActive, setIsScannerActive] = useState(false);
    const getDarkModoe = useSelector(darkModes => darkModes);


    const toggleQR = () => {

        setIsScannerActive(false);
    };

    const toggleScann = () => {
        setIsScannerActive(true);
    };


    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "conteinerToggelBTNContDark" : "conteinerToggelBTNContLight"}>
            <div className='modalheader'>
                <h1>
                 {
                        isScannerActive?
                        "Ваш QR-код для авторизации"
                        :
                        "Отсканируйте QR-код чтобы войти в приложение"
                    }
                </h1>
                <p>
                    {
                        isScannerActive?
                        ""
                        :
                        ""
                    }
                </p>
            </div>
            <div className='modalToggelBTNCont'>
                <button className={isScannerActive ? "" : "active"} onClick={toggleQR}>QR-код</button>
                <button className={isScannerActive ? "active" : ""} onClick={toggleScann}>Сканировать</button>
            </div>
            <div className='modalToggleCont'>
                {isScannerActive ? <QRScannerInner /> : <QRGeneratorinterior isScannerActive={isScannerActive} />}
            </div>
            <ModalForInstructionsLight/>
        </div>
    )
}



