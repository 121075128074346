const initialStateOnline = {
    allOnlineUsers: [],
  };
  
  const AllOnlineUsersReducer = (state = initialStateOnline, action) => {
    
    if (action.type == "SET_ONLINE_USER") {
      return {
        ...state,
    
        allOnlineUsers: action.value, // Use action.payload instead of action.value
      };
    }
  
    return state;
  };
  
  export default AllOnlineUsersReducer;
  