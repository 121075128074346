import React from 'react';
import { Handle, Position } from '@xyflow/react';

const CustomNode = ({ data }) => {
  const handleDelete = () => {
    if (data.onDelete) {
      data.onDelete(data.id); // Передаем идентификатор узла для удаления
    }
  };

  return (
    <div className="custom-node">
      <Handle type="target" position={Position.Top} /> {/* Обработчик для подключения ребер сверху */}
      <div>
        <strong>{data.label}</strong> {/* Заголовок узла */}
        <p>{data.description}</p> {/* Описание узла */}
        {/* Другие данные, которые хотите отобразить */}
      </div>
      <button onClick={handleDelete}>Удалить узел</button> {/* Кнопка для удаления узла */}
      <Handle type="source" position={Position.Bottom} /> {/* Обработчик для подключения ребер снизу */}
    </div>
  );
};

export default CustomNode;
