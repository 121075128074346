import { useEffect } from "react"
import "./scannerSucsses.css"


export default function ScannerSucsses(params) {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.replace("/");
        }, 1500); // 1500 мс = 1.5 секунды, совпадает с длительностью анимации

        return () => clearTimeout(timer);
    }, []); // Убедитесь, что этот эффект выполняется один раз при монтировании компонента

    return (
        <div class="main-containerSucsses">
            <div class="check-containerSucsses">
                <div class="check-backgroundSucsses">
                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7 25L27.3077 44L58.5 7"
                            stroke="white"
                            stroke-width="6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>

    )
}