import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router";



export default function ByAxios(url) {
  const navigate = useNavigate()
  const [data,setData] = useState(null)
  const [error,setError] = useState(null)
  const [loading,setLoading] = useState(false)
  const token =  localStorage.getItem('token')
  const location = useLocation()


  
    const getData=()=>{
   
      try {
        axios.get("/registration/v2/api", {
      }).then(data=>{
          if (data.data.authorization) {
            console.log(data.data.data);
              setData(data.data)
           
          }else{
            if (location.pathname != "/admin" && "/login" && location.pathname !=  "/adminPage"  && location.pathname !=  "/pages") {
              navigate("/login" )
            }
           
         
          }
      }).catch((error)=>{
             
              if (location.pathname != "/admin" && "/login" && location.pathname !=  "/adminPage"  && location.pathname !=  "pages") {
                        navigate("/login" )
              }
          
      })
      
      } catch (error) {
           
        if (location.pathname != "/admin" && "/login" && location.pathname !=  "/adminPage"  && location.pathname !=  "/pages") {
              navigate("/login" )
            }
          
      }
    }
 
   return [data, error, loading,getData]
   
}