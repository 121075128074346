
import { useSelector } from "react-redux"
import "./inDevelopingPage.css"
import "./inDevelopingPageDark.css"
import LogoNatification from "./LogoNatification.png"
import settingCont from "./settingsICon.png"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react"


export default function InDevelopingPage(params) {
    const getDarkModoe = useSelector(darkModes => darkModes)
    const [loadPage,setLoadPage] = useState(false)
    useEffect(() => {
        console.log('Component has successfully mounted.');
        // Your code here
        setLoadPage(true)
      }, []);
  
    const navigate = useNavigate()
            if (loadPage) {
                return <div className={getDarkModoe.ToggleDarkModeReducer.chekced?"devPageContDark":"devPageCont"}>
                <div className="devPageCont1">
                    <img src={LogoNatification} alt="" />
                    <div className="settingImgCont"></div>
                    <div className="devPageContCenter">
                        <div className="Heading">
                            <p className="errorP">503 Сервис недоступен</p>
                            <p className="noPageP">Страница не найдена</p>
                            <p className="infoP">Этот страница в настоящее время находится в разработке</p>
                        </div>
                        <div className="Content">
                            <div className="Links" onClick={()=> navigate("/")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M16.6654 9.16658H6.5237L11.182 4.50825L9.9987 3.33325L3.33203 9.99992L9.9987 16.6666L11.1737 15.4916L6.5237 10.8333H16.6654V9.16658Z" fill="#3B79F6" />
                                </svg>
                                <p>Вернуться домой</p>
        
                            </div>
                        </div>
                    </div>
                    <div className="Links2">
                        <div className="Link"  onClick={() => window.location = 'mailto::support@упрости.рф'}><p>Написать в поддержку</p></div>
        
        
        
        
                    </div>
                </div>
                <div className="devPageCont2">
                    <div className="myimg"></div>
                </div>
            </div>
            }else{
                return <h1 style={{width:"100%",textAlign:'center'}}>Загрузка...</h1>
            }
  
}