
import { Avatar } from '@chakra-ui/react';
import { useSelector } from "react-redux";
import SuppliedEmoticons from "../supplied emoticons/suppliedEmoticons";
import "./replyaMessageItemLight.css"
import "./replyaMessageItemDark.css"
import OpenModalForSubComANDSmile from "../openModalForSubComANDSmile/openModalForSubComANDSmile";
import React, { useEffect, useState } from "react";
import FileModal from "../fileModal/fileModal";
import WhoReadThisMessage from '../who read this message/whoReadThisMessage';
import moment from 'moment';



 function ReplyMessageItem({ item, scrollToItem, child,index }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const [isOpenModal, setisOpenModal] = useState(false);




    const handleContextMenu = (e) => {
        e.preventDefault();
        setisOpenModal(!isOpenModal);
    };


    function convertDate(params) {
        // Парсим строку в объект Date
        const dateString = item.createdAt;
        const dateObject = new Date(dateString);

        // Получаем компоненты времени
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";

        // Конвертируем часы в 12-часовой формат
        const twelveHourFormat = hours % 12 || 12;

        // Форматируем минуты, чтобы было две цифры
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Собираем результат в нужный формат
        const formattedTime = `${twelveHourFormat}:${formattedMinutes} ${period}`;

       return moment(dateObject).format("HH:mm") + "  PM"

    }

    useEffect(() => {
        const text = item.messageText;
        const strong = /\*\*(.*?)\*\*/g; // Регулярное выражение для поиска текста, заключенного в двойные звездочки
        const matchesStrong = text.match(strong); // Ищем совпадения в тексте
        if (matchesStrong) {
            const newText = text.replace(strong, '<strong>$1</strong>'); // Заменяем текст внутри двойных звездочек на <strong> элементы
            console.log("Измененный текст:", newText);

            // Устанавливаем новый текст внутрь <p> элемента
            const messageTextElement = document.querySelector(`.messageText-${item._id}`);
            console.log(messageTextElement, "messageTextElement");
            messageTextElement.innerHTML = newText;
        }
        const civiy = /^\*(.*?)\*$/;// Регулярное выражение для поиска текста, заключенного в двойные звездочки
        const matchesciviy = text.match(civiy); // Ищем совпадения в тексте
        if (matchesciviy) {
            const newText = text.replace(civiy, '<i>$1</i>'); // Заменяем текст внутри двойных звездочек на <strong> элементы
            console.log("Измененный текст:", newText);

            // Устанавливаем новый текст внутрь <p> элемента
            const messageTextElement = document.querySelector(`.messageText-${item._id}`);
            console.log(messageTextElement, "messageTextElement");
            messageTextElement.innerHTML = newText;
        }

    }, [item]);




    return (

        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "replyMessageContDark" : "replyMessageContLight"} onClick={(e) => scrollToItem(item,e)} onContextMenu={handleContextMenu} id={item._id}>
            <div>
                <span></span>
                <div>
                    <p>{item.senderInfo.name}</p>
                    <p>{item.messageReplyID.messageText}</p>
                </div>
            </div>

            <div>
                <div className="openModalForAddSubCommentAndSmileContRelative">
                    {/* DROWER для того чтобы открыть остальные функционал для сообщений */}
                    <OpenModalForSubComANDSmile isOpenModal={isOpenModal} setisOpenModal={setisOpenModal} item={item} child={child} index={index}/>
                </div>
                <Avatar src={item.senderInfo.img ? `/${item.senderInfo.img}` : "https://bit.ly/dan-abramov"} />
                <div className="messageInfoCont">
                    <div className="frame19">
                        <p className="name">{item.senderInfo.name ? item.senderInfo.name : "анонимный"}</p>
                        <p className="clock">{convertDate()}</p>
                        {
                            item.isImportantMessage ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M10.7751 2.85007C9.90121 0.160654 6.0964 0.160654 5.22256 2.85007C4.96564 3.64079 4.22878 4.17615 3.39737 4.17615C0.569552 4.17615 -0.606198 7.79473 1.68156 9.45688C2.35418 9.94557 2.63564 10.8118 2.37872 11.6025C1.50487 14.2919 4.58302 16.5283 6.87078 14.8662C7.5434 14.3775 8.45421 14.3775 9.12684 14.8662C11.4146 16.5283 14.4927 14.2919 13.6189 11.6025C13.362 10.8118 13.6434 9.94557 14.3161 9.45688C16.6038 7.79473 15.4281 4.17615 12.6002 4.17615C11.7688 4.17615 11.032 3.64079 10.7751 2.85007Z" fill="#F4E02B" />
                                </svg>
                                :
                                ""
                        }
                        <div>
                            {/* кто читал это сообщение */}
                             <WhoReadThisMessage item={item} />
                        </div>
                    </div>
                    <div className="frame41">
                        <p className={`messageText-${item._id}`}>{item.messageText}</p>
                    </div>
                    < SuppliedEmoticons item={item} child={child} />
                    <FileModal item={item} child={child} />
                </div>
            </div>

        </div>
    )
}


export default React.memo(ReplyMessageItem);
