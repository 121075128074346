import { useRef, useState, useEffect } from "react";
import "./assignUsersDropDownLight.css";
import "./assignUsersDropDownDark.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"
import { useSearchParams } from "react-router-dom";
import { getChatInfoByIDAction } from "../../../../redux/reducer/getChatInfoByID/action/action";
export default function AssignUsersDropDown({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const handleDrop = useRef();
  const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
  const [searchParams] = useSearchParams();
  const chatID = searchParams.get('chatID');
  const getDarkModoe = useSelector(darkModes => darkModes);

  const dispatch = useDispatch();
  const handleDropFunc = (e) => {
    setIsOpen(!isOpen);
    e.stopPropagation();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (handleDrop.current && !handleDrop.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleDrop]);

  // если пользователь является редактором тогда можем удалить Если нет тогда можем добавить
  const handleUpdateEditors = async () => {
    try {
      // console.log(item._id);
      // getChatInfo.data.chatOptions.editors
      const res = await axios.put('/updateEditors/api', {
        userID: item._id,
        chatID: getChatInfo.data._id


      });

      setIsOpen(!isOpen);
      if (chatID !== null) {
        dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
      }
    } catch (error) {
      console.error(error);
      throw new Error("ошибка при удалении и при добавлении редактора")
    }


  }
  // здесь удаляем пользователя из чата
  // и если является редактором тогда из редакторов тоже
  const handleDeletUserInChat = async (e) => {
    const res = await axios.put('/handleDeleteUserInChat/api', {
      userID: item._id,
      chatID: getChatInfo.data._id


    });
    setIsOpen(!isOpen);
    if (chatID !== null) {
      dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
    }
    e.stopPropagation();
  }

  return (
    <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownForUserDark" : "dropdownForUser"}>
      <button onClick={(e)=>handleDropFunc(e)} className="openBTN">
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
          <circle cx="2.29922" cy="1.9999" r="1.6" fill="#767676" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
          <circle cx="2.29922" cy="1.9999" r="1.6" fill="#767676" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
          <circle cx="2.29922" cy="1.9999" r="1.6" fill="#767676" />
        </svg>
      </button>
      <div className="dropdown-content" ref={handleDrop} style={{ display: isOpen ? 'flex' : 'none' }}>
        <ul>
          <li>
            <button onClick={handleUpdateEditors}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M1.5 19H10.5H19.5" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.7237 3.82843L15.6735 8.77814M10.7237 3.82843L13.5522 1L18.5019 5.94975L15.6735 8.77814L10.7237 3.82843ZM10.7237 3.82843L5.11711 9.43504C4.92958 9.62254 4.82422 9.87694 4.82422 10.1421V14.6776H9.35979C9.62499 14.6776 9.87929 14.5723 10.0669 14.3847L15.6735 8.77814L10.7237 3.82843Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Дать редактирование
            </button>
          </li>
          <li>
            <button onClick={(e)=> handleDeletUserInChat(e)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                <path d="M17.5 9V18.4C17.5 18.7314 17.2314 19 16.9 19H4.1C3.76863 19 3.5 18.7314 3.5 18.4V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.5 15V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.5 15V9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.5 5H14.5M19.5 5H14.5H19.5ZM1.5 5H6.5H1.5ZM6.5 5V1.6C6.5 1.26863 6.76863 1 7.1 1H13.9C14.2314 1 14.5 1.26863 14.5 1.6V5H6.5Z" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              Удалить
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
