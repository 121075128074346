import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    Button
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { useToast } from '@chakra-ui/react'


export default function Deldb() {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()
    const [seconds, setSeconds] = useState(60); // Initial countdown time in seconds
    const [isStartTime, setisStartTime] = useState(false)
    const toast = useToast()

    useEffect(() => {
        if (isStartTime) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 0) {
                        clearInterval(timer); // Stop the timer when it reaches 0
                        // You can add additional logic here when the timer reaches 0
                        startProcessDeleteBD()
                        console.log("Удалить всю базу данных");
                        onClose()
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);

            // Cleanup function to clear the interval when the component is unmounted
            return () => clearInterval(timer);
        }

    }, [isStartTime]);

    const deleteDB = () => {

        setisStartTime(true)


    }

    const canchelDbsDelete = () => {
        setSeconds(60)
        setisStartTime(false)
        onClose()
    }

    const startProcessDeleteBD = () => {
        toast({
            position: "top",
            title: 'Account created.',
            description: "We've created your account for you.",
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    }

    return (
        <div className='alertDialog'>
            <Button colorScheme='red' onClick={onOpen}>
                удалить базу данных
            </Button>


            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            удалить базу данных
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Если вы нажмете «Удалить», все базы данных будут удалены.
                            {seconds}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={canchelDbsDelete} className='closeDialog'>
                                Отмена
                            </Button>
                            <Button colorScheme='red' className='deletedbs' onClick={deleteDB} ml={3}>
                                Удалить
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </div>
    )
}