// actions.js
import axios from 'axios';

export const getAllTask = (url) => {
  return (dispatch) => {
   
    dispatch({ type: 'FETCH_GETALLTASK_REQUEST' });
    axios.get(url)
      .then(response => {
       
        dispatch({
          type: 'FETCH_GETALLTASK_SUCCESS',
          payload: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_GETALLTASK_FAILURE',
          payload: error.message
        });
      });
  };
};
