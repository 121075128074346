import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useEffect, useState } from 'react';



export default function CheckedBool({item}) {
    const [value,setValue] = useState([])
    let atributeParams =  {
        complex_id: 0,
        id: item.id,
        values: [
          {
                value:"false"
          }
        ]
      }

useEffect(()=>{
    setValue(atributeParams)
},[])

    const changeBool=(e)=>{
  
                if (e.target.checked) {
                    atributeParams.values[0].value = "true"
                }else{
                    atributeParams.values[0].value = "false"
                }
       
        setValue(atributeParams)
        console.log(atributeParams);

      
}
            return  (
                <>
                    <Checkbox  required={item.is_required} onChange={(e)=>changeBool(e)} name="checkobx">{item.name}</Checkbox>
                    <input type="hidden" name="boolvalue" defaultValue={JSON.stringify(value)} key={item}/>
                </>
            )
}