import axios from 'axios';

export const fetchUsers = (e) => {
  return (dispatch) => {
      
    dispatch({ type: 'FETCH_USERS_REQUEST' });
    axios.get(`/alldeploy/api?searchWorkers=${e}`)
      .then(response => {
          
        dispatch({
          type: 'FETCH_USERS_SUCCESS',
          payload: response.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_USERS_FAILURE',
          payload: error.message
        });
      });
  };
};