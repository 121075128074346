const darkState = {

    chekced:false,
  
    
  };
  
  const useChangeDarkReducer = (state = darkState, action) => {
             
            if (action.type == "changeDarkMode") {
                return{
                    ...state,
                    
                    chekced:state.chekced = action.value,
             
                }
            }

            return state
  };
  
  export default useChangeDarkReducer;
  