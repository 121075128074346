import React, { useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import axios from 'axios'; // Убедитесь, что axios установлен
import './qrScanner.css';
import sockets from '../../../socket/socket';
import { useToast } from '@chakra-ui/react';
import ScannerSucsses from '../scannerSucsses/scannerSucsses';
import notification from "./notification.mp3"
const socket = sockets;


const QRScannerOuth = () => {
  const [isSupported, setIsSupported] = useState(true);
  const [facingMode, setFacingMode] = useState('environment'); // По умолчанию задняя камера
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [loading, setLoading] = useState(false); // Для отслеживания состояния запроса
  const [sucsses, setsucsses] = useState(""); // Для отслеживания состояния запроса
  const toast = useToast({ position: 'top' });

  useEffect(() => {
    // Проверяем, поддерживает ли браузер доступ к камере
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setIsSupported(false);
    } else {
      // Получаем список устройств
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind === 'videoinput');
          if (videoDevices.length === 0) {
            setIsSupported(false);
            toast({
              title: "Ошибка",
              description: "Камеры не найдены.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            console.log('Доступные камеры:', videoDevices);
          }
        })
        .catch(err => {
          console.error('Ошибка при получении устройств:', err);
          toast({
            title: "Ошибка",
            description: "Ошибка при получении доступных камер.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, []);

  const handleScan = (data) => {
    if (data) {
      setQrCodeValue(data.text);
      sendQrCodeToServer(data.text); // Отправляем QR-код на сервер
    }
  };

  const handleError = (err) => {
    console.error(err);
    toast({
      title: err,
      description: err,
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) =>
      prevMode === 'environment' ? 'user' : 'environment'
    );
  };

  const sendQrCodeToServer = async (qrCode) => {
    setLoading(true);
    try {
      const response = await axios.post('/qrCodeAutorization/v3/api', {
        qrCode, // отправляем QR-код
      });
      socket.emit('pushmyid', response.data.datas._id);
      if (response.data.authorization) {
        const notificationSound = new Audio(notification); // Создайте новый объект Audio
        const playNotification = () => {
          notificationSound.play(); // Воспроизведите звук
      };
      playNotification();
        setsucsses("sucsses")
       
      }
    } catch (error) {
      setsucsses("error")
      console.error('Ошибка при отправке запроса:', error);
      toast({
        title: error.response.data.message,
        description: error.response.data.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);

      setQrCodeValue("");
    }
  };

  if (!isSupported) {
    return <p>Ваш браузер не поддерживает доступ к камере или камеры не найдены.</p>;
  }
  if (sucsses==="sucsses") {
    return <ScannerSucsses />
  }
  return (
    <div className="qr-scanner-container">
      <div className="qr-scanner-header">
       
        <p>{qrCodeValue}</p>
      </div>
      <div className="qr-scanner-wrapper">
        <div className="qr-video-wrapper">
          <QrScanner
            delay={300}
            constraints={{
              video: { facingMode },
            }}
            onError={handleError}
            onScan={handleScan}
          />
          <div className="qr-overlay"></div>
        </div>
        <button onClick={toggleCamera} className="camera-toggle-button">
          Переключить камеру ({facingMode === 'environment' ? 'Задняя' : 'Фронтальная'})
        </button>
      </div>
    </div>
  );
};

export default QRScannerOuth;
