import { useEffect, useRef, useState } from "react"
import "./navLeftBarMobileLight.css"
import { useDispatch, useSelector } from "react-redux";
import { toggleDarkModeAction } from "../../../redux/reducer/toggleDarkMode/action/action";
import { toggleMenuAction } from "../../../redux/reducer/toggleMenu/action/action";
const isDarkstorage = JSON.parse(localStorage.getItem('isDark'));

export default function NavLeftBarMobile() {
    const toggleMenu = useSelector(toggleMenu => toggleMenu);
    const toggleMobileMenu = useRef();
    const dipatch = useDispatch()
     useEffect(()=>{
           
                if (toggleMenu.ToggleMenuReducer.toggleMenu == true) {
                    toggleMobileMenu.current.style.width = "100%"
                }else{
                    toggleMobileMenu.current.style.width = "0"
                }
     },[toggleMenu.ToggleMenuReducer.toggleMenu])
   
   const closeDow=()=>{
    dipatch(toggleMenuAction(!toggleMenu.ToggleMenuReducer.toggleMenu))
   }  
    return (
        <div id="mySidenav" className="sidenav" ref={toggleMobileMenu}>
            <a href="#" className="closebtn" onClick={closeDow}>&times;</a>
            <a href="#">About</a>
            <a href="#">Services</a>
            <a href="#">Clients</a>
            <a href="#">Contact</a>
        </div>
    )
}