import { useEffect, useRef } from "react"


export default function SearchToggleForTop({toggleSearchCont,hanldetoggleSearch}) {

    return (
        <div className="toggleSreachCont">
        
            <label>
                <input type="text" placeholder="Какой-то поиск" />
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#353535" />
                        <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#353535" />
                    </svg>
                </div>
            </label>
            <button onClick={hanldetoggleSearch}>
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#353535" />
                </svg>
                </div>
            </button>
        </div>
    )
}