import { useState } from "react"
import PlusemojiDropDownPicker from "../EmojiDropDownPickerPlus/emojiDropDownPickerPlus"

export default function SmileForMessageItems({child,setValue,value,addLike}) {
        
    return (
        <div>
            <PlusemojiDropDownPicker setValue={setValue} value={value} addLike={addLike}/>
        </div>
    )
}