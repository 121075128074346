// reducer.js
// для того чтобы открыть остальные функционал для редактирования сообщений и ответа сообщений
import { IS_EDIT_MESSAGE, REPLY_TO_MESSAGE, UPDATE_DATA, ENABLED_ALL } from './action/action';

const initialState = {
    editMessage: false,
    replyMessage: false,
    data: {},
};

const otherMenuOptionsForMessageItemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_EDIT_MESSAGE:
            return {
                ...state,
                data: action.value, // Обновляем data только для IS_EDIT_MESSAGE
                editMessage: true,
                replyMessage: false
            };
        case REPLY_TO_MESSAGE:
            return {
                ...state,
                data: action.value, // Обновляем data только для REPLY_TO_MESSAGE
                editMessage: false,
                replyMessage: true
            };
        case ENABLED_ALL:
            return {
                ...state,
                editMessage: false,
                replyMessage: false,
                data: {}
            };
        case UPDATE_DATA:
            return {
                ...state,
                data: action.value, // Обновляем data только для UPDATE_DATA
            };
        default:
            return state;
    }
};

export default otherMenuOptionsForMessageItemsReducer;
