import {

    TableContainer,
  } from '@chakra-ui/react'
import OzonEditInput from '../editinput/ozonEditInput';
import OzoModalEdit from '../ozonModalEdit/ozomodalEdit';
import { Avatar } from '@chakra-ui/react'
import axios from 'axios';
import {  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { useToast } from '@chakra-ui/react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'


import "./ozontable.css"
import "./ozontableDark.css"
  export default function DableOzon(params) {

        const [loading,setLoading] = useState(false)
        const getLisProduct = useSelector(getLisProduct=>getLisProduct)
    const disptach = useDispatch()
    const toast = useToast()
    const getDarkModoe = useSelector(darkModes => darkModes )





console.log(getLisProduct.getOzonisListReducer);
    if (getLisProduct.getOzonisListReducer.error) {
        return (
         <div className='alertError'>
             <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Ошибка</AlertTitle>
          <AlertDescription>Нет токенов</AlertDescription>
        </Alert>
         </div>
        )
    }

    if (getLisProduct.getOzonisListReducer.loading) {
      return (
        <div >
            <div class="custom-loader"></div>
        </div>
    )
    }
if (getLisProduct.getOzonisListReducer.ozon == '' || getLisProduct.getOzonisListReducer.ozon.data == null) {
        return (
            <div  >
                <div>безрезультатно</div>
            </div>
        )
}


    return(
      
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ozonTableDark":"ozonTable"}style={{height:"60vh"}} >
            
        <div className='loadingContwb'>
        {/* <div className="custom-loader"></div> */}
        </div>
          
          <div className="containerwbTable" >
                <table id="customers" className='customers'>
                  {/* for Dekstop */}
                <thead className='ForDekstopWbTheadTr'>
                <tr >
                  
                    <th>Номер</th>
                    <th>Фото</th>
                    <th>Категория</th>
                    <th>Наименование</th>
                    <th>Цена</th>
                    <th>Цена до скидки</th>
                    <th>Артикул продавца</th>

                    <th>Редактировать</th>
                    
                
                  </tr>
                </thead>
                 
                  <tbody className='ForDekstopWbTBodyTr'>
                  {(getLisProduct.getOzonisListReducer.ozon.data !="")&&getLisProduct.getOzonisListReducer.ozon.data.allProduct[0]?.map((item,index)=>{
                        
                                return (
                                    <tr key={index}>
                                   
                                    <td style={{textAlign:"center"}} className='indexTDWBOZON'>{index + 1}</td>
                                    
                                    {(item.images.length > 0)?
                                    <td> <Avatar name='Dan Abrahmov' src={item.images[0].file_name} /></td>
                                    :
                                    <td>  <Avatar name='Sasuke Uchiha' src='https://bit.ly/broken-link' /></td>
                                    }
                                    <td>
                                    <div className='ContINput'>
                                        <textarea name="" id=""  disabled>
                                        {item.catigoryName}
                                        </textarea>
                                        <div style={{height:"26px"}}></div>
                                        </div>

                                    </td>
                                    <td><OzonEditInput item={item.productName} nameis={"productName"} allitem={item}/></td>
                                    <td><OzonEditInput item={item.price}  nameis={"pirce"} allitem={item}/></td>
                                    <td>
                                        <div className='ContINput'>
                                        <textarea name="" id=""  disabled>
                                        {item.oldPrice.slice(0, -2)}
                                        </textarea>
                                        <div style={{height:"26px"}}></div>
                                        </div>
                                        
                                    </td>
                                    <td><OzonEditInput item={item.arcticule} nameis={"arcticule"} allitem={item}/></td>
                                    

                                   
                                    
                                    <td className='edit'><OzoModalEdit  item={item}/></td>
                                </tr>
                                )
                        })}
                   
                 
                  
                  </tbody>
        
        
                  {/* formobile */}
        
                </table>







{/* for mobile */}



                <TableContainer className='MobileContWb'>
                             <table id="" className=''>
                  {/* for Dekstop */}
                <thead className='ForDekstopWbTheadTr'>
                <tr >
                  
                    <th>Номер</th>
                    <th>Фото</th>
                    
                    <th>Наименование</th>
                   

                    <th>Редактировать</th>
                    
                
                  </tr>
                </thead>
                 
                  <tbody className='ForDekstopWbTBodyTr'>
                  {(getLisProduct.getOzonisListReducer.ozon.data !="")&&getLisProduct.getOzonisListReducer.ozon.data.allProduct[0]?.map((item,index)=>{
                        
                                return (
                                    <tr key={index}>
                                   
                                    <td style={{textAlign:"center"}}>{index + 1}</td>
                                    
                                    {(item.images.length > 0)?
                                    <td> <Avatar name='Dan Abrahmov' src={item.images[0].file_name} /></td>
                                    :
                                    <td>  <Avatar name='Sasuke Uchiha' src='https://bit.ly/broken-link' /></td>
                                    }
                                   
                                    <td><OzonEditInput item={item.productName} nameis={"productName"} allitem={item}/></td>
                                   
                                    

                                   
                                    
                                    <td className='edit'><OzoModalEdit  item={item}/></td>
                                </tr>
                                )
                        })}
                   
                 
                  
                  </tbody>
        
        
                  {/* formobile */}
        
                </table>
              </TableContainer>
             
                    
              </div>
                            
        </div>
    
        );
  }