const getAllAdminState = {
    allAdmin: "",
    loading: false,
    error: null
  };
  
  const getAllAdminReducer = (state = getAllAdminState, action) => {
    switch (action.type) {
      case 'FETCH_GETALLADMIN_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_GETALLADMIN_SUCCESS':
    
        return {
          ...state,
          loading: false,
          allAdmin: action.payload
        };
        
      case 'FETCH_GETALLADMIN_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getAllAdminReducer;
  