

import axios from "axios"


import "./ozon.css"
import { useEffect, useState } from "react"
import OzonBar from "./ozonNav/ozoNav"
import DableOzon from "../ozoEdit/ozonTable/ozontable"
import { useSelector } from "react-redux"


export default function Ozon() {
  const [data,setData] = useState([])
  const getDarkModoe = useSelector(darkModes => darkModes )
 
            return (
                <div style={{margin:"0 2%"}}>
                < OzonBar />
               
                < DableOzon />
              
               
              </div>
            )
}