const getWaldberisListSatate = {
    wblist: [],
    loading: false,
    error: null
  };
  
  const getWaldberisListReducer = (state = getWaldberisListSatate, action) => {
  
    switch (action.type) {
      case 'FETCH_WBLIST_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_WBLIST_SUCCESS':
    
        return {
          ...state,
          loading: false,
          wblist: action.payload
        };
        
      case 'FETCH_WBLIST_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getWaldberisListReducer;
  