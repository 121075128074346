import {
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react';
import "./metka.css"
import "./metkaDark.css"
import { useSelector } from 'react-redux';
export default function MetkaEdit({ setMetka, metka }) {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const [metkaVal, setMetkaVal] = useState("");
  const [MetkaData, setMetkaData] = useState([]);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const metkaRef = useRef(null);
  const modalMetka = useRef(null);

  const addMetka = (e) => {

    if (e.key === "Enter") {
      setMetka(metka => [...metka, metkaVal])

      setMetkaVal("")



    }

  }

  const addMetkaBtn = (e) => {

    setMetka(metka => [...metka, metkaVal])

    setMetkaVal("")

  }


  const handleDelete = (index) => {

    const deleteMetka = metka.filter((_, i) => i !== index);

    setMetka(deleteMetka);
  };
  useEffect(() => {
    modalMetka.current.style.display = "none";
  }, [])

  const handelOpenModal = () => {
    modalMetka.current.style.display = "flex";
  }


  const handelCloseModal = () => {
    modalMetka.current.style.display = "none";
  }

  return (
    <div   className={getDarkModoe.ToggleDarkModeReducer.chekced ? "metkaContDark" : "metkaContLight"}>

      <div id="myModal" ref={modalMetka} className='modalMetka'>


        <div className="modal-contentMetka">
          <div className="modal-headerMetka">
            <div>
              <h2>Теги</h2>

              <div onClick={handelCloseModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#353535" />
                </svg>
              </div>
            </div>
          </div>
          <div className='searchTagCont' >
            <div>
              <div>
                <p>Название тега</p>
                <input
                  size="sm"
                  type="text"
                  placeholder="Название тега"
                  value={metkaVal}
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                  placement="right"
                  closeOnBlur={false}
                  color={"#ACACB0"}
                  onChange={(e) => setMetkaVal(e.target.value)}
                  onKeyDown={addMetka}
                />
              </div>
              <div className='addTagBtn' onClick={addMetkaBtn}>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
                <p>Добавить</p>
              </div>
            </div>
          </div>
          <div className="modal-bodyMetka">
            {metka?.map((item, index) => {
              return (
                <div key={index} className='metkaListInModal'>
                  <p className='metkaNameInModalList' key={index}>#{item}</p>

                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={(e) => handleDelete(index)}>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 6V3.5H15V6H8.5ZM7.75 7.5H15.75H20.75C21.1642 7.5 21.5 7.16421 21.5 6.75C21.5 6.33579 21.1642 6 20.75 6H16.5V3.35C16.5 2.60444 15.8956 2 15.15 2H8.35C7.60442 2 7 2.60442 7 3.35V6H2.75C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H7.75ZM5.5 10.75C5.5 10.3358 5.16421 10 4.75 10C4.33579 10 4 10.3358 4 10.75V20.15C4 20.8956 4.60444 21.5 5.35 21.5H18.15C18.8956 21.5 19.5 20.8956 19.5 20.15V10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V20H5.5V10.75ZM10.5 10.75C10.5 10.3358 10.1642 10 9.75 10C9.33579 10 9 10.3358 9 10.75V16.75C9 17.1642 9.33579 17.5 9.75 17.5C10.1642 17.5 10.5 17.1642 10.5 16.75V10.75ZM13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z" fill="#DD5E98" />
                    </svg>
                  </div>

                </div>
              )
            })}
          </div>

        </div>

      </div>
      {/* <div className="metka">
        <div className='BNTcONT' onClick={handelOpenModal}>
          <button  >Теги</button>
          <span class="material-symbols-outlined">
            crop_square
          </span>
        </div>
        <div className='metkaCont'>
          {metka?.map((item, index) => {
            if (index < 3) {
              return (
                <div key={index}>
                  <p key={index}>#{item}</p>


                </div>
              )
            }

          })}
        </div>
      </div> */}

      <div className='metka'>
        <div>
          <p>Теги</p>
          <div>
            {metka?.map((item, index) => {
              if (index < 3) {
                return (
                  <div key={index}>
                    <div>
                      <p>{item}</p>
                      <div onClick={(e) => handleDelete(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                          <path d="M7.6115 7.9974L5.34754 5.73344C5.1029 5.4888 5.1029 5.09217 5.34754 4.84754C5.59217 4.6029 5.9888 4.6029 6.23344 4.84754L8.4974 7.1115L10.7614 4.84754C11.006 4.6029 11.4026 4.6029 11.6473 4.84754C11.8919 5.09217 11.8919 5.4888 11.6473 5.73344L9.38329 7.9974L11.6473 10.2614C11.8919 10.506 11.8919 10.9026 11.6473 11.1473C11.4026 11.3919 11.006 11.3919 10.7614 11.1473L8.4974 8.88329L6.23344 11.1473C5.9888 11.3919 5.59217 11.3919 5.34754 11.1473C5.1029 10.9026 5.1029 10.506 5.34754 10.2614L7.6115 7.9974Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                      </div>
                    </div>
                  </div>
                )
              }

            })}

            <button onClick={handelOpenModal}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <div>
                <p>Добавить теги</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}