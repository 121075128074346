import { useEffect, useRef, useState } from "react";
import "./edidInput.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getWaldberisListAction } from "../../../../redux/reducer/getwildberrieslIST/action/action";

import { useToast } from '@chakra-ui/react'

export default function EditInputWB({itemVal,index,name,vendorCodes,nmID}) {
    const [inpvalue,setinpvalue] = useState("")
    const [show,setShow] = useState(false)
        const inpRef = useRef(null)
        const inpRefColor = useRef(null)
        const toast = useToast()

        const dispatch = useDispatch()
    useEffect(()=>{
        inpRef.current.style.visibility = "hidden";
        inpRefColor.current.style.color="#5E88D9"
        inpRefColor.current.style.outline="1px solid white"
        setinpvalue(itemVal)
    },[])

    const handleShow=()=>{
        inpRef.current.style.visibility = "visible";
        inpRefColor.current.style.color="#DB5F95"
        inpRefColor.current.style.outline="1px solid #DB5F95"
       
    }


    const handleClose=()=>{
        inpRef.current.style.visibility = "hidden";
        inpRefColor.current.style.color="#5E88D9"
        inpRefColor.current.style.outline="1px solid white"
    }

    const handleSendEdit=(itemCard)=>{
            if (name == "price") {
                axios.post('/wildberis/changePrice/api', {
                    name,
                    inpvalue,
                    vendorCodes,
                    nmID
                  })
                  .then(function (response) {
                    console.log(response);
                    toast({
                        position:"top",
                        title: 'Хорошо',
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      })
                      handleClose()
                  })
                  .catch(function (error) {
                    console.log(error);
                    toast({
                        position:"top",
                        title: 'Ошибка',
                        description: error.response.data.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                      })
                  });
            }else if(name == "skidka"){
                axios.post('/wildberis/changeDiscount/api', {
                    name,
                    inpvalue,
                    vendorCodes,
                    nmID
                  })
                  .then(function (response) {
                    console.log(response);
                    toast({
                        position:"top",
                        title: 'Хорошо',
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      })
                      handleClose()
                  })
                  .catch(function (error) {
                    console.log(error);
                    toast({
                        position:"top",
                        title: 'Ошибка',
                        description: error.response.data.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                      })
                  });
            }
            else{
                axios.post('/wildberis/byWendorCode/api', {
                    name,
                    inpvalue,
                    vendorCodes
                  })
                  .then(function (response) {
                    console.log(response);
                    toast({
                        position:"top",
                        title: 'Хорошо',
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                      })
                      handleClose()
                  })
                  .catch(function (error) {
                    console.log(error);
                    toast({
                        position:"top",
                        title: 'Ошибка',
                        description: error.response.data.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                      })
                  });
            }
       
      
               console.log(vendorCodes);
    }

 
   return(
    <div className="ContINput" key={index}>
        <textarea  defaultValue={itemVal} onClick={()=> handleShow()} ref={inpRefColor} onChange={(e)=> setinpvalue(e.target.value)} key={itemVal}/>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} ref={inpRef}>
        <span className="material-symbols-outlined spaiShow" onClick={()=>handleSendEdit()}>check</span>
        <span className="material-symbols-outlined spaiClose" onClick={handleClose} >close</span>
        </div>
    </div>
   )
}