import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';


export default function PlusemojiDropDownPicker({ setValue, value,addLike }) {
  const [message, setMessage] = useState('');
  const [isOpen, setIsopen] = useState(false);
  const dropOpen = useRef(null);
  const dropdownRef = useRef(null);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const collection = document.getElementsByClassName("epr-emoji-category-label");
  const search = document.querySelectorAll('[aria-label="Type to search for an emoji"]');
  const setMesasge = (e) => {
     addLike(e.emoji)
    
  }


  const opeDropFunc = () => {
    setIsopen(!isOpen)

  }

  useEffect(() => {
    if (isOpen) {
      dropOpen.current.style.display = "block"
    } else {
      dropOpen.current.style.display = "none"
    }

  }, [isOpen])


  const handleOutsideClick = (event) => {

    // Close the dropdown if the click is outside of it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsopen(false);
    }
  };

  useEffect(() => {
    // Add event listener to the document to handle outside clicks
    document.addEventListener('click', handleOutsideClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };


  }, []);

  for (let i = 0; i < collection.length; i++) {
    if (collection[i].innerHTML == "Frequently Used") {
      collection[i].innerHTML = "Часто используемый"
    } else if (collection[i].innerHTML == "Smileys &amp; People") {
      collection[i].innerHTML = "Смайлики и люди"

    } else if (collection[i].innerHTML == "Animals &amp; Nature") {
      collection[i].innerHTML = "Животные и природа"
    } else if (collection[i].innerHTML == "Custom Emojis") {
      collection[i].innerHTML = "Пользовательские смайлы"
    } else if (collection[i].innerHTML == "Food &amp; Drink") {
      collection[i].innerHTML = "Еда и напитки"
    } else if (collection[i].innerHTML == "Travel &amp; Places") {
      collection[i].innerHTML = "Места для путешествий"
    } else if (collection[i].innerHTML == "Activities") {
      collection[i].innerHTML = "Деятельность"
    } else if (collection[i].innerHTML == "Objects") {
      collection[i].innerHTML = "Объекты"
    } else if (collection[i].innerHTML == "Symbols") {
      collection[i].innerHTML = "Символы"
    } else if (collection[i].innerHTML == "Flags") {
      collection[i].innerHTML = "Флаги"
    }
  }
  if (search.length > 0) {
    
    for (let i = 0; i < search.length; i++) {
      
      search[i].placeholder = "Поиск";
    }
  }


  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownEmojiDark" : "dropdownEmojiLight"} ref={dropdownRef} >
      <div ref={dropdownRef} onClick={opeDropFunc}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M9.75 1C9.75 0.585786 9.41421 0.25 9 0.25C8.58579 0.25 8.25 0.585786 8.25 1L8.25 8.25H1C0.585786 8.25 0.25 8.58579 0.25 9C0.25 9.41421 0.585786 9.75 1 9.75H8.25V17C8.25 17.4142 8.58579 17.75 9 17.75C9.41421 17.75 9.75 17.4142 9.75 17V9.75H17C17.4142 9.75 17.75 9.41421 17.75 9C17.75 8.58579 17.4142 8.25 17 8.25H9.75L9.75 1Z" fill="#8C9092" />
        </svg>
      </div>
      <div className='dropdownEmojiCont-content' ref={dropOpen}>
        <EmojiPicker onEmojiClick={setMesasge} emojiStyle='apple' />

      </div>

    </div>
  );
}
