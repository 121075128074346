import { Select } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import "./prioritetLight.css"
import "./prioritetDark.css"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Button,
  useDisclosure
} from '@chakra-ui/react'

export default function Prioritet({ prioritet, setPrioritet }) {
  const getDarkModoe = useSelector(darkModes => darkModes);
  const [prioritetColor, setPrioritetColor] = useState("red")
  const { onOpen, onClose, isOpen } = useDisclosure()
  // useEffect(()=>{
  //   modalRef.current.style.display ="none"
  // },[])

  const handleShow = () => {
    modalRef.current.style.display = "flex"
  }

  const handleClose = () => {
    modalRef.current.style.display = "none"
  }
  useEffect(() => {
    setPrioritet("Низший")

  }, [])

  useEffect(() => {
    if (prioritet == "Низший") {
      setPrioritetColor("#353535")
    } else if (prioritet == "Критический") {
      setPrioritetColor("red")
    } else if (prioritet == "Важно") {
      setPrioritetColor("#56D4FC")
    } else if (prioritet == "Срочно") {
      setPrioritetColor("#4DFF30")
    }

  }, [prioritet])

  const modalRef = useRef(null)
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPrioritetDark" : "selectPrioritetLight"}>
      {/* {
                                        (()=>{
                                            if (prioritet == 'Критический') {
                                                return <div className='statusContForShow' onClick={handleShow}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#FF7070"/>
                                                        </svg>
                                              <p>Критический</p>
                                                </div>
                                            }
                                            else  if (prioritet == 'Важно') {
                                              return <div className='statusContForShow' onClick={handleShow}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#C070FF"/>
                                                </svg>
                                                <p>Важно</p>
                                              </div>
                                          }
                                          else  if (prioritet == 'Срочно') {
                                            return <div className='statusContForShow' onClick={handleShow}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#FFE870"/>
                                                </svg>
                                            <p>Срочно</p>
                                            </div>
                                          }
                                          else  if (prioritet == 'Низший') {
                                            return <div className='statusContForShow' onClick={handleShow}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.5941C10.8433 2.5941 10.2207 2.08558 10.0763 1.37768C9.98333 0.911388 9.55966 0.566737 9.06366 0.566737H1.64433C1.07599 0.566737 0.610992 1.02289 0.610992 1.58042V16.7856C0.610992 17.3431 1.07599 17.7993 1.64433 17.7993C2.21266 17.7993 2.67766 17.3431 2.67766 16.7856V12.7035C2.67766 11.599 3.57309 10.7035 4.67766 10.7035H7.22289C7.94535 10.7035 8.568 11.2121 8.71233 11.92C8.80533 12.3964 9.22899 12.7309 9.72499 12.7309H15.0777C15.646 12.7309 16.111 12.2747 16.111 11.7172V3.60778C16.111 3.05025 15.646 2.5941 15.0777 2.5941H11.5658Z" fill="#6B7A99"></path></svg>
                                            <p>Низший</p>
                                            </div>
                                          }
                                         
                                        })()
                                      }
                                 <div id="myModal"  ref={modalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced?"modalStatusCreatDark":"modalStatusCreat"}>


                                    <div class="modal-contentStatusCreat">
                                      <div class="modal-headerStatusCreat">
                                        <span class="closeStatusCreat" onClick={handleClose}>&times;</span>
                                        <h2>Выберите Приоритет</h2>
                                      </div>
                                      <div class="modal-bodyStatusCreat">
                                              <div  className='chooseStatus'>
                                              <div className='statusContForShow' onClick={()=> setPrioritet("Критический")}>
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#FF7070"/>
                                                        </svg>
                                              <p>Критический</p>
                                            </div>
                                            <div className='statusContForShow' onClick={()=> setPrioritet("Важно")}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#C070FF"/>
                                                </svg>
                                                <p>Важно</p>
                                              </div>
                                              <div className='statusContForShow' onClick={()=> setPrioritet("Срочно")}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6667 2.94118L15.2667 1.17647C15.1167 0.5 14.4333 0 13.6333 0H1.66667C0.75 0 0 0.661765 0 1.47059V23.5294C0 24.3382 0.75 25 1.66667 25C2.58333 25 3.33333 24.3382 3.33333 23.5294V14.7059H12.6667L13.0667 16.4706C13.2167 17.1618 13.9 17.6471 14.7 17.6471H23.3333C24.25 17.6471 25 16.9853 25 16.1765V4.41176C25 3.60294 24.25 2.94118 23.3333 2.94118H15.6667Z" fill="#FFE870"/>
                                                </svg>
                                            <p>Срочно</p>
                                            </div>
                                            <div className='statusContForShow'  onClick={()=> setPrioritet("Низший")}>
                                              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.5941C10.8433 2.5941 10.2207 2.08558 10.0763 1.37768C9.98333 0.911388 9.55966 0.566737 9.06366 0.566737H1.64433C1.07599 0.566737 0.610992 1.02289 0.610992 1.58042V16.7856C0.610992 17.3431 1.07599 17.7993 1.64433 17.7993C2.21266 17.7993 2.67766 17.3431 2.67766 16.7856V12.7035C2.67766 11.599 3.57309 10.7035 4.67766 10.7035H7.22289C7.94535 10.7035 8.568 11.2121 8.71233 11.92C8.80533 12.3964 9.22899 12.7309 9.72499 12.7309H15.0777C15.646 12.7309 16.111 12.2747 16.111 11.7172V3.60778C16.111 3.05025 15.646 2.5941 15.0777 2.5941H11.5658Z" fill="#6B7A99"></path></svg>
                                            <p>Низший</p>
                                            </div>       
                                              </div>
                                      </div>
                                      <div class="modal-footerStatusCreat">
                                          <button onClick={handleClose}>закрыть</button>
                                      </div>
                                    </div>

                                  </div> */}
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}

      >
        <PopoverTrigger>
          <button>
            <div>
              <div>
                <div>
                  <p>Приоритет:</p>
                  <div>
                    <p style={{ color: prioritetColor }}>{prioritet}</p>
                  </div>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent borderRadius={"0 0 12px 12px"} overflow={"hidden"} width={"240px"}>

          <PopoverBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverForCreatPrioritetDark" : "popoverForCreatPrioritetLight"}>
            <div onClick={() => [setPrioritet("Критический"), onClose()]}>
              <div>
                <div>
                  <p>Критический</p>
                </div>
              </div>
            </div>
            <div onClick={() => [setPrioritet("Важно"), onClose()]}>
              <div>
                <div>
                  <p>Важно</p>
                </div>
              </div>
            </div>
            <div onClick={() => [setPrioritet("Срочно"), onClose()]}>
              <div>
                <div>
                  <p>Срочно</p>
                </div>
              </div>
            </div>
            <div onClick={() => [setPrioritet("Низший"), onClose()]}>
              <div>
                <div>
                  <p>Низший</p>
                </div>
              </div>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>

    </div>
  )
}