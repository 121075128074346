import { useSelector } from "react-redux"
import ModalForQrAutorizationComponent from "../containerForQrAutorizationScanner/containerForQrAutorizationScanner"
import "./appLicationLight.css"
import "./appLicationDark.css"
import { Helmet } from "react-helmet"


export default function AppLication(params) {
  const getDarkModoe = useSelector(darkModes => darkModes)
        return(
           <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "appContDark" : "appContLight"}>
                 <Helmet>
        <title>Авторизация через QR | Приложение Упрости.рф</title>
        <meta name="description" content="Войдите в Упрости.рф с помощью QR-кода. Установите удобное приложение и работайте с задачами на маркетплейсах через таск-трекер. Быстро, просто, безопасно." />
      </Helmet>
             <ModalForQrAutorizationComponent />
           </div>
        )
}