import { combineReducers } from 'redux';


const adminState = {
  isLoading: false,
  data: [],
  error: null,
};

const adminReducer = (state = adminState, action) => {
  switch (action.type) {
    case 'FETCH_DATAADMIN_REQUEST':
     
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_DATAADMIN_SUCCESS':
      
      return {
        ...state,
        loading: false,
        data: action.payload
      };
      
    case 'FETCH_DATAADMIN_FAILURE':
      return {
        ...state,
        loading: false,
        error: "error"
      };
    default:
      return state;
  }
};



export default adminReducer;
