import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { Radio, RadioGroup ,Stack} from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import JsBarcode from 'jsbarcode';
import React from "react";
import "./ozonEditInfo.css"
import "./ozonEditInfoDark.css"
import { useDispatch, useSelector } from "react-redux";
function generateRandomNumber() {
    var randomNumber = '';
    for (var i = 0; i < 13; i++) {
      randomNumber += Math.floor(Math.random() * 10);
    }
    return randomNumber;
  }
  
  var random13DigitNumber = generateRandomNumber();


export default function OzonProductinfoEdit({item}) {
    
    const barcodeRef = React.useRef(null);

    const [value, setValue] =useState("0.1")
    const [random, setRandom] =useState(null)
    const getDarkModoe = useSelector(darkModes => darkModes )
  
    useEffect(()=>{
        var randomNumber = '';
        for (var i = 0; i < 13; i++) {
          randomNumber += Math.floor(Math.random() * 10);
        }
            setRandom(Number(randomNumber))

            if (barcodeRef.current) {
                JsBarcode(barcodeRef.current, randomNumber, {
                  format: 'CODE128',
                  width: 1.5,
                  height: 50,
                  displayValue: true,
                });
              }
           
              if (item.vat == "0.0") {
                    setValue("0")
              }else if (item.vat == "0.100000") {
                setValue("0.1")
          }
          else if (item.vat == "0.200000") {
            setValue("0.2")
      }
    },[])


    const creatRandom = ()=>{
        var randomNumber = '';
        for (var i = 0; i < 13; i++) {
          randomNumber += Math.floor(Math.random() * 10);
        }
        setRandom(Number(randomNumber))
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, randomNumber, {
              format: 'CODE128',
              width: 1.5,
              height: 50,
              displayValue: true,
            });
          }
    }


       return(
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced?"ProdcutInfoDark":"ProdcutInfo"}>
                <div>
                    <label htmlFor="">название</label>
                     <input placeholder='название' name="prodname" required  defaultValue={item.attributs.name}/>
                </div>
                {/* <div>
                    <label htmlFor="">Штрих-код</label>
                    <svg ref={barcodeRef} />
                     <Input placeholder='Штрих-код' name="strixkode" required defaultValue={random} />
                     <div className="generateStrixCode" onClick={creatRandom}>сгенерировать штрих-код</div>
                </div> */}
                 <div className="strixCodeContainer">
                        
                   
                        <div className="strixCodeContainerChild">
                                <div>
                                       <label htmlFor=""  >Штрих-код</label>
                                      <svg ref={barcodeRef} />
                                </div>
                           
                        <div className="strixCodeRender">
                                      <input placeholder='Штрих-код' className="strixCode" name="strixkode" required defaultValue={random} />
                                      <div className="generateStrixCode" onClick={creatRandom}>Сгенерировать</div>
                            </div>
                        </div>
                         
                        
                    </div>
                <div>
                    <label htmlFor="">Артикул</label>
                     <Input placeholder='Артикул' name="arcticul" required defaultValue={item.arcticule}/>
                </div>
                <div className="priceOzon">
                   <div className="PiceInputContParent">
                  <div className="PiceInputCont">
                    <label htmlFor="">Цена в RUB </label>
                        <input placeholder='Цена в RUB ' name="pirce" defaultValue={item.price} required/>
                   </div>
                   <div className="PiceInputCont">
                    <label htmlFor="">Цена до скидки </label>
                        <input placeholder='Цена до скидки ' name="skidprice" defaultValue={item.oldPrice} />
                       
                   </div>
                    </div>
                    <div className="radibtnOzon">
               
                        <RadioGroup onChange={setValue} name="nds" value={value} aria-required>
                                <label htmlFor="">Цена до скидки </label>
                                <Stack direction='row'>
                                    <Radio value='0.1' >10%</Radio>
                                    <Radio value='0.2'>20%</Radio>
                                    <Radio value='0'>Не облагается</Radio>
                                </Stack>
                        </RadioGroup>
                    </div>
                </div>
              
                <div>
                    <h1>Габариты и вес в упаковке</h1>
                        <div>
                            <label htmlFor="">Вес с упаковкой, г </label>
                            <input placeholder='Вес с упаковкой, г ' type="number" name="wigthprod"  defaultValue={Number(item.attributs.weight)} required/>
                        </div>
                        <div>
                            <label htmlFor="">Длина упаковки, мм  </label>
                            <input placeholder='Длина упаковки, мм ' type="number" name="dlinaprod" defaultValue={Number(item.attributs.depth)} required/>
                        </div>
                        <div>
                            <label htmlFor="">Ширина упаковки, мм  </label>
                            <input placeholder='Ширина упаковки, мм  ' type="number" name="shirinaprod" defaultValue={Number(item.attributs.width)} required/>
                        </div>
                        <div>
                            <label htmlFor="">Высота упаковки, мм  </label>
                            <input placeholder='Высота упаковки, мм ' type="number" name="visota" defaultValue={Number(item.attributs.height)} required/>
                        </div>
                </div>
        </div>
       )
}