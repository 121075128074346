import { getCompanyForChangeAPI } from "../../../redux/reducer/myCompanyAPI/action/action";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'

import "./chanegApi.css";
import "./chanegApiDark.css";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useToast } from '@chakra-ui/react'
import { useNavigate } from "react-router";


export default function ChangeApi() {
  
  const toast = useToast()
  const dispatch = useDispatch();
  const [load,setLoad] = useState(false)
  const navigate= useNavigate()
  const getMycOMANYapi = useSelector(
    (mycompany) => mycompany.getMyCompanyAPIReducer
  );
  
  const getDark = useSelector(dark=>dark)
  const getDarkModoe = useSelector(darkModes => darkModes )
console.log(getMycOMANYapi.company.ozonToken
  );

useEffect(()=>{
  dispatch(getCompanyForChangeAPI("/changeapi/api"));
},[])


// здесь мы добавляем token key для Wildberries 

const  WildberriesChange =(e)=>{
    
  dispatch(getCompanyForChangeAPI("/changeapi/api"));
      e.preventDefault()
      setLoad(true)
      axios({
       method: 'patch',
       url: '/changeapi/wildberis/api',
      data:{
          companyID:getMycOMANYapi.company._id,
        key1:e.target.key1.value,
        price:e.target.price.value,
        wbStatistic:e.target.wbStatistic.value,
        wbReclam:e.target.wbReclam.value,
       }
    }).then(data=>{
      setLoad(false)
        swal("Ключ успешно добавлен", "Ключ успешно добавлен", "success");
        navigate("/changeApi")
        dispatch(getCompanyForChangeAPI("/changeapi/api"));

        
      })
     .catch(error=>{
       dispatch(getCompanyForChangeAPI("/changeapi/api"));
        
       toast({
         title: 'Ошибка',
          description:error.response.data.message,
        status: "error",
         duration: 9000,
        isClosable: true,
         position:"top"
      })
       
     })
    setLoad(false)
}
// здесь мы добавляем token key для Ozon
const  ozonChange =(e)=>{
  dispatch(getCompanyForChangeAPI("/changeapi/api"));
      e.preventDefault()
      setLoad(true)
       axios({
        method: 'patch',
        url: '/changeapi/ozon/api',
        data:{
          companyID:getMycOMANYapi.company._id,
          key1:e.target.key1.value,
          clientid:e.target.clientid.value,
        }
      }).then(data=>{
        setLoad(false)
        swal("Ключ успешно добавлен", "Ключ успешно добавлен", "success");
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
      })
      .catch(error=>{
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        
        toast({
          title: 'Ошибка',
          description:error.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
          position:"top"
        })
       
      })
      setLoad(false)

}



if (getMycOMANYapi.company != "" && getMycOMANYapi.company !=null) {
    return(
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced?"MycompanyContApiDark":"MycompanyContApi"}>
          <Tabs color={"#6A717F"}>
              <TabList>
                <Tab >Wildberries</Tab>
                <Tab>Ozon</Tab>
              
              </TabList>

              <TabPanels>
               <form onSubmit={WildberriesChange} className="wildberiscont">
               <TabPanel >
                
                 <div className="changeApiCont">
                        <div className="changeApiContChild1">
                              <p>  {getMycOMANYapi.company.sellersname}</p>

                              <div className="forBorder">
                                  <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>Контент</label>
                                  <input placeholder='Контент' name="key1" required defaultValue={getMycOMANYapi.company.walberisToken} key={getMycOMANYapi.company.walberisToken}/>
                                  <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>Цены</label>
                                  <input placeholder='Цены' name="price" required defaultValue={getMycOMANYapi.company.walberisTokenPrice} key={getMycOMANYapi.company.walberisTokenPrice + 1}/>
                                  <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>Статистика</label>
                                  <input placeholder='Статистика' name="wbStatistic" required defaultValue={getMycOMANYapi.company.walberisTokenStatistic} key={getMycOMANYapi.company.walberisTokenStatistic +2}/>
                                  <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}> Продвижение </label>
                                  <input placeholder='Продвижение' name="wbReclam" required defaultValue={getMycOMANYapi.company.walberisTokenReclam} key={getMycOMANYapi.company.walberisTokenReclam + 3}/>
                                  {load?
                                  <Button isLoading colorScheme='teal' loadingText='добавить ключ' backgroundColor={"#1879DE"} variant='solid'>
                                  Добавить ключ
                                  </Button>
                                  :
                                  <button colorScheme='blue' type="submit">
                                    Добавить ключ
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="27" viewBox="0 0 50 27" fill="none">
                                            <path d="M45.4545 9H26.4773C24.6136 3.7575 19.5682 0 13.6364 0C6.11364 0 0 6.0525 0 13.5C0 20.9475 6.11364 27 13.6364 27C19.5682 27 24.6136 23.2425 26.4773 18H27.2727L31.8182 22.5L36.3636 18L40.9091 22.5L50 13.41L45.4545 9ZM13.6364 20.25C9.88636 20.25 6.81818 17.2125 6.81818 13.5C6.81818 9.7875 9.88636 6.75 13.6364 6.75C17.3864 6.75 20.4545 9.7875 20.4545 13.5C20.4545 17.2125 17.3864 20.25 13.6364 20.25Z" fill="white"/>
                                          </svg>
                                  </button>
                                  }
                              </div>
                              
                        </div>
                       
                 </div>
                  
                </TabPanel>
               </form>


               <form onSubmit={ozonChange}  className="ozoncont">
                <TabPanel >
                            <div className="changeApiCont">
                            <div className="changeApiContChild1">
                                      <p>  {getMycOMANYapi.company.sellersname}</p>
                                       <div className="forBorder">
                                            <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>Администратор</label>
                                              <input placeholder='Администратор' name="key1" defaultValue={getMycOMANYapi.company.ozonToken}/>
                                              <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>ID клиента</label>
                                              <input placeholder='ID клиента' name="clientid"  defaultValue={getMycOMANYapi.company.ozonClientID}/>

                                              <label htmlFor="" style={{color:getDark.useChangeDarkReducer.fonstColor}}>Товары</label>
                                              <input placeholder='Товары' />
                                              <button colorScheme='blue' type="submit">
                                                Добавить ключ
                                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="27" viewBox="0 0 50 27" fill="none">
                                                        <path d="M45.4545 9H26.4773C24.6136 3.7575 19.5682 0 13.6364 0C6.11364 0 0 6.0525 0 13.5C0 20.9475 6.11364 27 13.6364 27C19.5682 27 24.6136 23.2425 26.4773 18H27.2727L31.8182 22.5L36.3636 18L40.9091 22.5L50 13.41L45.4545 9ZM13.6364 20.25C9.88636 20.25 6.81818 17.2125 6.81818 13.5C6.81818 9.7875 9.88636 6.75 13.6364 6.75C17.3864 6.75 20.4545 9.7875 20.4545 13.5C20.4545 17.2125 17.3864 20.25 13.6364 20.25Z" fill="white"/>
                                                      </svg>
                                              </button>
                                      </div>
                            
                            </div>
                            </div>
                           
                 
                  </TabPanel>
               </form>
              
              </TabPanels>
        </Tabs>
      </div>
      
    )
}else{
  return(
    <div style={{margin:"2%",fontSize:"2rem",color:"#8E8E93"}}>Для добавления ключа необходимо создать компанию</div>
  )
}
 
}
