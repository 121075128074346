import axios from 'axios';

export const getadminInfoAction = (url) => {
        
  return (dispatch) => {
    
    dispatch({ type: 'FETCH_DATAADMIN_REQUEST' });
    axios.get("/getadmininfo/admin/api")
       
      .then(response => {
        
        dispatch({
          type: 'FETCH_DATAADMIN_SUCCESS',
          payload: response.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_DATAADMIN_FAILURE',
          payload: error.message
        });
      });
  };
};