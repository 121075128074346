import { NavLink, Outlet } from "react-router-dom";
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action";

import "./setttingNav.css"
import { useSelector ,useDispatch} from "react-redux";
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";
import { getCompanyForChangeAPI } from "../../../../redux/reducer/myCompanyAPI/action/action";
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export default function SettingNav(params) {
  const settingNavRefDarkLight = useRef(null)
  const dispatch = useDispatch()
  const [isDarkclassname,setisDarkclassname] = useState("settIngsNav")
  const getDarkModoe = useSelector(darkModes => darkModes )
  const handleStart=()=>{
    dispatch(getCompanyForChangeAPI("/changeapi/api"));
    // dispatch(fetchUsers("/mycopany/getallcompany"))
     dispatch(fetchUsers())
    
  }

  useEffect(()=>{
    dispatch(getMyCompany("/mycopany/getallcompany"))
     dispatch(fetchUsers())
       
  
  },[dispatch])
  // Navigation

  return (
    <nav className={getDarkModoe.ToggleDarkModeReducer.chekced?"settIngsNavDark":"settIngsNav"} ref={settingNavRefDarkLight}>
      <div>
        <ul>
         
          <li>
            <NavLink to={"AddNewUser"} onClick={handleStart }>Пользователи</NavLink>
          </li>
          <li>
            <NavLink to={"employeeTemplate"}>Мои команды</NavLink>
          </li>
          {/* <li>
            <NavLink to={"graphCompany"}>graphCompany</NavLink>
          </li> */}
         
        </ul>
       <div  className="settIngsNavOutlet">
           <Outlet />
       </div>
      </div>
    </nav>
  );
}
