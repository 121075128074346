import "./editcopnay.css"
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";
import { useEffect, useState } from "react";
import swal from 'sweetalert';
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from 'react-redux'
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action";
import { editCompanyAction } from "../../../../redux/reducer/editCompoany/action/action";

export default function EditCompany({ items }) {


  const dispatch = useDispatch();
  const navigate = useNavigate()
  //получаем данные компании через

  //обновление данных компании


  const editCompany = () => {
    navigate("/EditCompanyOption")
    dispatch(editCompanyAction(items))
  }
  return (
    <div onClick={editCompany}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M1 19H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.2198 3.82843L15.1696 8.77814M10.2198 3.82843L13.0483 1L17.998 5.94975L15.1696 8.77814L10.2198 3.82843ZM10.2198 3.82843L4.6132 9.43504C4.42567 9.62254 4.32031 9.87694 4.32031 10.1421V14.6776H8.85588C9.12108 14.6776 9.37538 14.5723 9.56298 14.3847L15.1696 8.77814L10.2198 3.82843Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>


    </div>
  )
}