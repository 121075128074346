const EditCompanyState = {
    compnayedit: [],
   
  };
  
  const userReducerEditCompany = (state = EditCompanyState, action) => {
                if (action.type === "editCompany") {
                        return{
                            ...state,
                            compnayedit:action.value
                        }
                }
        return state;
  };
  
  export default userReducerEditCompany;
  