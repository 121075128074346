const initialStateChatItem = {
    chatItems: [],
    loading: false,
    error: null
  };
  
  const getChatItemReducer= (state = initialStateChatItem, action) => {
    switch (action.type) {
      case 'FETCH_GETITEMCHAT_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_GETITEMCHAT_SUCCESS':
    
        return {
          ...state,
          loading: false,
          chatItems: action.payload
        };
        
      case 'FETCH_GETITEMCHAT_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getChatItemReducer;
  