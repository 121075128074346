import axios from 'axios';

export const getMyImageAction = (url) => {
  return (dispatch) => {
      
    dispatch({ type: 'FETCH_IMAGE_REQUEST' });
    axios.get(url)
      .then(response => {

        dispatch({
          type: 'FETCH_IMAGE_SUCCESS',
          payload: response.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_IMAGE_FAILURE',
          payload: error.message
        });
      });
  };
};