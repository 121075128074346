import axios from 'axios';

export const getItemChatAction = (url) => {
  return (dispatch) => {
      
    dispatch({ type: 'FETCH_GETITEMCHAT_REQUEST' });
    axios.get(url)
      .then(response => {

        dispatch({
          type: 'FETCH_GETITEMCHAT_SUCCESS',
          payload: response.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_GETITEMCHAT_FAILURE',
          payload: error.message
        });
      });
  };
};