import { Textarea } from '@chakra-ui/react'
import "./ozonTextArea.css"
import { useEffect, useState } from 'react'


export default function TextAreaOzon({placeholder,item}) {
      const [value,setValue]=useState([])
        let atributeParams =  {
                complex_id: 0,
                id: item.id,
                values: [
                  {
                        value:"" 
                  }
                ]
              }

      
        const handleChange=(e)=>{
              
              atributeParams.values[0].value = e.target.value
              setValue([atributeParams])
              console.log(atributeParams);
        }
        return(
            <div className='TextareaOzonCont'>
                    <label htmlFor={placeholder}>{placeholder}</label>
                 <Textarea placeholder={placeholder}  id={placeholder} onChange={handleChange} required={item.is_required}/>
                 <input type="hidden" name="TextareaOzon" value={JSON.stringify(value)} key={item}/>
            </div>
        )
}