import { useSelector } from "react-redux"
import ChangeDirector from "../changeDirector/changeDirector"
import PerfomerEdit from "../perfomerEdit/perfomerEdit"
import "./editPerformerAndDirectorLight.css"

export default function EditPerformerAndDirector({ creaTorTask, setcreaTorTask, onlineUsers, adminInfo, setperformerS, performerS, HandleSetPerfomerId, HandleDeletePerfomerId, allitem }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPerformerDark" : "selectPerformerLight"}>
            <ChangeDirector creaTorTask={creaTorTask} setcreaTorTask={setcreaTorTask} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} onlineUsers={onlineUsers} adminInfo={adminInfo} />
            <PerfomerEdit performerS={performerS} setperformerS={setperformerS} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} onlineUsers={onlineUsers} allitem={allitem} creaTorTask={creaTorTask}/>
        </div>
    )
}