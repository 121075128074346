import axios from 'axios';

export const getMessageItem = (url) => {

  return (dispatch) => {
      
    dispatch({ type: 'FETCH_GET_MESSAGE_ITEM_REQUEST' });
    axios.get(url)
      .then(response => {

        dispatch({
          type: 'FETCH_GET_MESSAGE_ITEM_SUCCESS',
          payload: response.data.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_GET_MESSAGE_ITEM_FAILURE',
          payload: error.message
        });
      });
  };
};