const PerexodeChatMenuState = {

    chekced:"MenuChecked",
  
    
  };
  
  const PerexodeChatMenuReducer = (state = PerexodeChatMenuState, action) => {
        
            if (action.type === "PEREXOD_CHAT_MENU") {
                return{
                    ...state,
                    
                    chekced:state.chekced = action.value,
             
                }
            }

            return state
  };
  
  export default PerexodeChatMenuReducer;
  