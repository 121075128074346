import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";

import "./ozoNav.css";
import "./ozoNavDark.css";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWaldberisListAction } from "../../../../redux/reducer/getwildberrieslIST/action/action";
import axios from "axios";
import OZfilter from "../ozonFilter/ozonfilter";
import OzonModal from "../ozoModal/ozonModal";
import { getOzonPorductList } from "../../../../redux/reducer/ozonGetProductLIst/action/action";
  
export default function OzonBar() {
  const dispatch = useDispatch();

    const [prdvalue,setprdvalue] = useState("")
    const getDarkModoe = useSelector(darkModes => darkModes )
    const findByInputAndFilterOzon =(e) =>{
      e.preventDefault()
       
      const data =  {
        searchs:e.target.searchs.value,
        catigory:e.target.catigory.checked,
        namePorduct:e.target.namePorduct.checked,
        arctioculozon:e.target.arctioculozon.checked,
        

      }
        dispatch(getOzonPorductList(`/ozon/getByNavOzonFilter/api?data=${JSON.stringify(data)}`))
      // axios.get(`/ozon/getByNavOzonFilter/api?data=${data}`, {
      //   params:{
      //     data
      //   }
      // })
      // .then(function (response) {
      //   console.log(response.data
      //     ,"ASDASD");
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
 
     

    } 

   
    useEffect(()=>{
          // if (prdvalue === "") {
          //   dispatch(getWaldberisListAction("/wildberis/getprodcutlist/api"))
          // }
    },[prdvalue])

  return (
      
    <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ozonNavDark":"ozonNav"}>
   
      <Navbar bg="light" expand="sm">
        <Container fluid>
          <form onSubmit={findByInputAndFilterOzon} style={{display:"flex"}}>
            <OZfilter  setprdvalue={setprdvalue}/>

          
          <div >
            
          <div className="d-flex" >
              <input
                type="search"
                placeholder="Поиск"
                className="me-2"
                aria-label="Поиск"
                name="searchs"
                onChange={(e) => setprdvalue(e.target.value)}
              />
              <button className="SearchOzon" type="submit" >Поиск</button>
              
            </div>
          </div>
          </form>
          <div>
            <OzonModal />
          </div>
        </Container>
      </Navbar>
     
    </div>
    
  );
}


