import ChilMNodal from "../childModal/childModal";
import "./subTaskContLight.css"
import "./subTaskContDark.css"
import { useSelector } from "react-redux";

export default function SubTaskCont({ allaray, sendServer, setperfomerID, setchildPerfomer, handleClose, deleteItemByIndex, metka, load, currentTask, getarraa, setSubStackBTN, message, setMessage, setSubStack }) {
    const getDarkModoe = useSelector(darkModes => darkModes)
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "subTaskContDark" : "subTaskContLight"}>
            <div>
                <p>Подзадачи</p>
                <div className="addSubTaskBtnInput">
                    <input type="text" onChange={(e) => setMessage(e.target.value)} onKeyDown={setSubStack} value={message} placeholder="Подзадача" />
                    <button onClick={setSubStackBTN}>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                            </svg>
                        </div>
                    </button>
                </div>
                <div className="listSubTask">
                    {currentTask[0]?.child?.map((item, index) => {
                        return (
                            <div onClick={() => getarraa(item, index)} key={index} >
                                <div className="itemChild">
                                    <ChilMNodal
                                        item={item}
                                        allaray={currentTask}
                                        nameCurrentSubTask={item.msg}
                                        sendServer={sendServer}
                                        setperfomerID={setperfomerID}
                                        setchildPerfomer={setchildPerfomer}
                                        handleClose={handleClose}
                                        deleteItemByIndex={deleteItemByIndex}
                                        index={index}
                                        metkas={metka}
                                        load={load}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>

        </div>
    )
}