const initialState = {
    data: [],
    loading: false,
    error: null
  };
  
  const userReducerMessageItem = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_GET_MESSAGE_ITEM_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'FETCH_GET_MESSAGE_ITEM_SUCCESS':
    
        return {
          ...state,
          loading: false,
          data: action.payload
        };
        
      case 'FETCH_GET_MESSAGE_ITEM_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userReducerMessageItem;
  