
//Я не знаю, зачем это нужно
import "./datecellery.css"
import "./datecelleryDark.css"

import axios from 'axios';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { getMyCompany } from '../../../../../redux/reducer/getCompany/action/action';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyForChangeAPI } from '../../../../../redux/reducer/myCompanyAPI/action/action';
import { checkedSessionAction } from '../../../../../redux/reducer/getCheckedCompanySession/action/action';
import { useToast } from '@chakra-ui/react'
import { Helmet } from "react-helmet";


export default function CardUser() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const toast = useToast()
  const getDarkModoe = useSelector(darkModes => darkModes)
  useEffect(() => {
    dispatch(getMyCompany("/mycopany/getallcompany"))
  }, [dispatch])
  // здесь мы добавляем новую компанию
  const addCompany = (e) => {
    setLoading(true)
    setDisabled(true)
    dispatch(getMyCompany("/mycopany/getallcompany"))
    e.preventDefault()
    axios.post('/mycopany/api', {

      inn: e.target.inn.value,
      sellersname: e.target.sellersname.value,
      indirecttax: e.target.indirecttax.checked,
      Legaladdress: e.target.Legaladdress.value,
      Checkingaccount: e.target.Checkingaccount.value,
      btc: e.target.btc.value,
      ognip: e.target.ognip.value,
    })
      .then(function (response) {
        setLoading(false)
        setDisabled(false)


        swal(response.data.message);

        swal("Хорошая работа!", response.data.message, "success");
        dispatch(getMyCompany("/mycopany/getallcompany"))
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));

      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        setDisabled(false)
        toast({
          position: "top",
          title: 'Ошибка.',
          description: error.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })

      });
  }


  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "settingsContDark" : "settingsCont"}>
      <Helmet>
        <title>Добавляйте компании и управляйте задачами на маркетплейсах </title>
        <meta name="description" content="
               Создайте профиль компании в таск-трекере, чтобы организовать задачи для маркетплейсов. Все компании в одном интерфейсе для удобного управления.
                " />
      </Helmet>
      <form onSubmit={addCompany}>
        <div>
          <div>
            <p>Добавление компании</p>
            <div className='child1'>
              <label htmlFor="">
                <p>ИНН</p>
                <input placeholder="ИНН" name='inn' />
              </label>
              <label htmlFor="">
                <p>Наименование компании</p>
                <input placeholder="Наименование компании" name='sellersname' />
              </label>
              <label htmlFor="">
                <p>Юридический адрес</p>
                <input placeholder="Юридический адрес" name='Legaladdress' />
              </label>
              <div>
                <label htmlFor="">
                  <p>Расчетный счет</p>
                  <input placeholder="Расчётный счёт" name='Checkingaccount' />
                </label>
                <label htmlFor="">
                  <p>БИК</p>
                  <input placeholder="БИК" name='btc' />
                </label>
                <label htmlFor="">
                  <p>ОГРН (ИП)</p>
                  <input placeholder="ОГРН (ИП)" name='ognip' className='ognip' />
                </label>
              </div>
            </div>
            <div className='child2'>
              <input type="checkbox" name='indirecttax' className='indirecttax'></input>
              <p> С НДС</p>
            </div>
            <div className='child4'>
              {loading ?
                <button disabled style={{ opacity: "0.5" }}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                    </svg>
                  </div>
                  <p>Создать компанию</p>
                </button>
                :
                <button colorScheme='blue' type='submit'  >
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                    </svg>
                  </div>
                  <p>Создать компанию</p>
                </button>
              }
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

