
import { useSelector } from "react-redux"
import "./WhoCreatedTheConversationLight.css"
import "./WhoCreatedTheConversationDark.css"
import moment from 'moment';
import 'moment/locale/ru';


export default function WhoCreatedTheConversation(params) {
    const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
    const getDarkModoe = useSelector(darkModes => darkModes);
    function convertDate() {
        if (getChatInfo && getChatInfo.data && getChatInfo.data.chatOptions && getChatInfo.data.chatOptions.chatDate) {
          const date = moment(getChatInfo.data.chatOptions.chatDate);
          const formattedDate = date.locale('ru').format('D MMMM YYYY г.');
          return formattedDate;
        }
        return null; // или можно вернуть что-то другое в случае отсутствия данных
      }
        return(
            <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "WhoCreatedTheConversationDarkCont" : "WhoCreatedTheConversationLightCont"}>
                <div>
                        <div className="chatDateIsCreat">
                            <p>{convertDate()}</p>
                        </div>
                        <div className="whoCreatThisChat">
                           {
                            getChatInfo?.data?.chatOptions?.creatorOfThisChatInfo && <p className="whoCreatThisChatName">{getChatInfo?.data?.chatOptions?.creatorOfThisChatInfo.name}</p>
                           }
                            <p className="whoCreatThisChatText">
                                {
                                    getChatInfo?.data?.chatOptions?.chatType =="canal"?"создал канал":"создал беседу"
                                }
                                </p>
                        </div>
                </div>
            </div>
        )
}