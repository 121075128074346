import { useEffect, useState } from "react"
import "./sendMessageNoPerformerNotify.css"
import "./sendMessageNoPerformerNotifyDark.css"
import { useSelector } from "react-redux"
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import axios from "axios"

export default function SendMessageNoPerformerNotify({ setShow, show, selectPerformerFunc, perfromer, allitem,handleShow }) {
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  let onlineUSers = useSelector(onlineUSers => onlineUSers);
  const getDarkModoe = useSelector(darkModes => darkModes);
  let adminIfno = useSelector(adminIfno => adminIfno);
  const [creatorTaskInfo, setCreatorTaskInfo] = useState({});
  const [load, setload] = useState(false)




  useEffect(() => {
    const handlecCloseWind = (event) => {

      if (!event.target.matches('.material-symbols-outlined')) {
        // setShow(false)
      }
    }

    window.addEventListener('click', handlecCloseWind);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('click', handlecCloseWind);
    };

  }, [])

  function reduceText(text, type) {


    if (type == "name") {
      if (text?.length > 5) {
        return text.slice(0, 5) + '...';
      }
    } else {
      if (text?.length > 18) {

        return text.slice(0, 18) + '...';
      }
    }

    return text;
  }

  useEffect(() => {
    setload(true)

    const fetchCreatorTaskInfo = async () => {
      try {
        const response = await axios.get('/getCreatorTaskInfo/api', {
          params: {
            creatorTasks: allitem.creatorTasks, // Параметр передаётся как query-параметр
          },
        });
        setload(false)
        setCreatorTaskInfo(response.data.data);
      } catch (error) {
        console.error("Ошибка запроса:", error);
        setload(false)
      }
    };

    if (show) {
      fetchCreatorTaskInfo();
    }
  }, [show, allitem]); // Добавляем allitem в зависимости

  const getFileName = (img) => {
    // Убираем хост и порт, если они есть
    if (img?.startsWith("http://") || img?.startsWith("https://")) {
      return img.split('/').pop(); // Берем только имя файла из URL
    }
    return img; // Возвращаем как есть, если это уже имя файла
  };

  return (
    <>
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownNotifySendMsgLigthDark" : "dropdownNotifySendMsgLigth"} onClick={handleShow}>
        <button className="dropbtnNotify">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" >
            <path d="M9.05642 13.0582C10.7746 13.0582 12.1675 11.6653 12.1675 9.94705C12.1675 8.22883 10.7746 6.83594 9.05642 6.83594C7.3382 6.83594 5.94531 8.22883 5.94531 9.94705C5.94531 11.6653 7.3382 13.0582 9.05642 13.0582Z" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.7 6.2995V10.2995C12.7 10.936 12.9529 11.5465 13.4029 11.9966C13.853 12.4466 14.4635 12.6995 15.1 12.6995C15.7365 12.6995 16.347 12.4466 16.7971 11.9966C17.2471 11.5465 17.5 10.936 17.5 10.2995V9.4995C17.4999 7.69392 16.889 5.94148 15.7666 4.52713C14.6443 3.11277 13.0764 2.11968 11.3181 1.70934C9.55978 1.299 7.71435 1.49554 6.08188 2.267C4.44942 3.03846 3.12592 4.33947 2.32661 5.95848C1.5273 7.57749 1.29918 9.41929 1.67934 11.1844C2.0595 12.9495 3.02559 14.5341 4.42051 15.6805C5.81543 16.8269 7.55715 17.4678 9.36246 17.4988C11.1678 17.5299 12.9305 16.9493 14.364 15.8515" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>


        </button>
        {
          show ?
            <div id="myDropdown" className="dropdown-contentNotify">
              {/* admin */}
              {
                load ?
                  <p>Загрузка</p>

                  :
                  <div onClick={() => selectPerformerFunc(creatorTaskInfo)}>
                    {
                      creatorTaskInfo.online ?
                        <Avatar size={"sm"} src={creatorTaskInfo?.img ? `/${creatorTaskInfo?.img}` : "https://bit.ly/broken-link"}>
                          <AvatarBadge boxSize='0.9em' bg='green.500' />
                        </Avatar>
                        :
                        <Avatar size={"sm"} src={creatorTaskInfo?.img ? `/${creatorTaskInfo?.img}` : "https://bit.ly/broken-link"}>
                        </Avatar>
                    }

                    <p>{reduceText(creatorTaskInfo?.name, "name")}</p>
                    <p>{reduceText(creatorTaskInfo?.email)}</p>
                  </div>
              }

              {/* preformer */}
              {
                perfromer?.map((item, index) => {
                  return onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.workerID) ?
                    <div onClick={() => selectPerformerFunc(item)} key={index}>
                      <Avatar name={item.name} size={"sm"} src={item.img ? `/${getFileName(item.img)}` : "https://bit.ly/broken-link"}>
                        <AvatarBadge boxSize='0.9em' bg='green.500' />
                      </Avatar>
                      <p>{item.name ? reduceText(item.name, "name") : reduceText(item.tel)}</p>
                      <p>{reduceText(item.email)}</p>
                    </div>
                    :
                    <div onClick={() => selectPerformerFunc(item)} key={index}>

                      <Avatar size={"sm"} src={item.img ? `/${getFileName(item.img)}` : "https://bit.ly/broken-link"}>
                      </Avatar>
                      <p>{item.name ? reduceText(item.name, "name") : reduceText(item.tel)}</p>
                      <p>{reduceText(item.email)}</p>
                    </div>

                })
              }


            </div>
            :
            ""
        }

      </div>
    </>
  );
}