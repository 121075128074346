import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MessageItems from "../messageItems/messageItems";
import ReplyMessageItem from "../replyaMessageItem/replyaMessageItem";
import ChangeChatNameInfo from "../changeChatNameInfo/changeChatNameInfo";
import WhoCreatedTheConversation from "../Who created the conversation/WhoCreatedTheConversation";
import JoinTheGroup from "../joinTheGroup/joinTheGroupButton";
import { getMessageItem } from "../../../../redux/reducer/getMessageItem/action/action";
import moment from "moment";
import "moment/locale/ru";
import "./chatBodyLight.css";
import "./chatBodyDark.css";

const ChatBody = () => {
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const chatID = searchParams.get("chatID");
    const chat_filter = searchParams.get("chat_filter");
    
    const getMessageItems = useSelector(state => state.userReducerMessageItem.data);
    const getDarkMode = useSelector(state => state.ToggleDarkModeReducer.chekced);
    const getChatInfo = useSelector(state => state.getChatInfoByIDReducer.data);
    const getMyInfo = useSelector(state => state.getMayInfoReducer.myinfo);

    const [isAtBottom, setIsAtBottom] = useState(true);
    const [containerHeight, setContainerHeight] = useState(0);

    // Определение текущей даты
    const currentDate = useMemo(() => moment().format("YYYY-MM-DD"), []);

    // Преобразование даты
    const convertDate = useCallback((date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        if (formattedDate === currentDate) {
            return "Сегодня";
        }
        return moment(date).locale("ru").format("D MMMM YYYY г.");
    }, [currentDate]);

    const handleNavigate = useCallback(() => {
        if (chat_filter && chatID) {
            navigate(`?chatID=${chatID}`);
            dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
        }
    }, [chat_filter, chatID, dispatch, navigate]);

    const scrollToItem = useCallback((itemID) => {
        const element = document.getElementById(`message-${itemID}`);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
            element.style.backgroundColor = getDarkMode ? "#33363B" : "#F4F8FE";
            setTimeout(() => {
                element.style.backgroundColor = "";
            }, 2000);
        }
    }, [getDarkMode]);

    const scrollToBottom = useCallback(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, []);

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            const tolerance = 5;
            setIsAtBottom(container.scrollHeight - container.scrollTop - container.clientHeight <= tolerance);
        }
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
            return () => container.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    // Сохраняем позицию прокрутки
    useEffect(() => {
        const savedScroll = sessionStorage.getItem(`chat-scroll-${chatID}`);
        if (savedScroll && containerRef.current) {
            containerRef.current.scrollTop = savedScroll;
        } else {
            scrollToBottom();
        }
    }, [chatID, location.pathname, getMessageItems]);

    const handleSaveScrollPosition = useCallback(() => {
        if (containerRef.current) {
            sessionStorage.setItem(`chat-scroll-${chatID}`, containerRef.current.scrollTop);
        }
    }, [chatID]);

    // Обработчик для сохранения прокрутки при уходе с чата
    useEffect(() => {
        return () => handleSaveScrollPosition();
    }, [handleSaveScrollPosition]);

    if (!chatID) return null;

    return (
        <div className={getDarkMode ? "chatBodyDarkCont" : "chatBodyLightCont"}>
            <div ref={containerRef} id="chatBodyChildCont" onScrollCapture={handleSaveScrollPosition}>
                {getMessageItems?.map((item, index) => {
                    const CommonItem = item.typeMessage === "reply" ? ReplyMessageItem : MessageItems;
                    return (
                        <div key={item._id}>
                            {item.gropuDate && (
                                <div className="datemessageCont">
                                    <p>{convertDate(item.gropuDate)}</p>
                                </div>
                            )}
                            {item.typeMessage === "changeChatName" ? (
                                <ChangeChatNameInfo item={item} />
                            ) : (
                                <CommonItem item={item} scrollToItem={scrollToItem} index={index} />
                            )}
                        </div>
                    );
                })}
                {getChatInfo?.chatOptions?.chatParticipantsID?.some(
                    (id) => id !== getMyInfo._id
                ) && <JoinTheGroup />}
                {getChatInfo?.chatOptions?.creatorOfThisChatInfo && <WhoCreatedTheConversation />}
            </div>
        </div>
    );
};

export default React.memo(ChatBody);
