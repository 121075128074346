import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { NavLink } from "react-router-dom"
import "./noPage.css"
import "./noPageDark.css"
import icon from "./LogoNatification.png"
import { useSelector } from "react-redux"
{/* <button ={() => window.location = 'mailto::support@упрости.рф'}>Contact Me</button> */ }
let darkStorage = localStorage.getItem("isDark");


export default function NoPage(params) {

        const [isDark, setisDark] = useState(false)
        const navigate = useNavigate()
        const [loadPage,setLoadPage] = useState(false)
        useEffect(() => {
                setisDark(JSON.parse(darkStorage))
                setLoadPage(true)

        }, [])
                if (loadPage) {
                        return <div className={isDark ? "errorContDark" : "errorCont"}>
                        <div className="errorContChild">
                                <img src={icon} alt="" />
                                <div className="Center">
                                        <div className="Heading">
                                                <p className="errorP">404 Ошибка</p>
                                                <p className="defaundP">Страница не найдена</p>
                                                <p className="InfoP">К сожалению, мы не смогли найти страницу, которую вы ищете.</p>
                                        </div>
                                        <div className="Items" onClick={() => navigate("/wildberries")}>
                                                <div className="itemsChild1">
                                                        <div className="iconBaseCont">
                                                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M14.5 2.125H6.5C5.4 2.125 4.51 3.025 4.51 4.125L4.5 20.125C4.5 21.225 5.39 22.125 6.49 22.125H18.5C19.6 22.125 20.5 21.225 20.5 20.125V8.125L14.5 2.125ZM6.5 20.125V4.125H13.5V9.125H18.5V20.125H6.5Z" fill="#808080" />
                                                                </svg>
                                                        </div>
                                                        <div className="itemsChild1Cont">
                                                                <p>WB</p>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                <path d="M8.79688 16.715L13.3769 12.125L8.79688 7.535L10.2069 6.125L16.2069 12.125L10.2069 18.125L8.79688 16.715Z" fill="#CCCCCC" />
                                                        </svg>
        
                                                </div>
                                        </div>
                                        <div className="Items" onClick={() => navigate("/ozon")}>
                                                <div className="itemsChild1">
                                                        <div className="iconBaseCont">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                        <path d="M14.5 12.125L12.5 14.125L10.5 12.125L12.5 10.125L14.5 12.125ZM12.5 6.125L14.62 8.245L17.12 5.745L12.5 1.125L7.88 5.745L10.38 8.245L12.5 6.125ZM6.5 12.125L8.62 10.005L6.12 7.505L1.5 12.125L6.12 16.745L8.62 14.245L6.5 12.125ZM18.5 12.125L16.38 14.245L18.88 16.745L23.5 12.125L18.88 7.505L16.38 10.005L18.5 12.125ZM12.5 18.125L10.38 16.005L7.88 18.505L12.5 23.125L17.12 18.505L14.62 16.005L12.5 18.125Z" fill="#808080" />
                                                                </svg>
                                                        </div>
                                                        <div className="itemsChild1Cont">
                                                                <p>OZON</p>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                <path d="M8.79688 16.715L13.3769 12.125L8.79688 7.535L10.2069 6.125L16.2069 12.125L10.2069 18.125L8.79688 16.715Z" fill="#CCCCCC" />
                                                        </svg>
        
                                                </div>
                                        </div>
                                        <div className="Items" onClick={() => navigate("/task")}>
                                                <div className="itemsChild1">
                                                        <div className="iconBaseCont">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                        <path d="M20.5 2.125H4.5C3.4 2.125 2.5 3.025 2.5 4.125V20.125C2.5 21.225 3.4 22.125 4.5 22.125H20.5C21.6 22.125 22.5 21.225 22.5 20.125V4.125C22.5 3.025 21.6 2.125 20.5 2.125ZM8.5 20.125H4.5V16.125H8.5V20.125ZM8.5 14.125H4.5V10.125H8.5V14.125ZM8.5 8.125H4.5V4.125H8.5V8.125ZM14.5 20.125H10.5V16.125H14.5V20.125ZM14.5 14.125H10.5V10.125H14.5V14.125ZM14.5 8.125H10.5V4.125H14.5V8.125ZM20.5 20.125H16.5V16.125H20.5V20.125ZM20.5 14.125H16.5V10.125H20.5V14.125ZM20.5 8.125H16.5V4.125H20.5V8.125Z" fill="#808080" />
                                                                </svg>
                                                        </div>
                                                        <div className="itemsChild1Cont">
                                                                <p>Задачи</p>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                                <path d="M8.79688 16.715L13.3769 12.125L8.79688 7.535L10.2069 6.125L16.2069 12.125L10.2069 18.125L8.79688 16.715Z" fill="#CCCCCC" />
                                                        </svg>
        
                                                </div>
                                        </div>
                                </div>
                                <div className="Links">
                                        <div className="linksCont" onClick={() => window.location = 'mailto::support@упрости.рф'}>
                                                <p>Написать в поддержку</p>
                                        </div>
                                        <div className="linksCont2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M16.6654 9.16658H6.5237L11.182 4.50825L9.9987 3.33325L3.33203 9.99992L9.9987 16.6666L11.1737 15.4916L6.5237 10.8333H16.6654V9.16658Z" fill="#3B79F6" />
                                                </svg>
                                                <p onClick={() => navigate("/")}>Вернуться домой</p>
                                        </div>
                                </div>
                        </div>
                </div>           
                }else{
                        return <h1 style={{width:"100%",textAlign:'center'}}>Загрузка...</h1>
                }
      
}