const ToggleDarkModeState = {

    chekced:false,
  
    
  };
  
  const ToggleDarkModeReducer = (state = ToggleDarkModeState, action) => {
        
            if (action.type === "changeDarkModeToggle") {
                return{
                    ...state,
                    
                    chekced:state.chekced = action.value,
             
                }
            }

            return state
  };
  
  export default ToggleDarkModeReducer;
  