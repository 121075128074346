import axios from "axios";
import "./addNewAdminButton.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Button,
  ButtonGroup,
  FormHelperText,
  Form,
  Switch 
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { useToast } from '@chakra-ui/react'
import { getAdminAllAction } from "../../../../../redux/reducer/getAllAdmin/action/action";
import { useDispatch } from "react-redux";


export default function AddNewAdminButton({currentPage}) {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef(null);
  const finalRef = useRef(null);
const [loading,setLoading] = useState(false)
const toast = useToast()


  const handleAddNewAdmin = (e) => {
    e.preventDefault()
    setLoading(true)
    axios.post('/addnewAdmin/api', {
        tel:e.target.tel.value,
        email:e.target.email.value,
          isChefAdmin:e.target.ChefAdmin.checked
      })
      .then(function (response) {
        console.log(response.data.message);
        setLoading(false)
        toast({
            position: 'top',
            title: 'Внимание.',
            description: response.data.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
          dispatch(getAdminAllAction(`/getalladmin/api?page=${currentPage}`));
      })
      .catch(function (error) {
        console.log(error.response.data.message);
        
        setLoading(false)
        toast({
            position: 'top',
            title: 'Внимание.',
            description: error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })

      });
  };
  return (
    <div className="addnewadminCont">
      <button onClick={onOpen}>
        <span>Добавить администратора</span>
        <span className="material-symbols-outlined">add</span>
      </button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Добавить администратора</ModalHeader>
          <form onSubmit={handleAddNewAdmin}>
            <ModalBody pb={6}>
              <FormControl mt={4}>
                <FormLabel>Номер администратора</FormLabel>
                <Input placeholder="700000000" type="tel" name="tel" required  pattern="[0-9]+" minlength="1" maxlength="15"  isRequired/>
                <FormLabel>Номер администратора</FormLabel>
                <Input placeholder="@mail.com" type="email" name="email" required  minlength="1"   isRequired/>
              </FormControl>

              
              <FormControl display='flex' alignItems='center'>
              {/* sweetch */}
            <FormLabel htmlFor='email-alerts' mb='0' style={{marginTop:"20px"}}>
            Сделать главным администратором?
            </FormLabel>
            <Switch id='email-alerts' name="ChefAdmin" style={{marginTop:"20px"}}/>
          </FormControl>
          {/* =================== */}
            </ModalBody>
            <ModalFooter>
              <FormControl>
                {loading?
                 <Button
                 isLoading
                 loadingText='Загрузка'
                 colorScheme="white"
                 mr={3}
                 backgroundColor={"#5E82DF"}
             >
                 Submit
             </Button>
                :
                <Button
                  colorScheme="white"
                  type="submit"
                  backgroundColor={"#5E82DF"}
                  mr={3}
                >
                  Добавить
                </Button>
                }
                
               


                <Button onClick={onClose}>Закрывать</Button>
              </FormControl>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}
