import { Pagination } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

import "./addNewAdmin.css";

import { useEffect, useRef, useState, useTransition } from "react";
import { getadminInfoAction } from "../../../../redux/reducer/getAdminInfo/action/action";
import { getAdminAllAction } from "../../../../redux/reducer/getAllAdmin/action/action";
import ModalEditAdmin from "./ModalEditAdmin/modalEditAdmin";
import DeleteAdmin from "./deleteAdmin/deleteAdmin";
import AddNewAdminButton from "./addNewAdminButton/addNewAdminButton";
import EditMyDataAdmin from "./editMyDataAdmin/editMydataAdmin";

export default function AllAdmin() {
  const [show, setShow] = useState(false);
  const [mshow, msetShow] = useState(false);
  const [data, setData] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [seatchVal, setseatchVal] = useState("");

  const [isPending, startTransition] = useTransition();
  const dispatch = useDispatch();

  const getAllAdmin = useSelector((users) => users);
  const getmyinfo = useSelector((users) => users);



  
  useEffect(() => {
    dispatch(getadminInfoAction("/getadmininfo/admin/api"));
  
  }, [dispatch]);

  useEffect(() => {
   
    if (seatchVal == "") {
      dispatch(getAdminAllAction(`/getalladmin/api?page=${currentPage}`));
    }else{
      dispatch(
        getAdminAllAction(
        `/searchbyinputadmins/api?search=${selectForm.current.browser.value}&page=${currentPage}`
      )
    );
        }

   
  }, [currentPage]);

  const selectForm = useRef(null);

  // for input seacrh
  const handleSubmit = (e) => {
   
    
    startTransition(() => {
          if (selectForm.current.browser.value == "") {
            dispatch(getAdminAllAction(`/getalladmin/api?page=${currentPage}`));
          }else{
            dispatch(
                getAdminAllAction(
                `/searchbyinputadmins/api?search=${selectForm.current.browser.value}&page=${currentPage}`
              )
            );
          }
         
          setseatchVal(e.target.value)
          e.PreventDefault();
          selectForm.current.submit();
      });
    
  };

  const setDataForEdit = (item) => {
    setData(item);
    console.log(data);
    setShow(true);
  };


  const setEditMyData = (item) => {
    setData(item);
    console.log(data);
    msetShow(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);

  };
console.log();
  if (getAllAdmin.getAllAdminReducer.allAdmin.data != undefined &&  getAllAdmin.getAllAdminReducer.allAdmin.data != "") {
    return (
      <>
        
        <h1 className="titleH1">Поиск администратора</h1>
        <form
          className="searchContForm"
          onChange={handleSubmit}
          ref={selectForm}
        >
            
          <input
            list="browsers"
            name="browser"
            id="browser"
            placeholder="Поиск администратора"
            value={seatchVal}
          />
          <datalist id="browsers">
            <option value="Edge" />
            <option value="Firefox" />
            <option value="Chrome" />
            <option value="Opera" />
            <option value="Safari" />
          </datalist>
        </form>
        {getAllAdmin.adminReducer?.data?.data?.chiefadministrator?
        <AddNewAdminButton currentPage={currentPage}/>
        : 
        <p></p>
        }
        
        <div className="allAdmin">
          {getAllAdmin.getAllAdminReducer.loading?
          <div className="loading">
                  <Spinner animation="border" size="sm" />

          </div>
          :
          <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <ModalEditAdmin setShows={setShow} shows={show} data={data} currentPage={currentPage}/>
                <EditMyDataAdmin setShows={msetShow} shows={mshow} data={data} currentPage={currentPage}/>
                <Th>Имя</Th>
                <Th>Тел.</Th>
                <Th >электронная почта</Th>
                <Th isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody>
              {getAllAdmin.getAllAdminReducer.allAdmin.data.map(
                (item, index) => {
                  if(getAllAdmin.adminReducer.data.data.chiefadministrator){
                    if (getmyinfo.adminReducer.data.data._id == item._id) {
                      return (
                        <Tr key={index} >
                          <Td onClick={() => setDataForEdit(item)}  >
                            {item.name}
                          </Td>
                          <Td onClick={() => setDataForEdit(item)}>
                            {item.tel}{" "}
                          </Td>
                          <Td onClick={() => setDataForEdit(item)} >
                            {item.email}
                          </Td>
                          <Td isNumeric style={{padding:"20px"}}>
                            {" "}
                            
                          </Td>
                        </Tr>
                      );
                    }else{
                      return (
                        <Tr key={index}>
                          <Td onClick={() => setDataForEdit(item)} >
                            {item.name}
                          </Td>
                          <Td onClick={() => setDataForEdit(item)}>
                            {item.tel}{" "}
                          </Td>
                          <Td onClick={() => setDataForEdit(item)} >
                            {item.email}
                          </Td>
                          <Td isNumeric>
                            {" "}
                            <DeleteAdmin item={item} currentPage={currentPage}/>{" "}
                          </Td>
                        </Tr>
                      );
                    }
                    
                  }else{
                      if (getmyinfo.adminReducer.data.data._id == item._id) {
                        return (
                          <Tr key={index} >
                            <Td onClick={() => setEditMyData(item)}  >
                              {item.name}
                            </Td>
                            <Td onClick={() => setEditMyData(item)}>
                              {item.tel}{" "}
                            </Td>
                            <Td onClick={() => setEditMyData(item)} >
                              {item.email}
                            </Td>
                            <Td isNumeric>
                              {" "}
                              <span class="material-symbols-outlined" style={{color:"#5E82DF"}} onClick={() => setEditMyData(item)}>edit</span>


                            </Td>
                          </Tr>
                        );
                      }else{
                        return (
                          <Tr key={index}>
                            <Td  style={{padding:"15px"}}>
                              {item.name}
                            </Td>
                            <Td >
                              {item.tel}{" "}
                            </Td>
                            <Td  >
                              {item.email}
                            </Td>
                            <Td isNumeric>
                              
                              
                            </Td>
                          </Tr>
                        );
                      }
                   
                  }
                 
                }
              )}
            </Tbody>
          </Table>
        </TableContainer> 
          }
         
          
        </div>
        <div className="paginationAdmin">
        <Pagination total={getAllAdmin.getAllAdminReducer.allAdmin.totalPages} initialPage={getAllAdmin.getAllAdminReducer.allAdmin.currentPage} onChange={handlePageChange}/>
           
          </div>
      </>
    );
  }
  return (
    <>
      <h1>Нет пользователей</h1>
   
      <form className="searchContForm" onChange={handleSubmit} ref={selectForm}>
        <input
          list="browsers"
          name="browser"
          id="browser"
          placeholder="Поиск компании"
        />
        <datalist id="browsers">
          <option value="Edge" />
          <option value="Firefox" />
          <option value="Chrome" />
          <option value="Opera" />
          <option value="Safari" />
        </datalist>
        
      </form>
    </>
  );
}
