import axios from 'axios';
import React, { useState } from 'react';
import { fetchUsers } from '../../../../redux/reducer/postFetcReducer/action/action';
import { useDispatch } from "react-redux";
import { getMyCompany } from '../../../../redux/reducer/getCompany/action/action';
import { getCompanyForChangeAPI } from '../../../../redux/reducer/myCompanyAPI/action/action';
import { checkedSessionAction } from '../../../../redux/reducer/getCheckedCompanySession/action/action';
import { useToast } from '@chakra-ui/react'
import DeleteCompanyModal from './deleteCompanyModal';

export default function DeleteCompany({ items }) {

  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const toast = useToast()





  const handleDeleteCompany = (e) => {

    dispatch(checkedSessionAction("/getCheckedCompanySession"));
    dispatch(getMyCompany("/mycopany/getallcompany"))

    dispatch(fetchUsers())
    axios.delete('/mycompany/delete', {
      data: {
        companyId: items.companyId,

      }
    })
      .then(function (response) {
        dispatch(fetchUsers())
        setShow(false)
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));
        dispatch(getMyCompany("/mycopany/getallcompany"))
      })
      .catch(function (error) {
        console.log(error);

        setShow(false)
        toast({
          position: "top",
          title: 'Ошибка',
          description: error.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      }).finally(final => {
        dispatch(fetchUsers())
      }).finally(finall => {
        setShow(false)
        dispatch(fetchUsers())
        dispatch(getCompanyForChangeAPI("/changeapi/api"));
        dispatch(checkedSessionAction("/getCheckedCompanySession"));
        dispatch(getMyCompany("/mycopany/getallcompany"))
      })

  }




  return (
    <>

      {/* <span className="material-symbols-outlined" style={{ color: "#DB5F95", cursor: "pointer" }} onClick={handleShow}>delete</span> */}


      <DeleteCompanyModal show={show} setShow={setShow} handleDeleteCompany={handleDeleteCompany} />
    </>
  )
}