const getMyImage= {
    users: [],
    loading: false,
    error: null
  };
  
  const getMyImageReducer = (state = getMyImage, action) => {
    switch (action.type) {
      case 'FETCH_IMAGE_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_IMAGE_SUCCESS':
    
        return {
          ...state,
          loading: false,
          users: action.payload
        };
        
      case 'FETCH_IMAGE_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getMyImageReducer;
  