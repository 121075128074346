import { useEffect, useRef, useState } from "react";
// import NavBarLightTop from "../NewNavBarTop/navbarLigthTop";
import "./navBarCont.css"
import "./navBarContDark.css"
import { Outlet } from "react-router";
// import NavBarLeftLIgth from "../NewNavBarLeft/navBarLeftLIgth";
// import NavLeftBarLigthMobile from "../NewNavBarLeftMobile/navLeftBarLigthMobile";
import { useDispatch, useSelector } from "react-redux";
import { toggleDarkModeAction } from "../../../redux/reducer/toggleDarkMode/action/action";
import NavBarTop from "../NewNavBarTop/navBarTop";
// import navBarLeftDeskop from "../navBarLeftDeskop/navBarLeftDeskop";
import NavBarLeftDeskop from "../navBarLeftDeskop/navBarLeftDeskop";
import NavLeftBarMobile from "../NewNavBarLeftMobile/navLeftBarMobile";
import NavBottomForMobileAndPlamshet from "../navBottomForMobileAndPlamshet/navBottomForMobileAndPlamshet";

const isDarkstorage = JSON.parse(localStorage.getItem('isDark'));

export default function NavBarCont() {

     const getDarkModoe = useSelector(darkModes => darkModes)
     const dipatch = useDispatch()
     const [isDark, setSsDark] = useState(false)
     const outlineIsDark = useRef(null)

     useEffect(() => {
          if (isDarkstorage == null || isDarkstorage == false) {
               setSsDark(false)
               dipatch(toggleDarkModeAction(false))
          }else{
               setSsDark(true)
               dipatch(toggleDarkModeAction(true))
          }
        
     }, [])



     const handleChangetema = () => {

          setSsDark(!isDark)
          localStorage.setItem('isDark', JSON.stringify(!isDark)); // or false
          dipatch(toggleDarkModeAction(!isDark))

     }

     
     return (
          <div className={isDark ? "allcontDark" : "allcont"} >
                <div className="LeftMenuContDekStop">
                         <NavBarLeftDeskop handleChangetema={handleChangetema} />
                        
               </div>
             
               {/* <NavBarLightTop />
               <div className="ContOutLetRigthMenu">
                    <div className="leftmenu" id="leftmenu">
                         <NavBarLeftLIgth handleChangetema={handleChangetema} isDark={isDark} />
                    </div>
                    <div className="leftmenuMobile">
                         <NavLeftBarLigthMobile isDark={isDark} handleChangetema={handleChangetema} />
                    </div>

                    <div className={isDark ? "OutletDark" : "Outlet"} id="Outlet" ref={outlineIsDark}>
                         <Outlet />
                    </div>
               </div> */}
                    <div className="outletAndTopMenu">  
                    
                    <NavLeftBarMobile/>
                         <NavBarTop />
                        
                           <Outlet />
                         <NavBottomForMobileAndPlamshet />
                    </div>
                 
              
          </div>
     )


}