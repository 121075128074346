import { useEffect, useRef } from "react"
import ChangeCompanyDrower from "../changeCompany/changeCompany"
import NotifyToggle from "../notifyToggle/notifyToggle"
import { useNavigate } from "react-router";

export default function NavBarForPlanshet({toggleSearchCont,hanldetoggleSearch}) {
    const navbarContForDekstop = useRef();
    const navigate = useNavigate();
   
    return (
        <div className="PlanshetCont">
            <div className="PlanshetContLogoCont" onClick={()=> navigate("/")}>
                <div>
                    <div className="iconContLogo"></div>
                    <p>Упрости</p>
                </div>
            </div>
            <div className="rightItemPlanshet">
                <div className="minmizeSearchPlanshet" onClick={hanldetoggleSearch}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.61522 0.25 1.25 4.61522 1.25 10C1.25 15.3848 5.61522 19.75 11 19.75C16.3848 19.75 20.75 15.3848 20.75 10C20.75 4.61522 16.3848 0.25 11 0.25ZM2.75 10C2.75 5.44365 6.44365 1.75 11 1.75C15.5563 1.75 19.25 5.44365 19.25 10C19.25 14.5563 15.5563 18.25 11 18.25C6.44365 18.25 2.75 14.5563 2.75 10Z" fill="#353535" />
                        <path d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L19.5304 17.4698Z" fill="#353535" />
                    </svg>
                </div>
                <div className="dropContTopPlanshet">
                    <ChangeCompanyDrower />
                </div>
                <div>
                    <button className="addCompanyBTNTopNavPlanshet" >
                        <div>
                            <div className="svgContBTN">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                                </svg>
                            </div>
                            <p>Добавить компанию</p>
                        </div>
                    </button>
                </div>
                <div className="questionMarkPlanshet">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M7.90039 8.07955C7.90039 3.30679 15.4004 3.30683 15.4004 8.07956C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 19.0101L12.01 18.999" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
                <NotifyToggle />
            </div>
        </div>
    )
}