import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import "./ozomodalEdit.css"
import "./ozomodalEditDark.css"
import { Select,Input, Img  } from '@chakra-ui/react'
import SelectOzonEdit from '../SelectOzonEdit/selectOzonEdit';
import TagInputOzonEdit from '../SelectOzonEditTag/selectOzonEditTag';
import { Checkbox, CheckboxGroup,Textarea ,Text } from '@chakra-ui/react'
import OzonTextArea from '../ozonEditTextArea/ozonEditTextArea';
import OzonEditInput from '../ozonEditInput/ozonEditInput';
import OzonEditBool from '../ozonEditBool/ozonEditBool';
import { Button, ButtonGroup } from '@chakra-ui/react'
import Form from 'react-bootstrap/Form';
import editJson from "../ozoEdit.json"
import OzonProductinfoEdit from '../ozonEditInfo/ozonEditInfo';

import { useToast } from '@chakra-ui/react'
import OzoUploadEdit from '../ozonUploadEdit/ozoUploadEdit';
import { useSelector } from 'react-redux';


export default function OzoModalEdit({item}) {

    const [show, setShow] = useState(false);
    
    const [alldata, setalldata] = useState(false);
    const [attributs, setattributs] = useState(false);
    const [loading,setloading] = useState(false)
    const [editImage,seteditImage] = useState(false)
    const handleClose = () => setShow(false);
    const toast = useToast()
  const [loadingedit,setloadingedit] = useState(false)
  const [dataDeletImage,setdataDeletImage] = useState([])

  const getDarkModoe = useSelector(darkModes => darkModes )
    const handleShow = useCallback(() => {
   
      setShow(true)
      setloading(true)
      axios({
        method: 'post',
        url: '/ozon/getattributesValueForMoadl/api',
        data:{
          item
        }
      })
        .then(function (response) {
          
            
               
              // setalldata(response.data.data.sort((a,b)=> a.id - b.id))
         
            setalldata(response.data.data)
            setloading(false)
        }).catch(error=>{
          setloading(false)
        })
    }, [show]);
    
// GET INPUT VALUE IN INPUT AND PUSH JSON FOR SEND SERVER
    const sendServer=(e)=>{
          e.preventDefault()
          console.log("sending server");
          editJson.items[0].attributes = []
        // selectEdit Input==================================================================
          let arrozonInputEdit =[]
          let ozonInputEdit= Array.from(e.target.selectsozonEdit)
       
             for (let i = 0; i < ozonInputEdit.length; i++) {
                      arrozonInputEdit.push(JSON.parse(ozonInputEdit[i].value))
             }
             for (let i = 0; i < arrozonInputEdit.length; i++) {
                if ( arrozonInputEdit[i].attribute_id) {
                  arrozonInputEdit[i].id = arrozonInputEdit[i].attribute_id
                  delete  arrozonInputEdit[i].attribute_id
                }
            
                if (arrozonInputEdit[i] instanceof Array) {
                      
                }else{
                 
              
                  editJson.items[0].attributes.push(arrozonInputEdit[i])
                  // set in json
              
                
                }
              
             }
         // selectEdit End==================================================================

        //  selectEditTag=============================================================================
        let ozonSelectEditTagArray = [];
      let ozonSelectEditTag =  Array.from(e.target.selectsozonEditTag)
              for (let i = 0; i < ozonSelectEditTag.length; i++) {
                 ozonSelectEditTagArray.push(JSON.parse(ozonSelectEditTag[i].value))
                
              }
         for (let i = 0; i < ozonSelectEditTagArray.length; i++) {
   
            for (let k = 0; k < ozonSelectEditTagArray[i].values.length; k++) {

            }
          
         }
         for (let i = 0; i < ozonSelectEditTagArray.length; i++) {
          if (ozonSelectEditTagArray[i] instanceof Array) {
                      
          }else{
            //  console.log(ozonSelectEditTagArray[i]);
            // set in json
            editJson.items[0].attributes.push(ozonSelectEditTagArray[i])
          }
         }
        //  console.log(item);

          //  selectEditTag   end=============================================================================

          // textarea ozon Edit==================================================================================

let textareaOzonEditArray = []
let textareaOzonEdit = Array.from(e.target.textAreaHidden)
                  for (let i = 0; i < textareaOzonEdit.length; i++) {
                                  textareaOzonEditArray.push(JSON.parse(textareaOzonEdit[i].value))
                    
                  }

                  for (let k = 0; k < textareaOzonEditArray.length; k++) {
                          if (textareaOzonEditArray[k] != "") {
                                  // console.log(textareaOzonEditArray[k]);
                                   // set in json
                                   editJson.items[0].attributes.push(textareaOzonEditArray[k])
                          }
                  }

                    // textarea ozon Edit END==================================================================================
                    // ozonEditInput ozon ==================================================================================

              let ozonEditInputArra =[]
              let ozonEditInput = Array.from(e.target.inputOzonEdit)
              for (let i = 0; i < ozonEditInput.length; i++) {
                       ozonEditInputArra.push( JSON.parse( ozonEditInput[i].value))
                
              }
              for (let k = 0; k < ozonEditInputArra.length; k++) {
                     
                      if (ozonEditInputArra[k] instanceof Array) {

                      }else{
                        // console.log(JSON.stringify(ozonEditInputArra[k]));
                         // set in json
                         editJson.items[0].attributes.push(ozonEditInputArra[k])
                      }
                
              }

               // ozonEditInput ozon  END==================================================================================
               let ozonEditBollArray =[]
                if (ozonEditBollArray.length > 0) {
                  let ozonEditBoll = Array.from(e.target.ozonEditBool)
                  console.log(ozonEditBollArray);
                if (ozonEditBoll.length > 0) {
                
                       for (let i = 0; i < ozonEditBoll.length; i++) {
                        ozonEditBollArray.push(JSON.parse(e.target.ozonEditBool[i].value))
                  
                 }
                 for (let k = 0; k < ozonEditBoll.length; k++) {
                           console.log(ozonEditBollArray[k]);
                            // set in json
                            // if array
                            editJson.items[0].attributes.push(ozonEditBollArray[k])
  
                 }
                
                }else{
               
                  // set in json
                  // if not  array
              
                  editJson.items[0].attributes.push(JSON.parse(e.target.ozonEditBool.value))
                
                }
                }
             
              // console.log(editJson);

              //characters EN

             let allimages = []
              console.log(e.target.nds.value);
              editJson.items[0].category_id = item.attributs.category_id
              editJson.items[0].depth = e.target.dlinaprod.value
              editJson.items[0].height =  e.target.visota.value
              editJson.items[0].weight =  e.target.wigthprod.value
              editJson.items[0].width = e.target.shirinaprod.value
             
              editJson.items[0].name =  e.target.prodname.value
               editJson.items[0].price =  e.target.pirce.value.toString()
              editJson.items[0].offer_id = item.attributs.offer_id
              editJson.items[0].currency_code = item.typevalute
              editJson.items[0].old_price = e.target.skidprice.value.toString()
              editJson.items[0].vat = e.target.nds.value.toString()
              

                for (let i = 0; i < item.attributs.images.length; i++) {
                            allimages.push(item.attributs.images[i].file_name)
                  
                }

                editJson.items[0].images =  allimages
              
                console.log(editJson);
              setloadingedit(true)
               axios.post('/ozon/editOzonforModal/api', {
                editJson
              })
              .then(function (response) {
                seteditImage(true)
                if (response.data.description == "pending") {
                  toast({
                    position:"top",
                    title: response.data.message,
                    description: "Товар в очереди на обработку",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  })
                }else if(response.data.description == "imported"){
                  toast({
                    position:"top",
                    title: response.data.message,
                    description: "Товар успешно загружен",
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  })
                }
                
                setloadingedit(false)
              })
              .catch(function (error) {
                console.log(error);
                toast({
                  position:"top",
                  title: error.response.data.message,
                  description: error.response.data.description,
                  status: 'error',
                  duration: 2000,
                  isClosable: true,
                })
                setloadingedit(false)
              });
               
    }
 
    



        return(
            
            <div className='ozonEditContainer'>
            <span class="material-symbols-outlined openModalEdit"  style={{color:'#3B79F6'}} onClick={handleShow}>edit_note</span>
            
      
            <Modal show={show} onHide={handleClose} fullscreen>
              <Modal.Header closeButton  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ozonDark":"ozonLight"}>
                <Modal.Title>Редактирование товара</Modal.Title>
              </Modal.Header>
              <Modal.Body  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ozonDark":"ozonLight"}>
                {loading?
                  <div class="ozonModalLoader"></div>
                :
                <form className='editModalOzon' onSubmit={sendServer}>
                  <OzonProductinfoEdit item={item}/>
                  {alldata&&alldata?.map((item,index)=>{
  
                            if (item.values.code != "3"  && item.is_collection == false && item.type !== "multiline") {
                                  if (item.currentinfo) {
                                      // console.log(item.currentinfo.values[0].value);
                                   
                                        
                                    return   (
                                        <SelectOzonEdit item={item} defval={item.currentinfo.values[0].value}/>
                                    )
                                  }else{
                                    return   (
                                      <SelectOzonEdit item={item} defval={""}/>
                                    )
                                  }
                        
                            
                            }else if (item.is_collection == true && item.type !== "multiline") {
                              if (item.currentinfo) {
                                // console.log(item.currentinfo.values[0].value);
                             
                                  
                              return   (
                                  <TagInputOzonEdit item={item} defval={item.currentinfo}/>
                              )
                            }else{
                              return   (
                                <TagInputOzonEdit item={item} defval={""}/>
                              )
                            }
                             
                            } 
                          else if (item.type == "multiline") {
                                 return  <OzonTextArea item={item}/>
                        
                            
                            }
                            else if (item.type == "Boolean") {
                           
                              return <OzonEditBool item={item}/>
                              }
                    
                        
                        else{
                          return  <OzonEditInput item={item} editJson={editJson}/>
                        }
                              
                  })}
                  <OzoUploadEdit  item={item} seteditImage={seteditImage} editImage={editImage} setShow={setShow} />
           
                 <div className='btnEditOzon'>
                  {
                    loadingedit?
                    <Button
                      isLoading
                      loadingText='Загрузка...'
                      colorScheme='blue'
                      variant='outline'
                    >
                      Submit
                    </Button>
                    :
                    <button variant='outline' type='submit' colorScheme='blue' className='creatProductOz'>Редактировать</button>
                  }
                     
                     <Button variant='outline' onClick={handleClose} className='closeModalOzon'>Закрывать</Button>
                 </div>
                
                  </form>
                }
                 
              </Modal.Body >
             
            </Modal>
          </div>

        )
}   