const getMyInfoSatate = {
    myinfo: [],
    loading: false,
    error: null
  };
  
  const getMayInfoReducer = (state = getMyInfoSatate, action) => {
  
    switch (action.type) {
      case 'FETCH_MYINFO_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_MYINFO_SUCCESS':
    
        return {
          ...state,
          loading: false,
          myinfo: action.payload
        };
        
      case 'FETCH_MYINFO_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getMayInfoReducer;
  