

  import axios from "axios"
import  NavBarWB from "./NavBarWB/navBarWb"
import WBtable from "./wbtable/wbTable"
  
import "./wildberries.css"
import { useEffect, useState } from "react"


export default function Wildberries() {
  const [data,setData] = useState([])
  
 
            return (
              <div style={{height:"90vh",margin:"0 2%"}}>
                < NavBarWB />
                < WBtable  datas={data}/>

              
               
              </div>
            )
}