const getMaydataSatate = {
    users: [],
    loading: false,
    error: null
  };
  
  const getMaydataSatateReducer = (state = getMaydataSatate, action) => {
    switch (action.type) {
      case 'FETCH_getMaydata_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_getMaydata_SUCCESS':
    
        return {
          ...state,
          loading: false,
          users: action.payload
        };
        
      case 'FETCH_getMaydata_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getMaydataSatateReducer;
  