import { useSelector } from "react-redux"
import TaskMenegerHeader from "../tableTask/taskMenegerHeader/taskMenegerHeader"
import "./nothingFoundSearchTaskLight.css";
import "./nothingFoundSearchTaskDark.css";

export default function NothingFoundSearchTask({searchByPerfomer,handleSelect,searchValue}) {
    const getDarkModoe = useSelector(darkModes => darkModes);
        return (
            <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "nothingFoundSearchTaskDark" : "nothingFoundSearchTaskLight"}>
                     {/* <TaskMenegerHeader searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue} /> */}
                     <div>
                            <p>Ничего не найдено</p>
                            <p>Проверьте данные которые вы указывали в поиске</p>
                     </div>
            </div>
        )
}