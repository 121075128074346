const initialState = {
  companyID: "",
};

const userReducerCompanyChecked = (state = initialState, action) => {
 
  switch (action.type) {
      case "CHECKED":
          console.log('CHECKED action processed:', action.value);  // Логирование действия "CHECKED"
          return {
              ...state,
              companyID: action.value
          };
      default:
          return state;
  }
};

export default userReducerCompanyChecked;
