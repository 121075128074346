const getAllCompanyForAdminSatate = {
    allCompany: "",
    loading: false,
    error: null
  };
  
  const getAllCompanyForAdminReducer = (state = getAllCompanyForAdminSatate, action) => {
    switch (action.type) {
      case 'FETCH_GETCOMPANYFORADMIN_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_GETCOMPANYFORADMIN_SUCCESS':
    
        return {
          ...state,
          loading: false,
          allCompany: action.payload
        };
        
      case 'GETCOMPANYFORADMIN_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getAllCompanyForAdminReducer;
  