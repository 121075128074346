import axios from "axios"
import { useState,useRef,useEffect, lazy, Suspense} from "react";
import "./uploadFile.css"
import "./uploadFileDark.css"
import { useSelector } from "react-redux";
import zipIcon from "./iconUpload/zipIcon.png"
import pdf from "./iconUpload/pdf.png"
import exel from "./iconUpload/exel.png"
import world from "./iconUpload/world.png"
import otherFilre from "./iconUpload/otherFilre.png"
import UploadFileEditList from "./downloadBtnFile/downloadBtnFile";


export default function UploadFileTaskMeneger({ handleChangeFile }) {

  const [files, setFile] = useState([]);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const modalRefFile = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);


  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFile(prevFiles => [...prevFiles, ...selectedFiles]);
    
  };

  const handleDeleteLocal = (itemToDelete, event) => {
    event.stopPropagation()

    setFile(prevArray => {
      // Фильтрация массива, оставляем только те элементы, которые не совпадают с itemToDelete по lastModified
      const newArray = prevArray.filter(item => item.lastModified !== itemToDelete.lastModified);
      return newArray; // Обновляем состояние с отфильтрованным массивом
    });
  };

  const closeModal = () => {

    modalRefFile.current.style.display = "none"
  }

  useEffect(() => {
    modalRefFile.current.style.display = "none";
  }, [])

  const openModalForShow = (index) => {
    setCurrentSlide(index)
    modalRefFile.current.style.display = "flex"
  }


  
  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? files.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === files.length - 1 ? 0 : prev + 1));
  };

  const downloadFileForLocal = (item, e) => {
    e.stopPropagation()
    const fileURL = URL.createObjectURL(item);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.name || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);

  };

  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "uploadFileDark" : "uploadFileLight"}>
      <div>
        <div>
          <label htmlFor="upload">
            <p>Добавить файлы</p>
            <input type="file" id="upload" style={{ display: "none" }} onChange={handleFileChange} multiple />
          </label>
        </div>

        <div>
        <Suspense fallback={<div>Загрузка...</div>}>
            {
              files.map((item, index) => {
                return (
                  <div key={index} onClick={(e) => openModalForShow(index, e)}>
                    {
                      (() => {

                        if (item.type === "image/png" || item.type === "image/jpeg") {
                          return <img
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        } else if (item.type === "video/mp4") {
                          return <video
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            loading="lazy"


                          />
                        } else if (item.mimetype === "image/png" || item.mimetype === "image/jpeg") {
                          return <img
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        } else if (item.mimetype === "video/mp4") {
                          return <video
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        }
                        else {
                          return <img src={otherFilre} alt="" loading="lazy"/>
                        }
                      })()
                    }
                    <UploadFileEditList item={item}  handleDeleteLocal={handleDeleteLocal} handleDownload={downloadFileForLocal} handleDeleteFile={downloadFileForLocal} index={index} />
                  </div>
                )
              })
            }
          </Suspense>


        </div>
      </div>
      <div>
        <div>
          <div className="myModalSliderUpload" ref={modalRefFile}>
            <div className="modal-contentSliderUpload">
              <div className="modal-header">
                <p>{files[currentSlide]?.name}</p>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" onClick={closeModal}>
                    <path d="M1.5 1L15.5 15M1.50003 15L8.50003 8L15.5 1" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="slider">
                  {/* Объединение файлов из базы данных и локальных файлов */}

                  {
                    (() => {
                      if (files[currentSlide]?.type == "image/png" || files[currentSlide]?.type == "image/jpeg") {
                        return <img src={URL.createObjectURL(files[currentSlide])} alt="" className="slide-item" />
                      } else if (files[currentSlide]?.type == "video/mp4") {
                        return <video src={URL.createObjectURL(files[currentSlide])} className="slide-item" controls></video>
                      } else if (files[currentSlide]?.mimetype == "image/png" || files[currentSlide]?.mimetype == "image/jpeg") {
                        return <img src={`/${files[currentSlide]?.filename}`} alt={`Slide ${currentSlide + 1}`} className="slide-item" />
                      } else if (files[currentSlide]?.mimetype == "video/mp4") {
                        return <video
                          src={`/${files[currentSlide]?.filename}`} // Используем корректный путь
                          className="slide-item"
                          controls
                        ></video>
                      }
                      else {
                        return <img src={"https://www.iconpacks.net/icons/2/free-file-icon-1453-thumb.png"} alt="" />
                      }
                    })()
                  }
               

               <button className="prev" onClick={handlePrevSlide}>&#10094;</button>
               <button className="next" onClick={handleNextSlide}>&#10095;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}