const initialState = {
    users: [],
    loading: false,
    error: null
  };
  
  const userReducerCompany = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_COPMANY_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_COPMANY_SUCCESS':
    
        return {
          ...state,
          loading: false,
          users: action.payload
        };
        
      case 'FETCH_COPMANY_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userReducerCompany;
  