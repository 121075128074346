const initialState = {
  filters: false, // Начальное значение filters — false
  taskFiltersObject: {},
  settings: "default", // Пример для другого значения, которое может изменяться
  openMenuCheckedCompany: false,
  myProject: false,
};

const optionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FILTERS":
      // Логика для обновления фильтров
      return {
        ...state,
        filters: action.payload, // Сохраняем переданное значение для filters
      };
    case "UPDATE_FILTERS_OBJECT":
      // Логика для обновления фильтров
      return {
        ...state,
        taskFiltersObject: action.payload, // Добавляем новое значение в массив
      };

    case "UPDATE_CHECKED_MENU_COMPANY":
      // Логика для обновления фильтров
      return {
        ...state,
        openMenuCheckedCompany: action.payload, // Сохраняем переданное значение для filters
      };

      case "UPDATE_MY_PROJECT_COMPANY":
        // Логика для обновления фильтров
        return {
          ...state,
          myProject: action.payload, // Сохраняем переданное значение для filters
        };
    case "UPDATE_SETTINGS":
      // Логика для обновления настроек
      return {
        ...state,
        settings: action.payload, // Сохраняем переданное значение для settings
      };

    // Добавьте другие типы, если нужно

    default:
      return state;
  }
};

export default optionReducer;
