import React, { useState, useEffect } from 'react';
import QrScanner from 'react-qr-scanner';
import axios from 'axios'; // Убедитесь, что axios установлен
import { useToast } from '@chakra-ui/react';
import sockets from '../../../../socket/socket';
import { useSelector } from 'react-redux';
const socket = sockets;


const QRScannerInner = () => {
    const [isSupported, setIsSupported] = useState(true);
    const [facingMode, setFacingMode] = useState('environment');
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [loading, setLoading] = useState(false); // Для отслеживания состояния запроса
    const toast = useToast({ position: 'top' });
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer.myinfo);


  useEffect(() => {
    // Проверяем, поддерживает ли браузер доступ к камере
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setIsSupported(false);
    } else {
      // Получаем список устройств
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const videoDevices = devices.filter(device => device.kind === 'videoinput');
          if (videoDevices.length === 0) {
            setIsSupported(false);
            toast({
              title: "Ошибка",
              description: "Камеры не найдены.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          } else {
            console.log('Доступные камеры:', videoDevices);
          }
        })
        .catch(err => {
          console.error('Ошибка при получении устройств:', err);
          toast({
            title: "Ошибка",
            description: "Ошибка при получении доступных камер.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  }, []);

    const handleScan = (data) => {
      
        if (data) {
            setQrCodeValue(data.text);
            sendQrCodeToServer(data.text); // Отправляем QR-код на сервер
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const toggleCamera = () => {
        setFacingMode((prevMode) =>
            prevMode === 'environment' ? 'user' : 'environment'
        );
    };


    const sendQrCodeToServer = async (qrCode) => {
        setLoading(true); // Устанавливаем состояние загрузки перед отправкой
    
        socket.emit('sendMessageSocket', qrCode, getmyinfo);
    
        socket.on('messageSent', (response) => {
            if (response.status === 'success') {
                console.log('Успешно:', response.msg);
                setQrCodeValue("")
            } else {
                console.error('Ошибка:', response.msg);
                toast({
                    title: "Oops",
                    description: response.msg,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                  })
                  setQrCodeValue("")
            }
            setLoading(false); // Сбрасываем состояние загрузки после получения ответа
        });
    };
    
      



    if (!isSupported) {
        return <p>Ваш браузер не поддерживает доступ к камере.</p>;
    }

    return (
        <div className="qr-scanner-container">
            <div className="qr-scanner-header">

                {loading && <p>Отправка данных на сервер...</p>} {/* Показываем загрузку */}
                <p>{qrCodeValue}</p>
            </div>
            <div className="qr-scanner-wrapper">
                <div className="qr-video-wrapper">
                    <QrScanner
                        delay={700}
                        constraints={{
                            video: { facingMode },
                        }}
                        onError={handleError}
                        onScan={handleScan}

                    />
                    <div className="qr-overlay"></div>
                </div>
                <button onClick={toggleCamera} className="camera-toggle-button">
                    Переключить камеру ({facingMode === 'environment' ? 'Задняя' : 'Фронтальная'})
                </button>
            </div>

        </div>
    );
};

export default QRScannerInner;
