import { Textarea,Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react';


export default function OzonTextArea(item) {
    const [value,setValue] = useState("")
    let atributeParams =  {
        complex_id: 0,
        id: item.item.id,
        values: [
          {
                value:"" 
          }
        ]
      }

      useEffect(()=>{
        if (item.item.currentinfo) {
            
             
            atributeParams.values[0].value = item.item.currentinfo.values[0].value
            setValue(atributeParams)
        }
     
      },[])

      const handleChange=(e)=>{
       
            atributeParams.values[0].value = e.target.value
            setValue(atributeParams)
           
      }
       
            if (item.item.currentinfo) {
                return (
                    <>
                    <label mb='8px'> {item.item.name}</label>
                    <input type="hidden" name="textAreaHidden"  defaultValue={JSON.stringify(value)}/>
                    <Textarea
                    name='textareaEditOzon'
                      defaultValue={item.item.currentinfo.values[0].value}
                      onChange={(e)=> handleChange(e)}
                      placeholder='Here is a sample placeholder'
                      size='sm'
                    />
                  </>
                )
            }else{
                return (
                    <>
                     <label mb='8px'> {item.item.name}</label>
                     <input type="hidden" name="textAreaHidden"  defaultValue={JSON.stringify(value)}/>
                    <Textarea
                     name='textareaEditOzon'
                      defaultValue={""}
                      onChange={(e)=> handleChange(e)}
                      placeholder='Вот пример заполнителя'
                      size='sm'
                    />
                  </>
                )
            }

           
                
}