import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import "./modalForEditTable.css"
import "./modalForEditTableDark.css"

import Spinner from 'react-bootstrap/Spinner';

import { useToast } from '@chakra-ui/react'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../../../redux/reducer/postFetcReducer/action/action";
import { Avatar } from '@chakra-ui/react'


export default function ModalFromEdit({ shows, currentDate, setShows }) {
  const getDarkModoe = useSelector(darkModes => darkModes)
  const [show, setShow] = useState(false);
  const [currentRol, setcurrentRol] = useState("");
  const [loading, setloading] = useState(false);
  const toast = useToast()
  const dipatch = useDispatch()
  const modalRef = useRef(null)

  const handleClose = () => {

    setShow(false);
    setShows(false);
  }



  //обновить информацию о сотруднике
  const handleUpdate = () => {
    if (currentRol) {
      setloading(true)
      axios.patch('/alldeploy/api', {
        currentDate,
        currentRol
      })
        .then(function (response) {
          console.log(response);
          setShow(false);
          setShows(false);
          dipatch(fetchUsers())
        })
        .catch(function (error) {
          console.log(error);
          setShow(true);
          setShows(true);
          toast({
            title: 'Ошибка',
            description: error.response.data.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top"
          })
        }).finally(fin => {
          setloading(false)
        })
    } else {
      toast({
        title: 'Выберите другую роль для изменения.',
        description: "Выберите другую роль для изменения.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top"
      })
    }

  };


  //для показа modal
  useEffect(() => {

    setShow(shows);
    if (shows == true) {
      modalRef.current.style.display = "flex";
    } else {
      modalRef.current.style.display = "none";
    }
    console.log(currentDate,"currentDate");
    
  }, [shows]);

  return (
    <div ref={modalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "EditemploysDark" : "EditemploysLight"}>
      <div className="EditemploysModal-content">
        <div>
          <div>
            <p>Редактировать профиль</p>
            <div onClick={handleClose}>

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M10.6712 12.5L7.27521 9.10406C6.90826 8.73711 6.90826 8.14216 7.27521 7.77521C7.64216 7.40826 8.23711 7.40826 8.60406 7.77521L12 11.1712L15.3959 7.77521C15.7629 7.40826 16.3578 7.40826 16.7248 7.77521C17.0917 8.14216 17.0917 8.73711 16.7248 9.10406L13.3288 12.5L16.7248 15.8959C17.0917 16.2629 17.0917 16.8578 16.7248 17.2248C16.3578 17.5917 15.7629 17.5917 15.3959 17.2248L12 13.8288L8.60406 17.2248C8.23711 17.5917 7.64216 17.5917 7.27521 17.2248C6.90826 16.8578 6.90826 16.2629 7.27521 15.8959L10.6712 12.5Z" fill="#64748B" />
              </svg>
            </div>
          </div>
          <p>Введите все доступные данные для редактирования профиля сотрудника</p>
        </div>
        <div>
          <div>
          <Avatar src={currentDate.img ? `/${currentDate.img}` : "https://bit.ly/broken-link"} />

            
            <label htmlFor="" className="editemploLabel">
              <p>Имя</p>
              <input type="text" defaultValue={currentDate.name} disabled placeholder="Номер телефона" />
            </label>
            <label htmlFor="" className="editemploLabel">
              <p>Почта</p>
              <input type="text" className="editemploLabel" placeholder="Электронная почта" defaultValue={currentDate.email} disabled />
            </label>
            <label htmlFor="" className="editemploLabel">
              <p>Номер телефона</p>
              <input type="text" placeholder="Имя" defaultValue={currentDate.tel} disabled />
            </label>
            <label htmlFor="" className="editemploLabel">
              <p>Должность</p>
              <select onChange={(e) => setcurrentRol(e.target.value)}>


                {["Менеджер маркетплейсов", "Контент менеджер", "SEO-специалист", "Дизайнер"].map((item, index) => {
                  if (item === currentDate.rols) {

                    return (
                      <option value={item} key={index} selected >{item}</option>
                    )
                  } else {
                    return (

                      <option value={item} key={index}>{item}</option>
                    )
                  }

                })}
              </select>
            </label>
          </div>
        </div>
        <div >
          {loading ?
            <Button style={{ background: "#5E7DE8", outline: "none", border: "none" }} disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Загрузка...
            </Button>
            :
            <button onClick={handleUpdate} className="saveEmployBTN">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                  <path d="M17.5762 1.98016C17.8414 1.66195 17.7984 1.18903 17.4802 0.923853C17.1619 0.65868 16.689 0.701673 16.4239 1.01988L11.0332 7.48869C9.95034 8.7881 9.18861 9.69936 8.52791 10.296C7.88262 10.8787 7.43728 11.0639 7.00002 11.0639C6.56276 11.0639 6.11742 10.8787 5.47213 10.296C4.81143 9.69935 4.04969 8.78809 2.96686 7.48869L1.57618 5.81988C1.31101 5.50167 0.838088 5.45868 0.519881 5.72385C0.201673 5.98903 0.15868 6.46195 0.423853 6.78016L1.85312 8.49528C2.88839 9.73764 3.71748 10.7326 4.46684 11.4092C5.24089 12.1082 6.03216 12.5639 7.00002 12.5639C7.96788 12.5639 8.75914 12.1082 9.5332 11.4092C10.2826 10.7326 11.1116 9.73766 12.1469 8.49532L17.5762 1.98016Z" fill="#FAFAFA" />
                </svg>
              </div>
              Сохранять
            </button>
          }

          <button className="closeEmployBTN" onClick={handleClose}>
          Отменить
          </button>
        </div>
      </div>
    </div>
  );
}
