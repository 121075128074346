import { useDispatch, useSelector } from "react-redux";
import "./replaceSelectorForChatBodyLight.css";
import "./replaceSelectorForChatBodyDark.css";
import { enabledAll } from "../../../../redux/reducer/otherMenuOptionsForMessageItems/action/action";
import { enabledAll_trade } from "../../../../redux/reducer/trade/action/action";

export default function ReplaceSelectorForChatBodyTrade() {
  const getDarkModoe = useSelector((darkModes) => darkModes);
  const otherMenuOptionsForMessageItemsReducer = useSelector( (otherMenuOptionsForMessageItemsReducer) => otherMenuOptionsForMessageItemsReducer);
   
     

  const dispatch = useDispatch();
console.log(otherMenuOptionsForMessageItemsReducer,"otherMenuOptionsForMessageItemsReducer");
  return (
    <div
      className={
        getDarkModoe.ToggleDarkModeReducer.chekced
          ? "replaceContDark"
          : "replaceContLight"
      }
    >
      <div>
        <span></span>
        <div>
          <p>
            {otherMenuOptionsForMessageItemsReducer
              .TradeReducer.data?.senderInfo.name
              ? otherMenuOptionsForMessageItemsReducer
                  .TradeReducer.data?.senderInfo.name
              : "ответ на сообщение"}
          </p>
          <p>
            {
              otherMenuOptionsForMessageItemsReducer
                .TradeReducer.data?.messageText
            }
          </p>
        </div>
      </div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          onClick={() => dispatch(enabledAll_trade())}
        >
          <path
            d="M1.16406 1.16699L12.8307 12.8337M1.16408 12.8337L6.99742 7.00033L12.8308 1.16699"
            stroke="#393939"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}
