import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import WildberriesModal from "../wildberriesModal/wildberriesModal";
import Badge from "react-bootstrap/Badge";

import "./navBarWb.css";
import "./navBarWbDark.css";
import WbFilter from "../filter/filter";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWaldberisListAction } from "../../../../redux/reducer/getwildberrieslIST/action/action";
import axios from "axios";


export default function NavBarWB() {
  const dispatch = useDispatch();
    const [prdvalue,setprdvalue] = useState("")
    const getDarkModoe = useSelector(darkModes => darkModes )
    const findByInputAndFilter =(e) =>{
      e.preventDefault()
       
      const data =  {
        searchs:e.target.searchs.value,
        objectname:e.target.objectname.checked,
        sellerarcticul:e.target.sellerarcticul.checked,
        arctioculwb:e.target.arctioculwb.checked,
        barcode:e.target.barcode.checked,

      }
 
      dispatch(getWaldberisListAction(`/wildberis/searchByinput/api?params=${JSON.stringify(data)}`))
    } 

    useEffect(()=>{
          if (prdvalue === "") {
            dispatch(getWaldberisListAction("/wildberis/getprodcutlist/api"))
          }
    },[prdvalue])

  return (
      
    <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"navBarWbDark":"navBarWb"}>
   
      <Navbar bg="light" expand="sm">
        <Container fluid>
          <form onSubmit={findByInputAndFilter}>
            <WbFilter  setprdvalue={setprdvalue}/>
          
          <div >
            
          <div className="d-flex" >
              <Form.Control 
                type="search"
                placeholder="Поиск"
                className="me-2"
                aria-label="Поиск"
                name="searchs"
                onChange={(e) => setprdvalue(e.target.value)}
              />
              <button variant="outline-success" type="submit" className="SearchOzonFilter" >Поиск</button>
              
            </div>
          </div>
          </form>
          <div>
            <WildberriesModal />
          </div>
        </Container>
      </Navbar>
     
    </div>
    
  );
}


