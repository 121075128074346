const initialState = {
    workres: [],
    loading: false,
    error: null
  };
  
  const userReducerGetWorkersForChat = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_WORKRES_FORCHAT_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'GET_WORKRES_FORCHAT_SUCCESS':
    
        return {
          ...state,
          loading: false,
          workres: action.payload
        };
        
      case 'GET_WORKRES_FORCHAT_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default userReducerGetWorkersForChat;
  