import ChatHeader from "../chatHeader/chatheader"
import WebRtcChat from "../webRtcChat/webRtcChat"
import "./privateChatLight.css"
import "./privateChatDark.css"
import PinZakrep from "../pinZakrep/pinZakrep"
import ChatBox from "../chatBox/chatBox"
import ChatBody from "../chatBody/chatBody"
import ReplaceSelectorForChatBody from "../replaceSelectorForChatBody/replaceSelectorForChatBody"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"



export default function PrivateChat(params) {
    const otherMenuOptionsForMessageItemsReducer = useSelector((otherMenuOptionsForMessageItemsReducer) => otherMenuOptionsForMessageItemsReducer.otherMenuOptionsForMessageItemsReducer
    );
    const getMessageItems = useSelector(messageItem => messageItem);
    const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
    const getMyInfo = useSelector(getMyInfo => getMyInfo.getMayInfoReducer);

    const [searchParams] = useSearchParams();
    const chatID = searchParams.get('chatID');


    return (
        <div className="privateChatLightCont">

            {
                chatID && <ChatHeader backToUrl={"/chat/private"} />
            }

            {
                getChatInfo.data && getChatInfo.data.chatOptions && getChatInfo.data.chatOptions.pinMessageID && getChatInfo.data.chatOptions.pinMessageID.length > 0 && chatID &&
                <PinZakrep />
            }

            {
                getMessageItems.userReducerMessageItem.data && <ChatBody />
            }
            {
                chatID && <ChatBox isTrade={false} />
            }


        </div>)
}