import axios from "axios"
import { useState,useRef,useEffect, lazy, Suspense} from "react";
import "./uploadFile.css"
import { useSelector } from "react-redux";
import zipIcon from "./iconUpload/zipIcon.png"
import pdf from "./iconUpload/pdf.png"
import exel from "./iconUpload/exel.png"
import world from "./iconUpload/world.png"
import UploadFileEditList from "./downloadBtnFile/downloadBtnFile";
import otherFilre from "./iconUpload/otherFilre.png"


export default function UploadFileTaskMenegerChild({ setfile, file, handleChangeFile, index, allaray }) {

  const getDarkMode = useSelector(darkModes => darkModes);
  const modalRefFile = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);



  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setfile((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleDeleteLocal = (fileIndex,event) => {
    event.stopPropagation()

    setfile(prevArray => {
      // Фильтрация массива, оставляем только те элементы, которые не совпадают с itemToDelete по lastModified
      const newArray = prevArray.filter(item => item.lastModified !== fileIndex.lastModified);
      return newArray; // Обновляем состояние с отфильтрованным массивом
    });

  }
  const closeModal = () => {

    modalRefFile.current.style.display = "none"
  }

  useEffect(() => {
    modalRefFile.current.style.display = "none";
  }, [])

  const openModalForShow = (index) => {
    setCurrentSlide(index)
    modalRefFile.current.style.display = "flex"
  }


  
  const handlePrevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? file.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentSlide((prev) => (prev === file.length - 1 ? 0 : prev + 1));
  };
  const downloadFileForLocal = (item, e) => {
    e.stopPropagation()
    const fileURL = URL.createObjectURL(item);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.name || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);

  };

  return (
    <div className={getDarkMode.ToggleDarkModeReducer.chekced ? "uploadFileDark" : "uploadFileLight"}>
      <div>
        <div>
          <label htmlFor={`uploadChildLabel${index}`}>
            <p>Добавить файлы</p>
            <input type="file" id={`uploadChildLabel${index}`} style={{ display: "none" }} onChange={handleFileChange} multiple />
          </label>
        </div>
        <div>
        <Suspense fallback={<div>Загрузка...</div>}>
            {
              file.map((item, index) => {
                return (
                  <div key={index} onClick={(e) => openModalForShow(index, e)}>
                    {
                      (() => {

                        if (item.type === "image/png" || item.type === "image/jpeg") {
                          return <img
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        } else if (item.type === "video/mp4") {
                          return <video
                            src={URL.createObjectURL(item)}
                            alt="Uploaded"
                            loading="lazy"


                          />
                        } else if (item.mimetype === "image/png" || item.mimetype === "image/jpeg") {
                          return <img
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        } else if (item.mimetype === "video/mp4") {
                          return <video
                            src={`/${item.filename}`}
                            alt="Uploaded"
                            loading="lazy"

                          />
                        }
                        else {
                          return <img src={otherFilre} alt="" loading="lazy"/>
                        }
                      })()
                    }
                    <UploadFileEditList item={item}  handleDeleteLocal={handleDeleteLocal} handleDownload={downloadFileForLocal} handleDeleteFile={downloadFileForLocal} index={index} />
                  </div>
                )
              })
            }
          </Suspense>

          {/* {
            file.map((item, index) => {

              return (
                <div key={index} >
                  <div >
                    <div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                        </svg>
                        <p>{item.name}</p>
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteLocal(index)}>
                          <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                      </div>
                    </div>
                    {item && (item.type === "image/png" || item.type === "image/jpeg") && (
                      <img src={URL.createObjectURL(item)} alt="Uploaded" onClick={()=>openModalForShow(index)}/>
                    )}
                    {item && (file.type === "video/mp4") && (
                      <video src={URL.createObjectURL(item)} alt="Uploaded" onClick={()=>openModalForShow(index)}/>
                    )}

                  </div>
                </div>
              )
            })
          } */}

        </div>
      </div>
      <div>
        <div>
          <div className="myModalSliderUpload" ref={modalRefFile}>
            <div className="modal-contentSliderUpload">
              <div className="modal-header">
                <p>{file[currentSlide]?.name}</p>
                <div>
                
              
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" onClick={closeModal}>
                    <path d="M1.5 1L15.5 15M1.50003 15L8.50003 8L15.5 1" stroke="#767676" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </div>
              </div>
              <div className="modal-body">
                <div className="slider">
                  {/* Объединение файлов из базы данных и локальных файлов */}

                  {file[currentSlide] && (file[currentSlide]?.type === "image/png" || file[currentSlide]?.type === "image/jpeg") && (
                      <img src={URL.createObjectURL(file[currentSlide])} alt="Uploaded" />
                    )}
                    {file[currentSlide] && (file?.type === "video/mp4") && (
                      <video src={URL.createObjectURL(file[currentSlide])} alt="Uploaded" />
                    )}
               

               <button className="prev" onClick={handlePrevSlide}>&#10094;</button>
               <button className="next" onClick={handleNextSlide}>&#10095;</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
