import axios from 'axios';

export const getMyCompany = (url, searchCompanyVal) => {
  return (dispatch) => {
    dispatch({ type: 'FETCH_COPMANY_REQUEST' });

    // Добавляем параметр поиска в запрос
    axios.get(url, {
      params: {
        search: searchCompanyVal
      }
    })
    .then(response => {
      dispatch({
        type: 'FETCH_COPMANY_SUCCESS',
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: 'FETCH_COPMANY_FAILURE',
        payload: error.message
      });
    });
  };
};
