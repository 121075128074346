import { Pagination } from '@nextui-org/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';



import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement
} from '@chakra-ui/react';
import { SearchIcon, PhoneIcon } from '@chakra-ui/icons'
import "./allCompany.css";
import { getCaompanyAllAction } from '../../../../redux/reducer/getAllCompanyForAdmin/action/action';

import { useEffect, useRef, useState } from 'react';
import { getadminInfoAction } from '../../../../redux/reducer/getAdminInfo/action/action';
import ModalEdit from './modalForEdit/modaleditCompany';
import Spinner from 'react-bootstrap/Spinner';
import DeleteCompanyByAdmin from './deleteCompany/deleteCompany';
import MdalEditWorkers from './modalEditWorkers/modalEditWorkers';

export default function AllCompany() {
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [seatchVal, setseatchVal] = useState("");
    const now = moment();

    const dispatch = useDispatch();
    const getAllCompany = useSelector(state => state.getAllCompanyForAdminReducer); // Исправил доступ к reducer
    const selectForm = useRef(null);

    useEffect(() => {
        dispatch(getadminInfoAction("/getadmininfo/admin/api"));
    }, [dispatch]);

    useEffect(() => {
        const apiUrl = seatchVal === ""
            ? `/getAllCompant/admin/api?page=${currentPage}`
            : `/searchbyinputcompany/api?search=${seatchVal}&page=${currentPage}`;
        dispatch(getCaompanyAllAction(apiUrl));
    }, [currentPage, seatchVal, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setCurrentPage(1);
        setseatchVal(e.target.value);
    };

    const setDataForEdit = (item) => {
        setData(item);
        setShow(true);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const { allCompany, loading } = getAllCompany;
    console.log(allCompany);
    
    return (
        <>
            <h1 className="titleH1">Поиск компании</h1>
            <form className="searchContFormCompanyAdmin" onChange={handleSubmit} ref={selectForm}>
                <InputGroup width={"20%"}>
                    <InputRightElement pointerEvents='none'>
                        <SearchIcon color='gray.300' />
                    </InputRightElement>
                    <Input type='tel' placeholder='поиск компании' />
                </InputGroup>
            </form>
            <div className="allCompany">
                {loading ? (
                    <div className="loading">
                        <Spinner animation="border" size="sm" />
                    </div>
                ) : (
                    allCompany?.data && Array.isArray(allCompany.data) && allCompany.data.length > 0 ? (
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>ИД КОМПАНИИ</Th>
                                        <Th>Название компании</Th>

                                        <Th>ИНН</Th>
                                        <Th>Сотрудники</Th>
                                        <Th >дата создания компании</Th>
                                        <Th isNumeric>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {allCompany.data.map((item, index) => (
                                      
                                            
                                        <Tr key={index}>
                                            <Td onClick={() => setDataForEdit(item)}>{item?._id}</Td>
                                            <Td onClick={() => setDataForEdit(item)}><ModalEdit shows={show} setShows={setShow} data={item} currentPage={currentPage} /></Td>
                                            <Td onClick={() => setDataForEdit(item)}>{item?.inn}</Td>
                                            <Td onClick={() => setDataForEdit(item)}><MdalEditWorkers data={item} /></Td>
                                            <Td onClick={() => setDataForEdit(item)}> {moment(item?.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Td>
                                            <Td isNumeric><DeleteCompanyByAdmin item={item} /></Td>

                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <h1>нет компании</h1>
                    )
                )}
                {allCompany?.totalPages && (
                    <div className="paginationCompany">
                        <Pagination total={allCompany.totalPages} initialPage={allCompany.currentPage} onChange={handlePageChange} />
                    </div>
                )}
            </div>
        </>
    );
}

