const initialStateTogglreMenu = {
    toggleMenu: true,
  };
  
  const ToggleMenuReducer = (state = initialStateTogglreMenu, action) => {
    
    if (action.type == "SET_TOGGLE_MENU_USER") {
      return {
        ...state,
    
        toggleMenu: action.value, // Use action.payload instead of action.value
      };
    }
  
    return state;
  };
  
  export default ToggleMenuReducer;
  