import { Switch,FormControl,FormLabel} from "@chakra-ui/react";
import { useState,useEffect } from "react";

export default function BlockAdmin({data}) {
  useEffect(()=>{
    
  },[])
  const handleBlock=(e)=>{
      // console.log(e.target.checked)
  }
  return (
    <>
      <FormControl style={{marginBottom:"10px",marginTop:"10px"}}>
        <FormLabel htmlFor="email-alerts" mb="0">
        Заблокировать  администратора
        </FormLabel>
        <Switch id="email-alerts" name="blockAdmin" defaultChecked={data.blockAdmin} onChange={handleBlock} key={data.blockAdmin + '123'}/>
      </FormControl>
    </>
  );
}
