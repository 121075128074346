import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router";
import { Radio, RadioGroup ,Stack} from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import JsBarcode from 'jsbarcode';
import React from "react";
import "./productinfo.css"
import { useDispatch, useSelector } from "react-redux";
export default function Productinfo(x) {
        
    const barcodeRef = React.useRef(null);

    const [value, setValue] =useState("0.1")
    const [random, setRandom] =useState(null)
    const getDarkModoe = useSelector(darkModes => darkModes )
  
    useEffect(()=>{
            let randstrix = Math.floor(Math.random() * 1213213213123123)
            setRandom(randstrix)

            if (barcodeRef.current) {
                JsBarcode(barcodeRef.current, randstrix, {
                  format: 'CODE128',
                  width: 1.5,
                  height: 50,
                  displayValue: true,
                });
              }
    },[])


    const creatRandom = ()=>{
        let randstrix = Math.floor(Math.random() * 1213213213123123)
        setRandom(randstrix)
        if (barcodeRef.current) {
            JsBarcode(barcodeRef.current, randstrix, {
              format: 'CODE128',
              width: 1.5,
              height: 50,
              displayValue: true,
            });
          }
    }


       return(
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"ProdcutInfoDark":"ProdcutInfo"}>
                <div>
                    <label htmlFor="">название</label>
                     <input placeholder='название' name="prodname" required />
                </div>
                <div className="strixCodeContainer">
                        
                   
                    <div className="strixCodeContainerChild">
                            <div>
                                   <label htmlFor=""  >Штрих-код</label>
                                  <svg ref={barcodeRef} />
                            </div>
                       
                    <div className="strixCodeRender">
                                  <input placeholder='Штрих-код' className="strixCode" name="strixkode" required defaultValue={random} />
                                  <div className="generateStrixCode" onClick={creatRandom}>Сгенерировать</div>
                        </div>
                    </div>
                     
                    
                </div>
                        <div className="arcticuleCont">
                            <label htmlFor="">Артикул</label>
                            <input placeholder='Артикул' name="arcticul" required/>
                        </div>
                        <div>
                        <div className="priceOzon">
                                <div  className="PiceInputContParent">
                                    <div className="PiceInputCont">
                                        <label htmlFor="">Цена в RUB </label>
                                            <input placeholder='Цена в RUB ' name="pirce" required/>
                                    </div>
                                    <div className="PiceInputCont">
                                        <label htmlFor="">Цена до скидки </label>
                                            <input placeholder='Цена до скидки ' name="skidprice" />
                                        
                                    </div>
                                </div>
                                <div className="radibtnOzon">
                              
                        <RadioGroup onChange={setValue} name="nds" value={value} aria-required>
                        
                        <label htmlFor="">Цена до скидки </label>
                                <Stack direction='row'>
                                
                                    <Radio value='0.1' >10%</Radio>
                                    <Radio value='0.2'>20%</Radio>
                                    <Radio value='0'>Не облагается</Radio>
                                </Stack>
                        </RadioGroup>
             
                       
                                </div>
                         </div>
             
                    </div>
              
                <div>
                    <h1>Габариты и вес в упаковке</h1>
                        <div>
                            <label htmlFor="">Вес </label>
                            <input placeholder='Вес' type="number" name="wigthprod" required/>
                        </div>
                        <div>
                            <label htmlFor="">Длина </label>
                            <input placeholder='Длина' type="number" name="dlinaprod" required/>
                        </div>
                        <div>
                            <label htmlFor="">Ширина </label>
                            <input placeholder='Ширина упаковки, мм  ' type="number" name="shirinaprod" required/>
                        </div>
                        <div>
                            <label htmlFor="">Высота </label>
                            <input placeholder='Высота' type="number" name="visota" required/>
                        </div>
                </div>
        </div>
       )
}