import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useEffect, useState, useTransition } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../../../redux/reducer/postFetcReducer/action/action';
import axios from 'axios';


export default function PerfromerFilter({ perfromers, setPerfromers }) {
    const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
    const dispatch = useDispatch();
    const getAllCompany = useSelector(company => company.userReducerCompany.users);
    const [isPending, startTransition] = useTransition();
    let adminIfno = useSelector(adminIfno => adminIfno);
    const myProject = useSelector((state) => state.optionReducer.myProject);
    const [workers,setWorkers]=useState([]);

    const handleSetPerformer = (e) => {
        setPerfromers((perfromers) => {
            // Проверяем, есть ли элемент с таким workerID
            const exists = perfromers.some((performer) => performer.workerID === e.workerID);

            if (exists) {
                // Если есть, удаляем его из массива
                return perfromers.filter((performer) => performer.workerID !== e.workerID);
            } else {
                // Если нет, добавляем его в массив
                return [...perfromers, e];
            }
        });
    };

    const searchPerfomer = (e) => {
            if (myProject) {
                        startTransition(() => {
            axios.get('/getPerformerForFilter/api', {
                params: {
                    myProject,
                    searchValue:e
                }
              })
              .then(function (response) {
                setWorkers(response.data.data)
              })
              .catch(function (error) {
                console.log(error);
              })
              .finally(function () {
                // выполняется всегда
              });  
        });
            }else{
                startTransition(() => {
                    dispatch(fetchUsers(e))
                });
            }
   

    }




  

    useEffect(() => {
        setWorkers([])
        if (myProject) {
            axios.get('/getPerformerForFilter/api', {
                params: {
                    myProject
                }
              })
              .then(function (response) {
                setWorkers(response.data.data)
              })
              .catch(function (error) {
                console.log(error);
              })
              .finally(function () {
                // выполняется всегда
              });  
        }else{
            setWorkers(gerChekcedCompany.rootReducer.users.data)
        }
       

    }, [perfromers,gerChekcedCompany,myProject,getAllCompany])
    return (
        <div className='filterPerformer'>
            <div>
                <p>Исполнитель</p>
                <p onClick={() => setPerfromers([])}>Очистить</p>
            </div>
            <section >
                <label htmlFor="searchPerformerFilter">
                    <input type="text" name="" id="searchPerformerFilter" placeholder="Поиск" onChange={(e) => searchPerfomer(e.target.value)} />
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                            <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                        </svg>
                    </div>
                </label>
                <div>
                    <div>
                        <div>
                            <div onClick={() => handleSetPerformer(adminIfno.rootReducer.users.adminInfo)}>
                                <div>
                                    {

                                        perfromers.some((performer) => performer.workerID === adminIfno.rootReducer.users.adminInfo.workerID) ?
                                            <div className='isChecked'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                                    <path d="M1.94439 3.97288C1.6515 3.67999 1.17663 3.67999 0.883732 3.97288C0.590839 4.26577 0.590839 4.74065 0.883732 5.03354L3.60608 7.75589C3.89898 8.04879 4.37385 8.04879 4.66675 7.75589L11.1111 1.31158C11.404 1.01869 11.404 0.543813 11.1111 0.25092C10.8182 -0.0419733 10.3433 -0.0419733 10.0504 0.25092L4.13641 6.1649L1.94439 3.97288Z" fill="white" />
                                                </svg>
                                            </div>
                                            :
                                            <div>

                                            </div>
                                    }

                                    <Avatar name='Dan Abrahmov' size={"xs"} src={`./${adminIfno.rootReducer.users.adminInfo.img}`} loading='lazy' />
                                    <p className='namePerfFilter'>{adminIfno.rootReducer.users.adminInfo.name}</p>
                                    <p className='emailPerfFilter'>{adminIfno.rootReducer.users.adminInfo.email}</p>
                                    <img src="" alt="" />
                                </div>
                            </div>

                            {/* performer======================================================= */}
                            {
                                workers?.map((item, index) => {
                                    const isSelected = perfromers.some((performer) => performer.workerID === item.workerID);
                                    return (
                                        <div key={index} onClick={() => handleSetPerformer(item)}>
                                            <div>
                                                {
                                                    isSelected ?
                                                        <div className='isChecked'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                                                <path d="M1.94439 3.97288C1.6515 3.67999 1.17663 3.67999 0.883732 3.97288C0.590839 4.26577 0.590839 4.74065 0.883732 5.03354L3.60608 7.75589C3.89898 8.04879 4.37385 8.04879 4.66675 7.75589L11.1111 1.31158C11.404 1.01869 11.404 0.543813 11.1111 0.25092C10.8182 -0.0419733 10.3433 -0.0419733 10.0504 0.25092L4.13641 6.1649L1.94439 3.97288Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                                }

                                                <Avatar name='Dan Abrahmov' size={"xs"} src={`./${item.img}`} loading='lazy' />
                                                <p className='namePerfFilter'>{item.name}</p>
                                                <p className='emailPerfFilter'>{item.email}</p>
                                                <img src="" alt="" />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}