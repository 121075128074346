import { useNavigate } from "react-router-dom";
import "./adminLogin.css"
import {
  PinInput,
  PinInputField,
  HStack,
  Text,
  Stack,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Flex, Spacer, VStack, Container,
  FormControl,
  useColorModeValue,

} from "@chakra-ui/react";
import { PhoneIcon, AddIcon, WarningIcon,EmailIcon } from '@chakra-ui/icons'
import { Center, Heading } from '@chakra-ui/react'




import { useEffect, useRef, useState } from "react";
import axios, { Axios } from "axios"
import { useToast } from '@chakra-ui/react'

import logo from "../../../img/LogoU.png"
import { CheckedPhoneNumber } from "../../../hook/checkedCountryNumber/checkedCountryNumber";


export default function AdminLogin(params) {

  const [isSending, setIsSending] = useState(false);
  const [inptValue, setinptValue] = useState("");
  const [loading, setloading] = useState(false);
  const [inpDisapled, setInpDisapled] = useState(false);
  const [myInfo,setmyInfo] = useState(null);

  const [pohoneNumber, setPohoneNumber] = useState("");
  const navigate = useNavigate()
  const toast = useToast({ position: 'top' })
  const focus = useRef(null)




  useEffect(() => {

    if (isSending) {
      setIsSending(true)
      setInpDisapled(true)
      setloading(true)
      setinptValue("")

      axios.post('/checkPinCode/admin/api', {
        pinCode: inptValue
      })
        .then(function (response) {
          console.log(response);
          setIsSending(false)
          setInpDisapled(false)
          setloading(false)
          if (response.data.authorization) {
            navigate("/adminPage")
          }
        })
        .catch(function (error) {
          console.log(error);
          setIsSending(false)
          setInpDisapled(false)
          setloading(false)
          toast({
            title: "Ошибка",
            description: error.response.data.message,
            status: "error",
            duration: 2000,
            isClosable: true,

          })
        });
    }
  }, [isSending])




  const handelPincode = (e) => {
    setinptValue(e)
    //без проверяется если пин-код длина равна 4 тогда отправляется сервер
    if (e.length === 5) {
      console.log("send server");
      setIsSending(true)
      setInpDisapled(true)

    }

  }


  //Вот как мы отправляем номер телефона на сервер, чтобы позвонить нам
  const handleCall = () => {

    if (pohoneNumber.length > 0) {
      setloading(true)
      setInpDisapled(true)
      axios.post('/calladmin/tel/api', {
        tel: pohoneNumber
      })
        .then(function (response) {
            console.log(response,"responseresponse");
            
          setmyInfo(response.data.data)
          setloading(false)
          setInpDisapled(false)
          toast({
            title: "проверьте электронную почту",
            description:  `код отправлен на электронную почту ${myInfo?.email}.`,
            status: 'success',
            duration: 2000,
            isClosable: true,
          })

        })
        .catch(function (error) {

          console.log(error);
          setloading(false)
          setInpDisapled(false)
          toast({
            title: "Ошибка",
            description: error.response.data.message,
           status: "error",
            duration: 9000,
            isClosable: true,
          })

        });
      console.log("called");
    } else {
      toast({
        title: "ошибка",
        description: "вы не набрали свой номер телефона.",
        status: "error",
        duration: 2000,
        isClosable: true,
      })
    }
    console.log(myInfo,"myInfomyInfo");
    
  }
  return (
    <>
      {/* <div className="aouthContAdmin">
      <div>
        <img src={logo} alt="" />
      </div>
      <h1 >

        УПРОСТИ

      </h1>
      <h2 > Администрация</h2>
 
      <div className="inputCont">
        <p style={{ color: "#5E7DE8", marginBottom: "10%", justifyContent: "c" }} className="enterPhone">Введите ваш номер телефона</p>
        <InputGroup className={"phoneINP"}>
          <InputLeftAddon children={"+"} color={"#5E7DE8"} />
          <Input
            type="number"
            placeholder="79000000000"
            value={pohoneNumber}
            borderColor={"#5E7DE8"}
            onChange={(e) => setPohoneNumber(e.target.value)}

          />
        </InputGroup>
        <p className="wrap" >
          После нажатия на кнопку вам поступит звонок. Отвечать на звонок не нужно

        </p>
      </div>
      <div>
        {loading ?
          <Button
            isLoading
            colorScheme='#5E7DE8'
            backgroundColor={"#5E7DE8"}
            width={"270px"}
            className={"pinBTN"}
          >
            подождите
          </Button>
          :
          <Button onClick={handleCall} colorScheme="blue" background={"#5E7DE8"} width={"270px"} isDisabled={inpDisapled} className={"pinBTN"} rightIcon={<PhoneIcon />}>получить звонок</Button>
        }
      </div>

      <div>

        <PinInput type="alphanumeric" color="#5E7DE8" colorScheme='#5E7DE8' focusBorderColor={"#5E7DE8"} value={inptValue} onChange={(e) => [handelPincode(e)]} isDisabled={inpDisapled} variant="flushed" isRequired={true}>
          <PinInputField width={"60px"} ref={focus} autoFocus={true} type="number" />
          <PinInputField width={"60px"} type="number" />
          <PinInputField width={"60px"} type="number" />
          <PinInputField width={"60px"} type="number" />
        </PinInput>

      </div>
      <div>

        <Text fontSize='1xl' color={"#5E7DE8"} >Введите последние 4 цифры номера </Text>


      </div>


    </div> */}


      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}>
        <Stack
          spacing={4}
          w={'full'}
          maxW={'sm'}
          bg={useColorModeValue('white', 'gray.700')}
          rounded={'xl'}
          boxShadow={'lg'}
          p={6}
          my={10}>
          <Center>
            <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
              УПРОСТИ
            </Heading>
          </Center>
          <Center
            fontSize={{ base: 'sm', sm: 'md' }}
            color={useColorModeValue('gray.800', 'gray.400')}>
            Администрация
          </Center>
          <Center
            fontSize={{ base: 'sm', sm: 'md' }}
            fontWeight="bold"
            color={useColorModeValue('gray.800', 'gray.400')}>
              {
                (()=>{
                  if (myInfo?.email) {
                    return <p>{myInfo?.email}</p>
                  }else {
                    return <p>{myInfo?.tel}</p>
                  }
                })()
              }
           
          </Center>
          <InputGroup className={"phoneINP"}>
            <InputLeftAddon children={"+"} color={"#5E7DE8"} />
            <Input
              type="number"
              placeholder="79000000000"
              value={pohoneNumber}
              borderColor={"#5E7DE8"}
              onChange={(e) => setPohoneNumber(e.target.value)}

            />
          </InputGroup>
          <Stack spacing={6}>
            <Button onClick={handleCall} color={'white'} bg={'blue.400'} isDisabled={inpDisapled} className={"pinBTN"} rightIcon={<EmailIcon />}>Сгенерировать код</Button>
          </Stack>
          <FormControl>
            <Center>
              <HStack>
                <PinInput mask type="alphanumeric" color="#5E7DE8" colorScheme='#5E7DE8'  focusBorderColor={"#5E7DE8"} value={inptValue} onChange={(e) => [handelPincode(e)]} isDisabled={inpDisapled} variant="flushed" isRequired={true}>
                  <PinInputField ref={focus} autoFocus={true} type="number" />
                  <PinInputField type="number" />
                  <PinInputField type="number" />
                  <PinInputField type="number" />
                  <PinInputField type="number" />
                 
                </PinInput>
              </HStack>
            </Center>
          </FormControl>

        </Stack>
      </Flex>
    </>
  )
}