import {
  Menu,
  MenuButton,
  MenuList,
  Radio, RadioGroup,Input,Button

} from "@chakra-ui/react";
import "./filter.css"
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import React, { useEffect, useRef, useState,useTransition } from "react";
import { useDispatch } from "react-redux";
import { getWaldberisListAction } from "../../../../redux/reducer/getwildberrieslIST/action/action";
import axios from "axios";



export default function WbFilter({prdvalue}) {
    const [min,setMin]=useState(0)
    const [max,setMax]=useState(200)
    const [value, setValue] = React.useState('objectname')
  const dispatch = useDispatch();

 

const sendServerPrice=(e)=>{
  e.preventDefault()
   dispatch(getWaldberisListAction(`/wildberis/searchPrice/api?min=${min}&max=${max}`))
}




  return (
    <div className="WBfilterCont" style={{zIndex:"7"}}>
      
      <Menu>
      <div className="filter">
            <MenuButton type="button">
              <span style={{ marginRight: "10px" }}>Фильтр</span>
              <span className="material-symbols-outlined">filter_list </span>
            </MenuButton>
          </div>
        <MenuList style={{zIndex:"7"}} >
              
           
             <RadioGroup onChange={setValue} value={value} >

                  <Radio  name="objectname" value={"objectname"}  defaultChecked>Категория</Radio>
                  <Radio name="sellerarcticul" value={"sellerarcticul"} >Артикул продавца</Radio>
                  <Radio name="arctioculwb" value={"arctioculwb"} >Артикул WB</Radio>
                  <Radio name="barcode" value={"barcode"} >Баркод товара</Radio>
               
          </RadioGroup>
           
            <div className="wbRangeSlider">
                <input placeholder='Минимум' type="number" value={min} onChange={(e)=> setMin(e.target.value)}/>
                <input placeholder='Максимум' type="number" value={max} onChange={(e)=> setMax(e.target.value)}/>
            </div>
            <div style={{display:"flex"}} className="filterBTn">
            <button className="searchbtnozonPrice" onClick={sendServerPrice}>Поиск</button>
            </div>
          
    

        </MenuList>
      </Menu>
    </div>
  );
}
