// actions.js
// // для того чтобы открыть остальные функционал для редактирования сообщений и ответа сообщений
export const IS_EDIT_MESSAGE_TRADE = 'IS_EDIT_MESSAGE_TRADE';
export const REPLY_TO_MESSAGE_TRADE = 'REPLY_TO_MESSAGE_TRADE';
export const UPDATE_DATA_TRADE = 'UPDATE_DATA_TRADE';
export const ENABLED_ALL_TRADE = 'ENABLED_ALL_TRADE';

export const editMessage_trade = (value) => ({
  type: IS_EDIT_MESSAGE_TRADE,
  value: value
});

export const replyToMessage_trade = (value) => ({
  type: REPLY_TO_MESSAGE_TRADE,
  value: value
});

export const updateData_trade = (value) => ({
    type: UPDATE_DATA_TRADE,
    value: value
});

export const enabledAll_trade = (value) => ({
    type: ENABLED_ALL_TRADE,
    value: value
});
