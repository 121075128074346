import { useEffect, useRef, useState } from "react"
import "./dopDownEditTextLight.css"
import "./dopDownEditTextDark.css"
import { useSelector } from "react-redux";

export default function DopDownEditText({editText,editTextLink,certainText}) {
    const openDropDown = useRef();
    const [isOpen, setisOpen] = useState(false);
    const blockRef = useRef();
    const getDarkModoe = useSelector(darkModes => darkModes);
    useEffect(() => {
        if (isOpen) {
            openDropDown.current.style.display = 'block'
          

        } else {
            openDropDown.current.style.display = 'none'

        }
    }, [isOpen])
    function openDropDownFunc(params) {
        setisOpen(!isOpen)
        certainText()
    }
    const closeDropdown = (event) => {

        if (!event.target.matches('.dropdownEditTextLight') && isOpen && !event.target.matches('.dropdownEditTextSvg') || event.type === 'beforeunload') {
            setisOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', closeDropdown);

        return () => {
            window.removeEventListener('click', closeDropdown);
        };
    }, []);


    return (
        <div ref={blockRef} onClick={openDropDownFunc} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownEditTextDark" : "dropdownEditTextLight"}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="none" className="dropdownEditTextSvg" onClick={openDropDownFunc} >
                <path onClick={openDropDownFunc} d="M10 11L5.5 1L1 11" stroke="#858C99" strokeWidth="1.21205" strokeLinecap="round" strokeLinejoin="round" />
                <path onClick={openDropDownFunc} d="M8 8L3 8" stroke="#858C99" strokeWidth="1.21205" strokeLinecap="round" strokeLinejoin="round" />
                <path onClick={openDropDownFunc} d="M14.5 11C15.8807 11 17 10.1046 17 9C17 7.89543 15.8807 7 14.5 7C13.1193 7 12 7.89543 12 9C12 10.1046 13.1193 11 14.5 11Z" stroke="#858C99" strokeWidth="1.21205" strokeLinecap="round" strokeLinejoin="round" />
                <path onClick={openDropDownFunc} d="M13 4.47929C13.4532 4.16363 13.9899 3.99647 14.5385 4.00006C15.9 4.00006 17 4.98209 17 6.19981V11" stroke="#858C99" strokeWidth="1.21205" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="dropdownEditTex-content" ref={openDropDown}>
                <div className="editTextType" onClick={()=>editText("**")}>
                    <p>Жирный</p>
                    <div>
                        <p>Ctrl</p>
                        <p>B</p>
                    </div>
                </div>
                <div className="editTextType" onClick={()=>editText("*")}>
                    <p>Курсив</p>
                    <div>
                        <p>Ctrl</p>
                        <p>I</p>
                    </div>
                </div>
                <div className="editTextType" onClick={()=>editText("~~")}>
                    <p>Зачеркнутый</p>
                    <div>
                        <p>Shift</p>
                        <p>Ctrl</p>
                        <p>X</p>
                    </div>
                </div>
                <div className="editTextType" onClick={()=>editTextLink("[]()")}>
                    <p>Ссылка</p>
                    <div>
                        <p>Shift</p>
                        <p>Ctrl</p>
                        <p>U</p>
                    </div>
                </div>
                <div className="editTextType"  onClick={()=>editText("`")}>
                    <p>Код в тексте</p>
                    <div>
                        <p>Shift</p>
                        <p>Ctrl</p>
                        <p>C</p>
                    </div>
                </div>
                <div className="editTextType" onClick={()=>editText("```")}>
                    <p>Блок кода</p>
                    <div>
                        <p>Shift</p>
                        <p>Ctrl</p>
                        <p>Alt</p>
                        <p>C</p>
                    </div>
                </div>


            </div>
        </div>
    )
}