import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import EditPrioritySelect from "../editPrioritySelect/editPrioritySelect";
import EditStatusBySelect from "../editStatusBySelect/editStatusBySelect";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import ChangeDate from "../changeDate/changeDate";
import ButtonDeleteTask from "../buttonDeleteTask";
import { useToast } from '@chakra-ui/react'
import { useNavigate } from "react-router";
import ButtonDeleteSubtaskTask from "../buttonDeleteSubTask";

export default function ForMobileAndTableMobileList({ getAllTaskRed, update, data, handleGetNotify, onlineUsers, item, index, allitemForStatus, indexParent }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    let onlineUSers = useSelector(onlineUSers => onlineUSers);
    const [mouts, setmounts] = useState(["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"])
    const [currentDate, setcurrentDate] = useState("")
    const [isOpen, setIsopen] = useState(false)
    const toggleRef = useRef(null);
    const toast = useToast();
    const navigate = useNavigate();
    const [borderRightColor, setBorderRightColor] = useState("");
    const toggle = () => {
        if (toggleRef.current.style.height === "0px" || toggleRef.current.style.height === "") {
            toggleRef.current.style.height = `auto`;
            setIsopen(true)
        } else {
            toggleRef.current.style.height = "0px";
            setIsopen(false)
        }
    };
    function Copytex(text = "Gor") {
        var textField = document.createElement('textarea')
        textField.innerText = `${window.location.origin}/editChild/` + allitemForStatus._id + "/" + index
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        toast({
            position: "top",
            title: 'Ссылка на задачу скопирована.',
            description: "Ссылка на задачу скопирована.",
            status: 'success',
            duration: 1000,
            isClosable: true,
        })


    }
    useEffect(() => {
        if (item) {
            if (item.status === "Новая") {
                setBorderRightColor("#FFEC8B");
            } else if (item.status === "Выполнена") {
                setBorderRightColor("#56D4FC");
            } else if (item.status === "Закрыта") {
                setBorderRightColor("#B4FFA8");
            } else if (item.status === "Возвращена" || item.status === "возвращена") {
                setBorderRightColor("#FB50FF");
            } else if (item.status === "Выполняется") {
                setBorderRightColor("#5364FF");
            } else if (item.status === "Отменено") {
                setBorderRightColor("#FF50A0");
            }
        }


    }, [item.status])

    return (
        <div>
            <div className="Component77" >
                <div className="Frame27" onClick={toggle}>
                    <div style={{ borderRightColor: borderRightColor }}>
                        {
                            isOpen ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path d="M18 15L12 9L6 15" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <path d="M6 9L12 15L18 9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                        }

                    </div>
                    <div>
                        {/* <p>{item.alltask[0].taskName}</p> */}
                        <p>{item?.msg}</p>
                    </div>
                </div>
                <div className="Frame626026" >
                    <div onClick={() => navigate("/editChild/" + allitemForStatus._id + "/" + index)}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M1 19H10H19" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10.2198 3.82843L15.1696 8.77814M10.2198 3.82843L13.0483 1L17.998 5.94975L15.1696 8.77814L10.2198 3.82843ZM10.2198 3.82843L4.6132 9.43504C4.42567 9.62254 4.32031 9.87694 4.32031 10.1421V14.6776H8.85588C9.12108 14.6776 9.37538 14.5723 9.56298 14.3847L15.1696 8.77814L10.2198 3.82843Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <div onClick={Copytex}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14 11.9976C14 9.5059 11.683 7 8.85714 7C8.52241 7 7.41904 7.00001 7.14286 7.00001C4.30254 7.00001 2 9.23752 2 11.9976C2 14.376 3.70973 16.3664 6 16.8714C6.36756 16.9525 6.75006 16.9952 7.14286 16.9952" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M10 11.9976C10 14.4893 12.317 16.9952 15.1429 16.9952C15.4776 16.9952 16.581 16.9952 16.8571 16.9952C19.6975 16.9952 22 14.7577 22 11.9976C22 9.61921 20.2903 7.62885 18 7.12384C17.6324 7.04279 17.2499 7 16.8571 7" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <ButtonDeleteSubtaskTask item={allitemForStatus} indexParent={indexParent} indexChild={index} key={index} />
                </div>
            </div>
            <div className="hiddenContenet" id={"hiddenContenet" + index} ref={toggleRef}>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Название задачи</p>
                    </div>
                    <div className="Sortablecolumn">
                        <p>{item.msg}</p>

                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Маркетплейсы</p>
                    </div>
                    <div className="Sortablecolumn">
                        {
                            allitemForStatus?.alltask[0]?.wb.length > 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                    <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H23C24.6569 0.5 26 1.84315 26 3.5V22.5C26 24.1569 24.6569 25.5 23 25.5H3C1.34315 25.5 0 24.1569 0 22.5V3.5Z" fill="#8A27D8" />
                                    <path d="M6.50675 14.5L4.36186 7.22727H6.56357L7.55788 11.7017H7.6147L8.79368 7.22727H10.5266L11.7056 11.7159H11.7624L12.7567 7.22727H14.9585L12.8136 14.5H10.9244L9.68857 10.4375H9.63175L8.39595 14.5H6.50675Z" fill="white" />
                                    <path d="M15.5977 14.5V7.22727H18.7511C19.305 7.22727 19.7702 7.30185 20.1467 7.45099C20.5254 7.60014 20.8107 7.81084 21.0025 8.0831C21.1966 8.35535 21.2937 8.67614 21.2937 9.04545C21.2937 9.31297 21.2345 9.55682 21.1161 9.77699C21.0001 9.99716 20.8368 10.1818 20.6261 10.331C20.4154 10.4777 20.1692 10.5795 19.8874 10.6364V10.7074C20.1999 10.7192 20.484 10.7985 20.7397 10.9453C20.9954 11.0897 21.199 11.2898 21.3505 11.5455C21.502 11.7988 21.5778 12.0971 21.5778 12.4403C21.5778 12.8381 21.4736 13.192 21.2653 13.5021C21.0593 13.8123 20.7657 14.0561 20.3846 14.2337C20.0034 14.4112 19.5489 14.5 19.021 14.5H15.5977ZM17.5721 12.9233H18.4954C18.8268 12.9233 19.0754 12.8617 19.2411 12.7386C19.4068 12.6132 19.4897 12.4285 19.4897 12.1847C19.4897 12.0142 19.4506 11.8698 19.3725 11.7514C19.2944 11.633 19.1831 11.5431 19.0387 11.4815C18.8967 11.42 18.725 11.3892 18.5238 11.3892H17.5721V12.9233ZM17.5721 10.1676H18.3817C18.5546 10.1676 18.7073 10.1404 18.8398 10.0859C18.9724 10.0315 19.0754 9.95336 19.1488 9.85156C19.2246 9.7474 19.2624 9.62074 19.2624 9.47159C19.2624 9.24669 19.1819 9.07505 19.021 8.95668C18.86 8.83594 18.6564 8.77557 18.4102 8.77557H17.5721V10.1676Z" fill="white" />
                                    <path d="M4 21C4 20.7239 4.22386 20.5 4.5 20.5H21.5C21.7761 20.5 22 20.7239 22 21C22 21.2761 21.7761 21.5 21.5 21.5H4.5C4.22386 21.5 4 21.2761 4 21Z" fill="white" />
                                </svg>
                                :
                                ""
                        }

                        {
                            allitemForStatus?.alltask[0]?.ozon.length > 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="26" viewBox="0 0 23 26" fill="none">
                                    <path d="M4.18901 7.22727H6.39071L7.81117 10.1818H7.86799L9.28844 7.22727H11.4901L8.81969 12.2131V14.5H6.85946V12.2131L4.18901 7.22727Z" fill="white" />
                                    <path d="M12.1338 7.22727H14.5912L16.2815 11.3466H16.3667L18.0571 7.22727H20.5145V14.5H18.5827V10.2955H18.5258L16.9065 14.4432H15.7417L14.1224 10.267H14.0656V14.5H12.1338V7.22727Z" fill="white" />
                                    <path d="M4 21C4 20.7239 4.22386 20.5 4.5 20.5H20.5C20.7761 20.5 21 20.7239 21 21C21 21.2761 20.7761 21.5 20.5 21.5H4.5C4.22386 21.5 4 21.2761 4 21Z" fill="white" />
                                    <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H20C21.6569 0.5 23 1.84315 23 3.5V22.5C23 24.1569 21.6569 25.5 20 25.5H3C1.34315 25.5 0 24.1569 0 22.5V3.5Z" fill="#005CFF" />
                                    <path d="M11.563 10.8636C11.563 11.6733 11.4056 12.3563 11.0907 12.9126C10.7759 13.4666 10.3509 13.8868 9.81587 14.1733C9.28084 14.4574 8.68425 14.5994 8.0261 14.5994C7.36322 14.5994 6.76426 14.4562 6.22923 14.1697C5.69656 13.8809 5.27279 13.4595 4.95792 12.9055C4.64542 12.3492 4.48917 11.6686 4.48917 10.8636C4.48917 10.054 4.64542 9.37216 4.95792 8.81818C5.27279 8.26184 5.69656 7.84162 6.22923 7.55753C6.76426 7.27107 7.36322 7.12784 8.0261 7.12784C8.68425 7.12784 9.28084 7.27107 9.81587 7.55753C10.3509 7.84162 10.7759 8.26184 11.0907 8.81818C11.4056 9.37216 11.563 10.054 11.563 10.8636ZM9.53178 10.8636C9.53178 10.428 9.47378 10.0611 9.35778 9.76278C9.24414 9.46212 9.07487 9.23485 8.84997 9.08097C8.62743 8.92472 8.35281 8.84659 8.0261 8.84659C7.6994 8.84659 7.42359 8.92472 7.19869 9.08097C6.97615 9.23485 6.80688 9.46212 6.69087 9.76278C6.57724 10.0611 6.52042 10.428 6.52042 10.8636C6.52042 11.2992 6.57724 11.6674 6.69087 11.968C6.80688 12.2663 6.97615 12.4936 7.19869 12.6499C7.42359 12.8037 7.6994 12.8807 8.0261 12.8807C8.35281 12.8807 8.62743 12.8037 8.84997 12.6499C9.07487 12.4936 9.24414 12.2663 9.35778 11.968C9.47378 11.6674 9.53178 11.2992 9.53178 10.8636Z" fill="white" />
                                    <path d="M12.505 14.5V13.4062L15.9425 8.81818H12.5192V7.22727H18.4567V8.32102L15.0192 12.9091H18.4425V14.5H12.505Z" fill="white" />
                                    <path d="M4 21C4 20.7239 4.22386 20.5 4.5 20.5H18.5C18.7761 20.5 19 20.7239 19 21C19 21.2761 18.7761 21.5 18.5 21.5H4.5C4.22386 21.5 4 21.2761 4 21Z" fill="white" />
                                </svg>
                                :
                                ""
                        }
                        {
                            allitemForStatus?.alltask[0]?.yandex && allitemForStatus?.alltask[0]?.yandex.length > 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="26" viewBox="0 0 25 26" fill="none">
                                    <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H22C23.6569 0.5 25 1.84315 25 3.5V22.5C25 24.1569 23.6569 25.5 22 25.5H3C1.34315 25.5 0 24.1569 0 22.5V3.5Z" fill="#FED42B" />
                                    <path d="M4.18901 7.22727H6.39071L7.81117 10.1818H7.86799L9.28844 7.22727H11.4901L8.81969 12.2131V14.5H6.85946V12.2131L4.18901 7.22727Z" fill="white" />
                                    <path d="M12.1338 7.22727H14.5912L16.2815 11.3466H16.3667L18.0571 7.22727H20.5145V14.5H18.5827V10.2955H18.5258L16.9065 14.4432H15.7417L14.1224 10.267H14.0656V14.5H12.1338V7.22727Z" fill="white" />
                                    <path d="M4 21C4 20.7239 4.22386 20.5 4.5 20.5H20.5C20.7761 20.5 21 20.7239 21 21C21 21.2761 20.7761 21.5 20.5 21.5H4.5C4.22386 21.5 4 21.2761 4 21Z" fill="white" />
                                </svg>
                                :
                                ""
                        }

                        {
                            allitemForStatus?.alltask[0]?.megaMarket && allitemForStatus?.alltask[0]?.megaMarket.length > 0 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="26" viewBox="0 0 35 26" fill="none">
                                    <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H32C33.6569 0.5 35 1.84315 35 3.5V22.5C35 24.1569 33.6569 25.5 32 25.5H3C1.34315 25.5 0 24.1569 0 22.5V3.5Z" fill="#17DB71" />
                                    <path d="M11.5993 9.95455H9.5965C9.5823 9.78883 9.54442 9.63849 9.48287 9.50355C9.42368 9.36861 9.34082 9.2526 9.23429 9.15554C9.13012 9.05611 9.00346 8.98035 8.85431 8.92827C8.70517 8.87382 8.5359 8.84659 8.3465 8.84659C8.01506 8.84659 7.73452 8.92708 7.50488 9.08807C7.27761 9.24905 7.10479 9.47988 6.98642 9.78054C6.87041 10.0812 6.81241 10.4422 6.81241 10.8636C6.81241 11.3087 6.8716 11.6816 6.98997 11.9822C7.11071 12.2805 7.28471 12.5054 7.51199 12.657C7.73926 12.8061 8.0127 12.8807 8.3323 12.8807C8.51459 12.8807 8.67794 12.8582 8.82235 12.8132C8.96677 12.7659 9.09224 12.6984 9.19878 12.6108C9.30531 12.5232 9.39172 12.4179 9.45801 12.2947C9.52666 12.1693 9.57283 12.0284 9.5965 11.8722L11.5993 11.8864C11.5757 12.1941 11.4893 12.5078 11.3401 12.8274C11.191 13.1446 10.9791 13.4382 10.7045 13.7081C10.4322 13.9756 10.0948 14.1911 9.69238 14.3544C9.28992 14.5178 8.82235 14.5994 8.28968 14.5994C7.62207 14.5994 7.02311 14.4562 6.49281 14.1697C5.96487 13.8833 5.54702 13.4619 5.23926 12.9055C4.93386 12.3492 4.78116 11.6686 4.78116 10.8636C4.78116 10.054 4.93741 9.37216 5.24991 8.81818C5.56241 8.26184 5.98381 7.84162 6.51412 7.55753C7.04442 7.27107 7.63628 7.12784 8.28968 7.12784C8.74896 7.12784 9.17155 7.19058 9.55744 7.31605C9.94333 7.44152 10.2819 7.625 10.5731 7.86648C10.8643 8.10559 11.0986 8.40033 11.2762 8.75071C11.4537 9.10109 11.5615 9.50237 11.5993 9.95455Z" fill="white" />
                                    <path d="M12.4951 7.22727H14.9525L16.6428 11.3466H16.7281L18.4184 7.22727H20.8758V14.5H18.944V10.2955H18.8872L17.2678 14.4432H16.1031L14.4838 10.267H14.4269V14.5H12.4951V7.22727Z" fill="white" />
                                    <path d="M21.8115 7.22727H24.2689L25.9593 11.3466H26.0445L27.7348 7.22727H30.1922V14.5H28.2604V10.2955H28.2036L26.5843 14.4432H25.4195L23.8002 10.267H23.7433V14.5H21.8115V7.22727Z" fill="white" />
                                    <path d="M4 21.0952C4 20.7665 4.2665 20.5 4.59524 20.5H30.4048C30.7335 20.5 31 20.7665 31 21.0952C31 21.424 30.7335 21.6905 30.4048 21.6905H4.59524C4.2665 21.6905 4 21.424 4 21.0952Z" fill="white" />
                                </svg>
                                :
                                ""
                        }

                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Исполнитель</p>
                    </div>
                    <div className="Sortablecolumn">
                        {/* <div>

                            {
                                onlineUSers.getOnlineUserViaRouterReducer.users.data?.find((user) => user === item.performer?.workerID)
                                    ? <Avatar size='md' src={item.performer[0]?.img ? `/${item.performer[0]?.img}` : "https://bit.ly/broken-link"} style={{ marginRight: "10px" }}>
                                        <AvatarBadge boxSize='0.9em' bg='green.500' />
                                    </Avatar>
                                    : <Avatar size='md' src={item.performer[0]?.img ? `/${item.performer[0]?.img}` : "https://bit.ly/broken-link"} style={{ marginRight: "10px" }} />
                            }

                            <div>
                                <p>{item.performer[0]?.name}</p>
                                <p>{item.performer[0]?.tel}</p>

                            </div>
                        </div> */}
                        <AvatarGroup size='sm' max={2}>
                            {
                                item.performer?.map((performer, index) => {
                                    const isOnline = onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(performer.workerID);
                                    return (
                                        <Avatar
                                            key={index}
                                            size='md'
                                            src={performer.img ? `/${performer.img}` : "https://bit.ly/broken-link"}
                                        >
                                            {isOnline && <AvatarBadge boxSize='0.9em' bg='green.500' />}
                                        </Avatar>
                                    )
                                })
                            }
                        </AvatarGroup>
                        {
                            item.performer.length === 1 ?
                                <div className="perfAllInfo">
                                    <p>{item?.performer[0]?.name}</p>
                                    <p>{item?.performer?.tel}</p>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Сроки задач</p>
                    </div>
                    <div className="Sortablecolumn">
                        <div>
                            <div>
                                <ChangeDate item={allitemForStatus} isChild={true} currentDate={currentDate} allitemForStatus={allitemForStatus} index={index} update={update} key={index} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Приоритет</p>
                    </div>
                    <div className="Sortablecolumn">
                        <div>
                            {
                                (() => {
                                    if (item.prioritet == "") {

                                        return <EditPrioritySelect isChild={true} prioritetGet={"Низший"} allitemForStatus={allitemForStatus} index={index} update={update} />
                                        // return <EditPrioritet isChild={true} prioritetGet={"Низший"} allitemForStatus={allitem} index={indexs} update={update} />
                                    } else {
                                        return <EditPrioritySelect isChild={true} prioritetGet={item.prioritet} allitemForStatus={allitemForStatus} index={index} update={update} />
                                        // return <EditPrioritet isChild={true} prioritetGet={items.prioritet} allitemForStatus={allitem} index={indexs} update={update} />
                                    }
                                })()
                            }
                        </div>
                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Статус</p>
                    </div>
                    <div className="Sortablecolumn">
                        <div>
                            {
                                (() => {
                                    if (item.status == "") {
                                        // return <EditStatus isChild={true} statusGet={"Запланирована"} allitemForStatus={allitem} index={indexs} update={update} />
                                        return <EditStatusBySelect isChild={true} statusGet={"Запланирована"} allitemForStatus={allitemForStatus} index={index} update={update} />
                                    } else {
                                        // return <EditStatus isChild={true} statusGet={items.status} allitemForStatus={allitem} index={indexs} update={update} />
                                        return <EditStatusBySelect isChild={true} statusGet={item.status} allitemForStatus={allitemForStatus} index={index} update={update} />
                                    }
                                })()
                            }

                        </div>
                    </div>
                </div>
                <div className="Frame626030">
                    <div className="Heading">
                        <p>Цена</p>
                    </div>
                    <div className="Sortablecolumn">
                        <div>
                            <input type="number" defaultValue={0} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}