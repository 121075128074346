import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar,Line } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July',"August","September","October","November","December"];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [1,2,3,4,5,6,7,9,10,11,12],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: new Date(),

      data: [25,3,4,5,6,7,46,72,11,22],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export function Statistic() {
  return (
    <>
     <Bar options={options} data={data} />
 
    </>
  );
}
