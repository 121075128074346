
import ChatNavFilter from "../chatNavFilter/chatNavFilter"
import "./ChtatLeftSideContLight.css"
import "./ChtatLeftSideContDark.css"
import { useSelector } from "react-redux"


export default function ChtatLeftSide(params) {
    const getDarkModoe = useSelector((darkModes) => darkModes);
            return (
                <div   className={
                    getDarkModoe.ToggleDarkModeReducer.chekced
                      ? "ChtatLeftSideContDark"
                      : "ChtatLeftSideContLight"
                  }>
                           
                    <ChatNavFilter />
                 
                   
                </div>
            )
}