
import { useEffect, useRef, useState } from "react";
import ModAlForTutorilaVideo from "../modAlForTutorilaVideo/modAlForTutorilaVideo"
import "./taskMenegerHeaderLight.css"
import "./taskMenegerHeaderDark.css"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux";
import Filter from "../filter/filter";


export default function TaskMenegerHeader({ searchByPerfomer, handleSelect, searchValue }) {
    const navigate = useNavigate();
    const dropRef = useRef(null);
    const [isDrop, setDrop] = useState(false);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const inputRef = useRef(null);


    const handleClickOutside = (event) => {
        if (dropRef.current && !dropRef.current.contains(event.target)) {
            setDrop(false);
        }
    };

    useEffect(() => {

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, []);


    return <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "taskMenegerHeaderDark" : "taskMenegerHeaderLight"}>
        <div>
            <button className="Component50" onClick={() => navigate("/creattask")}>
                <div className="Frame4">
                    <div className="Component46">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                        </svg>
                    </div>
                    <p>Добавить задачу</p>
                </div>
            </button>
            <div className="Component41">
                <div className="Field" onClick={() => setDrop(!isDrop)}>
                    <div className="value">
                        <div className="Frame625852">
                            <p>Сортировать по:</p>
                            <div className="Frame625851">Сортировать</div>
                            <div className="Component46">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 9L12 15L6 9" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                {/* dropDown */}
                {isDrop && (
                    <ul className="dropdown-contentSelecet" ref={dropRef}>
                        <li onClick={() => [handleSelect("Приоритет"), setDrop(false)]}>Приоритету</li>
                        <li onClick={() => [handleSelect("статусу"), setDrop(false)]}>Статусу</li>
                        <li onClick={() => [handleSelect("Дата"), setDrop(false)]}>Срокам</li>
                    </ul>
                )}
            </div>
           <Filter />
        </div>
        <div>
            <ModAlForTutorilaVideo />
            <label className="Component43" htmlFor="searchTaskTrackerHeader">
                <input ref={inputRef} type="text" placeholder="Поиск" id="searchTaskTrackerHeader" onChange={(e) => searchByPerfomer(e.target.value)} value={searchValue} />
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                        <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                    </svg>
                </div>
            </label>
        </div>
    </div>
}