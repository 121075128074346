import React, { useEffect, useRef, useState } from "react";

import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  HStack,
  Select
} from "@chakra-ui/react";
import "./tagInputOzon.css"


export default function TagInputOzon({childs,item,required}) {
  
  const [data,setData] = useState([])
  const divref = useRef(null)
  const refinput = useRef(null)
  const colorP = useRef(null)
  const bool = useRef(false)
const [isshow,setisshow] = useState(false)
const [inputvalue,setinputvalue] = useState("")

  useEffect(()=>{
    setisshow(false)
    setData(childs)
   let m =  childs?.sort((e)=>{
    return e
   })

  },[])

  const [value,setValue]=useState(
   
    {
      "complex_id": 0,
      "id": item.id,
      values: [
       
      ]
    }
   
  )

  const handleDelet=(e)=>{
    setValue(prevState => ({
      ...prevState,
      values: prevState.values.filter(item => item.value !== e.value)
    }));
 
   
  }


  const handleChange =async(e)=>{
   
    let targtet = await JSON.parse(e)
   


    let findvalue = await value.values.find(item=> item.value ==targtet.value)
   
        if (!findvalue) {
        await  setValue(prevState => ({
            ...prevState,
            values: [
              ...prevState.values,
              {
                "dictionary_value_id": targtet.id,
                "value": targtet.value
              }
            ]
          }));
        }
   
  console.log(value);
   
  }



  

  const changeinput=(e)=>{
    const pattern = new RegExp(e, 'i');
    setData(childs)
      if (e == "") {
        setData(childs)
      }else{
        let x =   childs.filter(item => pattern.test(item.value))
      if(x){
         setData(x)
      }else{
        console.log(childs);
        setData(childs)
      }
      }
   
  }


//   function filterFunction(e) {
     
//     divref.current.style.display = 'block';
//   let  input = document.getElementById("myInput");
//    let filter = input.value.toUpperCase();
//    let div = document.getElementById("myDropdown");
//    let a = div.getElementsByTagName("p");


//     for (let i = 0; i < a.length; i++) {
// let        txtValue = a[i].textContent || a[i].innerText;
//       if (txtValue.toUpperCase().indexOf(filter) > -1) {
       
//         a[i].style.display = "";
       
//       } else {
//         a[i].style.display = "none";
//       }
//     }
//   }



 window.addEventListener("click",(e)=>{
  e.stopPropagation();
     if (e.target.id && e.target.id =="myInput" ) {
      bool.current = true
      divref.current.style.display = 'block';
     }else{
     
      bool.current = false
      divref.current.style.display = 'none';
     }
 })

const shows=(e)=>{

  e.stopPropagation();


  if (data) {
    bool.current = !bool.current
    if (bool.current) {
      divref.current.style.display = 'block';
    }else{
      divref.current.style.display = 'none';
    }
  }


}

const changeSelec=(e)=>{


    setinputvalue(e)
}
  return (
    <div className="taginput">
     
      {item?
      <h1>{item.name}</h1>
      :
      ""}
      <HStack spacing={4}>
       
        {value.values.map((item,index) => (
          <Tag
            size={"lg"}
            key={index}
            borderRadius="full"
            variant="solid"
            colorScheme="green"
          >
            <TagLabel>{item.value}</TagLabel>
            <TagCloseButton onClick={(e)=> handleDelet(item)}/>
          </Tag>
        ))}
       
      </HStack>
{/*      
      <Select name="" id="" onChange={handleChange} required={required} >
      <option value={""}>Выберите {item.name}</option>
        {
    
        data?.map((item,index)=>{
              return <option value={JSON.stringify(item)} key={index}>{item.value}</option>
        })}
          
          
        </Select> */}
       <input type="hidden" name="taginput" value={JSON.stringify(value)} key={JSON.stringify(value)}/>

       <div className="dropdown" >
  
  <input type="text" placeholder="Выберите" id="myInput" name="selectunput"  onChange={(e)=> changeinput(e.target.value)} onClick={shows}  ref={refinput} autoComplete="off" defaultValue={inputvalue} key={"abs" + inputvalue} required={required}/>

<div id="myDropdown" className="dropdown-content"  ref={divref} >
 
{data?.map((item,index)=>{

            return   (
             <p  key={index} onClick={()=>[ handleChange(JSON.stringify(item)),changeSelec(item.value)]} ref={colorP}>{item.value}</p>
           )
   
 })}

</div>
</div>
    </div>
  );
}
