import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useEffect, useState } from 'react'


export default function OzonEditBool(item) {
       const [value,setValue] = useState("")
       let atributeParams =  {
              complex_id: 0,
              id: item.item.id,
              values: [
                {
                      value:"" 
                }
              ]
            }

                     useEffect(()=>{
                                
                                   if (item.item.currentinfo) {
                                        
                                          atributeParams.values[0] =item.item.currentinfo.values[0]
                                         
                                          setValue(atributeParams)
                                   }else{
                                          atributeParams.values[0].value = "false"
                                          setValue(atributeParams)
                                   }
                     },[])

const handleBool=(e)=>{
            if (e.target.checked) {
                console.log(e.target.checked);
                
            }else{
              atributeParams.values[0].value = "false"
              setValue(atributeParams)
            }
            console.log(JSON.stringify(value));
}

                     if (item.item.currentinfo) {
                           
                            if (item.item.currentinfo.values[0].value == "true") {
                                   return (
                                          <>
                                                 <Checkbox defaultChecked onChange={(e)=>handleBool(e)}>{item.item.name}</Checkbox>
                                                 <input type="hidden" name="ozonEditBool" defaultValue={JSON.stringify(value)} key={value}/>
                                          </>
                                   )
                            }else{
                                 return(
                                   <>
                                   <Checkbox onChange={(e)=>handleBool(e)}>{item.item.name}</Checkbox>
                                   <input type="hidden" name="ozonEditBool" defaultValue={JSON.stringify(value)} key={value}/>
                                  </>
                                 )
                            }
                     }else{
                            return(
                                   <>
                                   <Checkbox onChange={(e)=>handleBool(e)}>{item.item.name}</Checkbox>
                                   <input type="hidden" name="ozonEditBool" defaultValue={JSON.stringify(value)} key={value}/>
                                  </>
                                 )
                     }
             

      
}