
import ChatBody from "../chatBody/chatBody"
import ChatBox from "../chatBox/chatBox"
import ChatHeader from "../chatHeader/chatheader"
import PinZakrep from "../pinZakrep/pinZakrep"
import { useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import ReplaceSelectorForChatBody from "../replaceSelectorForChatBody/replaceSelectorForChatBody"
export default function PublicChat(params) {
    const [searchParams] = useSearchParams();
    const chatID = searchParams.get('chatID');

    // ответить на под комментарий
    const otherMenuOptionsForMessageItemsReducer = useSelector((otherMenuOptionsForMessageItemsReducer) => otherMenuOptionsForMessageItemsReducer.otherMenuOptionsForMessageItemsReducer
    );
    const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
    const getMyInfo = useSelector(getMyInfo => getMyInfo.getMayInfoReducer);
    const getMessageItems = useSelector(messageItem => messageItem);
    // getMyInfo.myinfo._id
    if (chatID) {

        return (
            <div className="conversationsLightCont" >
                <ChatHeader backToUrl={"/chat/public"} />
                {
                    getChatInfo.data && getChatInfo.data.chatOptions && getChatInfo.data.chatOptions.pinMessageID && getChatInfo.data.chatOptions.pinMessageID.length > 0 &&
                    <PinZakrep />
                }
            {
                getMessageItems.userReducerMessageItem.data && <ChatBody />
            }    

                {
                    otherMenuOptionsForMessageItemsReducer && otherMenuOptionsForMessageItemsReducer.replyMessage && <ReplaceSelectorForChatBody />
                }
                {/* здесь мы проверяем если тип чата является каналом то проверяется данные */}
                

                {
                    (() => {
                        if (getChatInfo.data && getChatInfo.data.chatOptions && getChatInfo.data.chatOptions.chatParticipantsID &&
                            getChatInfo.data.chatOptions.chatParticipantsID.find(myid => myid === getMyInfo.myinfo._id)) {
                            return <ChatBox isTrade={false} />;
                        } else {
                            // Другие внутренние действия, если условие не выполнено
                        }
                    })()
                }
            </div>
        )
    }
}