import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    useDisclosure
} from '@chakra-ui/react'

import { useSelector } from 'react-redux'
import WhoReadThisComment from '../whoReadThisComment/whoReadThisComment';
export default function CommentPopover({ deleteComment, handleAllComments, item, index }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const { onOpen, onClose, isOpen } = useDisclosure()
    return (
        <Popover 
        placement='top-start'
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
        >
            <PopoverTrigger >
                <button className='popoverButton'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                        <circle cx="2.00156" cy="2.30312" r="1.6" transform="rotate(90 2.00156 2.30312)" fill="#767676" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                        <circle cx="2.00156" cy="2.30312" r="1.6" transform="rotate(90 2.00156 2.30312)" fill="#767676" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none">
                        <circle cx="2.00156" cy="2.30312" r="1.6" transform="rotate(90 2.00156 2.30312)" fill="#767676" />
                    </svg>
                </button>
            </PopoverTrigger>
            <PopoverContent width={"auto"} height={"a"}  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverCommentDark" : "popoverCommentCreatLight"}>

                <PopoverBody >
                        <WhoReadThisComment item={item}/>
                    <div onClick={() => [deleteComment(item, index), onClose()]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 6.1875V3.6875H15V6.1875H8.5ZM7.75 7.6875H15.75H20.75C21.1642 7.6875 21.5 7.35171 21.5 6.9375C21.5 6.52329 21.1642 6.1875 20.75 6.1875H16.5V3.5375C16.5 2.79194 15.8956 2.1875 15.15 2.1875H8.35C7.60442 2.1875 7 2.79192 7 3.5375V6.1875H2.75C2.33579 6.1875 2 6.52329 2 6.9375C2 7.35171 2.33579 7.6875 2.75 7.6875H7.75ZM5.5 10.9375C5.5 10.5233 5.16421 10.1875 4.75 10.1875C4.33579 10.1875 4 10.5233 4 10.9375V20.3375C4 21.0831 4.60444 21.6875 5.35 21.6875H18.15C18.8956 21.6875 19.5 21.0831 19.5 20.3375V10.9375C19.5 10.5233 19.1642 10.1875 18.75 10.1875C18.3358 10.1875 18 10.5233 18 10.9375V20.1875H5.5V10.9375ZM10.5 10.9375C10.5 10.5233 10.1642 10.1875 9.75 10.1875C9.33579 10.1875 9 10.5233 9 10.9375V16.9375C9 17.3517 9.33579 17.6875 9.75 17.6875C10.1642 17.6875 10.5 17.3517 10.5 16.9375V10.9375ZM13.75 10.1875C14.1642 10.1875 14.5 10.5233 14.5 10.9375V16.9375C14.5 17.3517 14.1642 17.6875 13.75 17.6875C13.3358 17.6875 13 17.3517 13 16.9375V10.9375C13 10.5233 13.3358 10.1875 13.75 10.1875Z" fill="#DD5E98" />
                        </svg>
                        <p>Удалить</p>
                    </div>
                    <div onClick={() => [handleAllComments(item, index), onClose()]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M1 19.1875H10H19" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.2198 4.01593L15.1696 8.96564M10.2198 4.01593L13.0483 1.1875L17.998 6.13725L15.1696 8.96564L10.2198 4.01593ZM10.2198 4.01593L4.6132 9.62254C4.42567 9.81004 4.32031 10.0644 4.32031 10.3296V14.8651H8.85588C9.12108 14.8651 9.37538 14.7598 9.56298 14.5722L15.1696 8.96564L10.2198 4.01593Z" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <p>Редактировать</p>
                    </div>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}