
import { useEffect, useRef, useState } from 'react'
import "./status.css"
import "./statusDark.css"
import { useSelector } from 'react-redux'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure
} from '@chakra-ui/react'
export default function Status({ setStatus, status }) {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const getDarkModoe = useSelector(darkModes => darkModes);
  const [statusColor,setStatusColor]=useState("#FFEC8B");
  const [fakeShow,setfakeShow]=useState("Новая")

  // useEffect(() => {
  //   modalRef.current.style.display = "none"
  // }, [])



  const handleShow = () => {
    modalRef.current.style.display = "flex"
  }

  const handleClose = () => {
    modalRef.current.style.display = "none"
  }
  useEffect(() => {
    setStatus("Новая")
  }, [])

  useEffect(()=>{
    if (status == "Запланирована" || status == "Новая") {
      setfakeShow("Новая")
      setStatusColor("#FFEC8B")
    }else if (status == "Выполнена") {
      setfakeShow("Выполнена")
      setStatusColor("#9FE8FF")
    }else if (status == "Принята" || status == "Закрыта") {
      setfakeShow("Закрыта")
      setStatusColor("#B4FFA8")
    }else if (status == "возвращена") {
      setfakeShow("возвращена")
      setStatusColor("#FDA9FF")
    }else if (status == "Выполняется") {
      setfakeShow("Выполняется")
      setStatusColor("#9CA6FF")
    }else if (status == "Отменено") {
      setfakeShow("Отменено")
      setStatusColor("#FF9FCB")
    }
    
    
  },[status])
  const modalRef = useRef(null)
  return (
    <>
      {/* {
        (() => {
          if (status == 'Запланирована') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" onClick={handleShow}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66116C18.9946 1.31696 15.8152 0 12.5 0C9.18479 0 6.00537 1.31696 3.66116 3.66116C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66116 21.3388C6.00537 23.683 9.18479 25 12.5 25ZM14.0625 6.25C14.0625 5.8356 13.8979 5.43817 13.6049 5.14515C13.3118 4.85212 12.9144 4.6875 12.5 4.6875C12.0856 4.6875 11.6882 4.85212 11.3951 5.14515C11.1021 5.43817 10.9375 5.8356 10.9375 6.25V14.0625H16.6667C17.0811 14.0625 17.4785 13.8979 17.7715 13.6049C18.0645 13.3118 18.2292 12.9144 18.2292 12.5C18.2292 12.0856 18.0645 11.6882 17.7715 11.3951C17.4785 11.1021 17.0811 10.9375 16.6667 10.9375H14.0625V6.25Z" fill="#FFE870" />
              </svg>
              <p>Запланирована</p>
            </div>
          }
          else if (status == 'Выполняется') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" onClick={handleShow}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59644 19.4035 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4035 5.59644 25 12.5 25Z" fill="#707EFF" />
                <path d="M16.7671 13.8238L10.8669 17.3073C9.91714 17.868 8.75 17.1381 8.75 15.9835V9.01645C8.75 7.86184 9.91714 7.13201 10.8669 7.69274L16.7671 11.1763C17.7443 11.7533 17.7443 13.2467 16.7671 13.8238Z" fill="white" />
              </svg>
              <p>Выполняется</p>
            </div>
          }
          else if (status == 'Выполнена') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" onClick={handleShow}><g clip-path="url(#clip0_1274_4128)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59644 19.4035 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4035 5.59644 25 12.5 25Z" fill="#70DDFF"></path><path d="M17.8495 8.53731C15.2966 6.69708 12.7441 10.276 10.1911 9.0313V8.76653C10.1911 8.43769 9.92435 8.17091 9.59554 8.17091C9.26674 8.17091 9 8.43769 9 8.76653V19.0186C9 19.3473 9.26674 19.6141 9.59554 19.6141C9.92435 19.6141 10.1911 19.3473 10.1911 19.0186V14.6754C12.6176 15.858 15.0442 12.6835 17.4711 13.9468C17.5262 13.9757 17.5878 13.9898 17.65 13.9879C17.7121 13.9859 17.7727 13.9679 17.8259 13.9355C17.8791 13.9033 17.9231 13.8579 17.9536 13.8037C17.9841 13.7495 18.0001 13.6883 18 13.6261V8.83085C17.9999 8.77351 17.9862 8.71702 17.96 8.66599C17.9339 8.61497 17.896 8.57087 17.8495 8.53731Z" fill="white"></path></g><defs><clipPath id="clip0_1274_4128"><rect width="25" height="25" fill="white"></rect></clipPath></defs></svg>
              <p>Выполнена</p>
            </div>
          }
          else if (status == 'Отменено') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" onClick={handleShow}>
                <path d="M12.5 0C5.59375 0 0 5.59375 0 12.5C0 19.4062 5.59375 25 12.5 25C19.4062 25 25 19.4062 25 12.5C25 5.59375 19.4062 0 12.5 0ZM19.1458 17.3125L17.3021 19.1562L12.5 14.3438L7.6875 19.1562L5.84375 17.3125L10.6562 12.5L5.85417 7.6875L7.69792 5.84375L12.5 10.6562L17.3125 5.84375L19.1562 7.6875L14.3438 12.5L19.1458 17.3125Z" fill="#FF7070" />
              </svg>
              <p>Отменено</p>
            </div>
          }
          else if (status == 'Принята') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" onClick={handleShow}>
                <circle cx="12.5" cy="12.5" r="12.5" fill="#84FF70" />
                <rect x="6.47363" y="10.7773" width="8.80282" height="2.46479" transform="rotate(45 6.47363 10.7773)" fill="white" />
                <path d="M20.5254 9.1748L10.9558 18.7444L9.21289 17.0015L18.7825 7.43194L20.5254 9.1748Z" fill="white" />
              </svg>
              <p>Принята</p>
            </div>
          }
          else if (status == 'возвращена') {
            return <div className='statusContForShow'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none"><g clip-path="url(#clip0_1274_4166)" onClick={handleShow}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM7.12738 8.05497C7.12738 8.2969 7.27113 8.2448 7.47475 7.95891C8.78218 6.12339 10.8363 4.99967 13.043 5C16.903 5 20.0433 8.36463 20.0433 12.5003C20.0433 16.6357 16.903 20.0003 13.043 20C12.7206 20 12.4114 19.8628 12.1834 19.6185C11.9555 19.3743 11.8274 19.043 11.8274 18.6975C11.8274 18.3521 11.9555 18.0208 12.1834 17.7765C12.4114 17.5323 12.7206 17.395 13.043 17.395C15.5625 17.395 17.612 15.1991 17.612 12.5C17.612 9.80061 15.5625 7.60463 13.043 7.60463C11.4934 7.60463 10.0595 8.45385 9.22316 9.81722C9.03899 10.1178 9.10007 10.3838 9.31737 10.3838H9.71063C9.99274 10.3838 10.2633 10.5039 10.4628 10.7176C10.6623 10.9313 10.7743 11.2212 10.7743 11.5235C10.7743 11.8257 10.6623 12.1156 10.4628 12.3293C10.2633 12.5431 9.99274 12.6631 9.71063 12.6631H6.06369C5.78158 12.6631 5.51103 12.5431 5.31155 12.3293C5.11207 12.1156 5 11.8257 5 11.5235V7.61603C5 7.46637 5.02751 7.31817 5.08097 7.1799C5.13442 7.04163 5.21278 6.91599 5.31155 6.81016C5.41032 6.70433 5.52758 6.62039 5.65663 6.56311C5.78569 6.50584 5.92401 6.47636 6.06369 6.47636C6.20338 6.47636 6.3417 6.50584 6.47075 6.56311C6.5998 6.62039 6.71706 6.70433 6.81583 6.81016C6.91461 6.91599 6.99296 7.04163 7.04641 7.1799C7.09987 7.31817 7.12738 7.46637 7.12738 7.61603V8.05497Z" fill="#FC70FF">
                </path>
              </g><defs><clipPath id="clip0_1274_4166"><rect width="25" height="25" fill="white"></rect></clipPath></defs></svg>
              <p>возвращена</p>
            </div>
          }
        })()
      }
      <div id="myModal" ref={modalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "modalStatusCreatDark" : "modalStatusCreat"}>


        <div class="modal-contentStatusCreat">
          <div class="modal-headerStatusCreat">
            <span class="closeStatusCreat" onClick={handleClose}>&times;</span>
            <h2>Выберите статус</h2>
          </div>
          <div class="modal-bodyStatusCreat">
            <div className='chooseStatus'>
              <div onClick={() => setStatus("Принята")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <circle cx="13" cy="13" r="12.5" fill="#84FF70" />
                  <rect x="6.97363" y="11.2776" width="8.80282" height="2.46479" transform="rotate(45 6.97363 11.2776)" fill="white" />
                  <path d="M21.0254 9.6748L11.4558 19.2444L9.71289 17.5015L19.2825 7.93194L21.0254 9.6748Z" fill="white" />
                </svg>
                Принята
              </div>
              <div onClick={() => setStatus("Отменено")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M12.5 0C5.59375 0 0 5.59375 0 12.5C0 19.4062 5.59375 25 12.5 25C19.4062 25 25 19.4062 25 12.5C25 5.59375 19.4062 0 12.5 0ZM19.1458 17.3125L17.3021 19.1562L12.5 14.3438L7.6875 19.1562L5.84375 17.3125L10.6562 12.5L5.85417 7.6875L7.69792 5.84375L12.5 10.6562L17.3125 5.84375L19.1562 7.6875L14.3438 12.5L19.1458 17.3125Z" fill="#FF7070" />
                </svg>
                Отменено
              </div>
              <div onClick={() => setStatus("Выполняется")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59644 19.4035 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4035 5.59644 25 12.5 25Z" fill="#707EFF" />
                  <path d="M16.7671 13.8238L10.8669 17.3073C9.91714 17.868 8.75 17.1381 8.75 15.9835V9.01645C8.75 7.86184 9.91714 7.13201 10.8669 7.69274L16.7671 11.1763C17.7443 11.7533 17.7443 13.2467 16.7671 13.8238Z" fill="white" />
                </svg>
                Выполняется
              </div>
              <div onClick={() => setStatus("Выполнена")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <g clip-path="url(#clip0_1274_4128)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4035 25 25 19.4035 25 12.5C25 5.59644 19.4035 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4035 5.59644 25 12.5 25Z" fill="#70DDFF" />
                    <path d="M17.8495 8.53731C15.2966 6.69708 12.7441 10.276 10.1911 9.0313V8.76653C10.1911 8.43769 9.92435 8.17091 9.59554 8.17091C9.26674 8.17091 9 8.43769 9 8.76653V19.0186C9 19.3473 9.26674 19.6141 9.59554 19.6141C9.92435 19.6141 10.1911 19.3473 10.1911 19.0186V14.6754C12.6176 15.858 15.0442 12.6835 17.4711 13.9468C17.5262 13.9757 17.5878 13.9898 17.65 13.9879C17.7121 13.9859 17.7727 13.9679 17.8259 13.9355C17.8791 13.9033 17.9231 13.8579 17.9536 13.8037C17.9841 13.7495 18.0001 13.6883 18 13.6261V8.83085C17.9999 8.77351 17.9862 8.71702 17.96 8.66599C17.9339 8.61497 17.896 8.57087 17.8495 8.53731Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1274_4128">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Выполнена
              </div>
              <div onClick={() => setStatus("Запланирована")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C15.8152 25 18.9946 23.683 21.3388 21.3388C23.683 18.9946 25 15.8152 25 12.5C25 9.18479 23.683 6.00537 21.3388 3.66116C18.9946 1.31696 15.8152 0 12.5 0C9.18479 0 6.00537 1.31696 3.66116 3.66116C1.31696 6.00537 0 9.18479 0 12.5C0 15.8152 1.31696 18.9946 3.66116 21.3388C6.00537 23.683 9.18479 25 12.5 25ZM14.0625 6.25C14.0625 5.8356 13.8979 5.43817 13.6049 5.14515C13.3118 4.85212 12.9144 4.6875 12.5 4.6875C12.0856 4.6875 11.6882 4.85212 11.3951 5.14515C11.1021 5.43817 10.9375 5.8356 10.9375 6.25V14.0625H16.6667C17.0811 14.0625 17.4785 13.8979 17.7715 13.6049C18.0645 13.3118 18.2292 12.9144 18.2292 12.5C18.2292 12.0856 18.0645 11.6882 17.7715 11.3951C17.4785 11.1021 17.0811 10.9375 16.6667 10.9375H14.0625V6.25Z" fill="#FFE870" />
                </svg>
                Запланирована
              </div>
              <div onClick={() => setStatus("возвращена")}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <g clip-path="url(#clip0_1274_4166)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM7.12738 8.05497C7.12738 8.2969 7.27113 8.2448 7.47475 7.95891C8.78218 6.12339 10.8363 4.99967 13.043 5C16.903 5 20.0433 8.36463 20.0433 12.5003C20.0433 16.6357 16.903 20.0003 13.043 20C12.7206 20 12.4114 19.8628 12.1834 19.6185C11.9555 19.3743 11.8274 19.043 11.8274 18.6975C11.8274 18.3521 11.9555 18.0208 12.1834 17.7765C12.4114 17.5323 12.7206 17.395 13.043 17.395C15.5625 17.395 17.612 15.1991 17.612 12.5C17.612 9.80061 15.5625 7.60463 13.043 7.60463C11.4934 7.60463 10.0595 8.45385 9.22316 9.81722C9.03899 10.1178 9.10007 10.3838 9.31737 10.3838H9.71063C9.99274 10.3838 10.2633 10.5039 10.4628 10.7176C10.6623 10.9313 10.7743 11.2212 10.7743 11.5235C10.7743 11.8257 10.6623 12.1156 10.4628 12.3293C10.2633 12.5431 9.99274 12.6631 9.71063 12.6631H6.06369C5.78158 12.6631 5.51103 12.5431 5.31155 12.3293C5.11207 12.1156 5 11.8257 5 11.5235V7.61603C5 7.46637 5.02751 7.31817 5.08097 7.1799C5.13442 7.04163 5.21278 6.91599 5.31155 6.81016C5.41032 6.70433 5.52758 6.62039 5.65663 6.56311C5.78569 6.50584 5.92401 6.47636 6.06369 6.47636C6.20338 6.47636 6.3417 6.50584 6.47075 6.56311C6.5998 6.62039 6.71706 6.70433 6.81583 6.81016C6.91461 6.91599 6.99296 7.04163 7.04641 7.1799C7.09987 7.31817 7.12738 7.46637 7.12738 7.61603V8.05497Z" fill="#FC70FF" />
                  </g>
                  <defs>
                    <clipPath id="clip0_1274_4166">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Возвращена
              </div>
            </div>
          </div>
          <div class="modal-footerStatusCreat">
            <button onClick={handleClose}>закрыть</button>
          </div>
        </div>

      </div> */}
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownStatusCreatDark" : "dropdownStatusCreatLight"}>
        <Popover
         isOpen={isOpen}
         onOpen={onOpen}
         onClose={onClose}>
          <PopoverTrigger>
            <div>
              <div>
                <div>
                  <p>Статус:</p>
                  <div>
                    <button style={{ backgroundColor: statusColor }}>
                      {fakeShow}
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                      </svg>
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent borderRadius={"0 0 12px 12px"} overflow={"hidden"} width={"230px"}>
            <PopoverBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverForCreatStatusDark" : "popoverForCreatStatusLight"} borderEndRadius={"0 0 12px 12px"} overflow={"hidden"}>
              <div onClick={() => [setStatus("Новая"),onClose()]}>
                <div>
                  <div>
                    <p>Новая</p>
                  </div>
                </div>
              </div>
              <div onClick={() => [setStatus("Выполнена"),onClose()]}>
                <div>
                  <div>
                    <p>Выполнена</p>
                  </div>
                </div>
              </div>
              <div onClick={() => [setStatus("Закрыта"),onClose()]}>
                <div>
                  <div>
                    <p>Закрыта</p>
                  </div>
                </div>
              </div>
              <div onClick={() => [setStatus("возвращена"),onClose()]}>
                <div>
                  <div>
                    <p>Возвращена</p>
                  </div>
                </div>
              </div>
              <div onClick={() => [setStatus("Выполняется"),onClose()]}>
                <div>
                  <div>
                    <p>Выполняется</p>
                  </div>
                </div>
              </div>
              <div onClick={() => [setStatus("Отменено"),onClose()]}>
                <div>
                  <div>
                    <p>Отменена</p>
                  </div>
                </div>
              </div>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </div>
    </>
  )
}