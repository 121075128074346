import React, { useState, useEffect, useRef } from 'react';
import './editStatusBySelectLight.css';
import "./editStatusBySelectDark.css"
import sockets from '../../../../../socket/socket';
import { useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react'
import axios from 'axios';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure,
    onOpen, onClose, isOpen
} from '@chakra-ui/react'
const socket = sockets;

const EditStatusBySelect = ({ isChild, statusGet, allitemForStatus, index, update }) => {
    const [selected, setSelected] = useState(statusGet);
    const [isActive, setIsActive] = useState(false);
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
    const myinfos = useSelector(data => data.getMyImageReducer);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const selectRef = useRef(null);
    const [bgColorBTN, setbgColorBTN] = useState("");
    const { onOpen, onClose, isOpen } = useDisclosure();
    const toast = useToast();
    const [options] = useState([
        "Новая",
        "Выполнена",
        "Закрыта",
        "Возвращена",
        "Выполняется",
        "Отменено",


    ]);

    function getColor(statusGet) {
        if (statusGet === "Принята" || statusGet === "Закрыта") {
            return "#B4FFA8"
        } else if (statusGet === "Отменено") {
              return "#FF9FCB"
        } else if (statusGet === "Выполняется") {
            return "#9CA6FF"
        } else if (statusGet === "Выполнена") {
              return "#9FE8FF"
        } else if (statusGet === "Новая" || statusGet === "Запланирована") {
                 return "#FFEC8B"
        } else if (statusGet === "Возвращена" || statusGet === "возвращена") {
              return "#FDA9FF"
        }
    }
    useEffect(() => {
        setSelected(statusGet)
        if (statusGet === "Принята" || statusGet === "Закрыта") {
            setbgColorBTN("#B4FFA8")
            setSelected("Закрыта")
        } else if (statusGet === "Отменено") {
            setbgColorBTN("#FF9FCB")
            setSelected("Отменено")
        } else if (statusGet === "Выполняется") {
            setbgColorBTN("#9CA6FF")
            setSelected("Выполняется")
        } else if (statusGet === "Выполнена") {
            setbgColorBTN("#9FE8FF")
            setSelected("Выполнена")
        } else if (statusGet === "Новая" || statusGet === "Запланирована") {
            setbgColorBTN("#FFEC8B")
            setSelected("Новая")
        } else if (statusGet === "Возвращена" || statusGet === "возвращена") {
            setbgColorBTN("#FDA9FF")
            setSelected("Возвращена")
        }
    }, [statusGet])

    useEffect(() => {

    }, []);


    const handleOptionClick = (option) => {
        // setSelected(option);
        setIsActive(false);
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    const handleGetNotify = () => {
        socket.emit('getNotifyEmit');
    }
    //эта функция для того чтобы создать уведомление чтобы изменил что-то в задаче 
    async function sendInfoNotify(allitemForStatus, isChild, whatIsNotofy, index, currentTask) {
        console.log(currentTask);

        axios.post('/sendInfoNotify/api', {
            allitemForStatus,
            isChild,
            whatIsNotofy,
            index,
            currentTask
        })
            .then(function (response) {
                console.log(response);
                handleGetNotify()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    // для обновления статуса
    const handleUpdateStatus = (e) => {
        if (isChild) {
            allitemForStatus.alltask[0].child[index].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    sendInfoNotify(allitemForStatus, isChild, "status", index, allitemForStatus.alltask[0].child[index])
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false)
                    onClose()
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        } else {
            allitemForStatus.alltask[0].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response);
                    sendInfoNotify(allitemForStatus, isChild, "status", index, allitemForStatus.alltask[0])
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false)
                    onClose()
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }

    }
    // send notify for apply
    const handleStatus = () => {
        if (isChild) {
            // update Child
            axios.post('/notifyConfirm', {
                eidtTaskID: allitemForStatus._id,
                isChild: true,
                adminIDTask: allitemForStatus.creatorTasks.toString(),
                typenotify: "status",
                indexTask: index,
                senderName: getmyinfo.myinfo.name,
                senderTel: getmyinfo.myinfo.tel,
                senderEmail: getmyinfo.myinfo.email,
                text: "",
                messageConfirm: allitemForStatus.alltask[0].endDate,
                taskName: allitemForStatus.alltask[0].child[index].msg,
                oldDate: allitemForStatus.alltask[0].endDate,
                myAvatar: getmyinfo.myinfo.img

            })
                .then(function (response) {

                    socket.emit('getNotifyEmit');

                    toast({
                        position: "top",
                        title: 'Отправлено на подтверждение постановщику задачи',
                        description: "Отправлено на подтверждение постановщику задачи",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false);
                    onClose()
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            // update parent
            axios.post('/notifyConfirm', {
                eidtTaskID: allitemForStatus._id,
                isChild: false,
                adminIDTask: allitemForStatus.creatorTasks.toString(),
                typenotify: "status",
                indexTask: 0,
                senderName: getmyinfo.myinfo.name,
                senderTel: getmyinfo.myinfo.tel,
                senderEmail: getmyinfo.myinfo.email,
                text: "",
                messageConfirm: allitemForStatus.alltask[0].endDate,
                taskName: allitemForStatus.alltask[0].taskName,
                oldDate: allitemForStatus.alltask[0].endDate,
                myAvatar: getmyinfo.myinfo.img

            })
                .then(function (response) {

                    socket.emit('getNotifyEmit');
                    toast({
                        position: "top",
                        title: 'Отправлено на подтверждение постановщику задачи',
                        description: "Отправлено на подтверждение постановщику задачи",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false);
                    onClose()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    }
    // =============================================

    // ========================
    // этот функция для того чтобы создать уведомление для задач
    function sendSeverCreatNotifyForTask(e) {

        if (isChild) {
            let notifyText = `${getmyinfo.myinfo.name}  взял <a target="_blank" class="taskName" href="editChild/${allitemForStatus._id}/${index}">${allitemForStatus.alltask[0].child[index].taskName} </a> в работу.`
            axios.post('/creatNotifyForTaskInfo/api', {
                // здесь не проверяем Это задача и подзадача
                isChild,
                // здесь мы отправляем полное задание
                task: allitemForStatus,
                // если это подзадача тогда получаем индекс этого подзадачу
                index,
                // здесь мы отправляем текст для уведомлений
                notifyText,
                // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
                notificationRecipientID: allitemForStatus.creatorTasks,
                // информация пользователя для отправления уведомлений на электронную почту
                emailInfo: {
                    // здесь мы отправляем идентификатор пользователя
                    userIdForEmail: allitemForStatus.creatorTasks,
                    // текст для заголовки
                    subject: "Задача в работе",
                    // текст для электронную почту
                    textEmail: ""
                }
            })
                .then(function (response) {
                    console.log(response);
                    socket.emit('getNotifyEmit');
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            // для подзадач=================================================================================================================================================================================================
            let notifyText = `${getmyinfo.myinfo.name} взял  <a target="_blank" class="taskName" href="getCurrentTask/${allitemForStatus._id}">Задачу ${allitemForStatus.alltask[0].taskName} </a>  в работу.`
            axios.post('/creatNotifyForTaskInfo/api', {
                // здесь не проверяем Это задача и подзадача
                isChild,
                // здесь мы отправляем полное задание
                task: allitemForStatus,
                //  // если это подзадача тогда получаем индекс этого подзадачу
                index: 1234,
                // здесь мы отправляем текст для уведомлений
                notifyText,
                // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
                notificationRecipientID: allitemForStatus.creatorTasks,
                // информация пользователя для отправления уведомлений на электронную почту
                emailInfo: {
                    // здесь мы отправляем идентификатор пользователя
                    userIdForEmail: allitemForStatus.creatorTasks,
                    // текст для заголовки
                    subject: "Задача в работе",
                    // текст для электронную почту
                    textEmail: ""
                }
            })
                .then(function (response) {
                    console.log(response);
                    socket.emit('getNotifyEmit');
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const handleStatusAndSendNotify = (e) => {
        if (isChild) {

            allitemForStatus.alltask[0].child[index].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    // send notify
                    sendSeverCreatNotifyForTask(e)

                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    update();

                })
                .catch(function (error) {
                    update();
                });

        } else {
            allitemForStatus.alltask[0].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    sendSeverCreatNotifyForTask(e)
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }
    }



    return (
        <div ref={selectRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "editStatusBySelectContDark" : "editStatusBySelectContLight"}>
            <Popover
             isOpen={isOpen}
             onOpen={onOpen}
             onClose={onClose}
            >
                <PopoverTrigger>
                    <button style={{ background: bgColorBTN }}>
                        <div className='Frame625852'>
                            <div className='Frame625851'>
                                <p>{selected}</p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                        </svg>
                    </button>
                </PopoverTrigger>
                <PopoverContent padding={0} borderColor={getDarkModoe.ToggleDarkModeReducer.chekced ? "#2C3037" : "white"} >

                    <PopoverBody padding={0}>
                        <div
                            className="select-items"

                        >
                            {
                                options.map((option, index) => {
                                    if (getmyinfo.myinfo._id === allitemForStatus.creatorTasks) {
                                        return (
                                            <div key={index} onClick={() => handleUpdateStatus(option)} className='Component56'>
                                                <div className='Frame625852'>
                                                    <div className='Frame625851'>
                                                        <p>{option}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    } else {
                                        if (option == "Закрыта") {
                                            return (
                                                <div key={index} onClick={handleOptionClick} className='Component56'>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else if (option == "Отменено") {
                                            return (
                                                <div key={index} onClick={handleOptionClick} className='Component56'>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else if (option == "Выполняется") {
                                            return (
                                                <div key={index} onClick={() => handleStatusAndSendNotify("Выполняется")} className='Component56'>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else if (option == "Выполнена") {
                                            return (
                                                <div key={index} onClick={() => [handleUpdateStatus("Выполнена"), handleStatus()]} className='Component56'>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else if (option == "Новая") {
                                            return (
                                                <div key={index} onClick={() => handleUpdateStatus("Новая")} className='Component56'>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        } else if (option == "Возвращена") {
                                            return (
                                                <div key={index} className='Component56' onClick={handleOptionClick}>
                                                    <div className='Frame625852'>
                                                        <div className='Frame625851'>
                                                            <p>{option}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        }

                                    }

                                })
                            }

                        </div>
                    </PopoverBody>

                </PopoverContent>
            </Popover>
        </div>
    );
};



export default EditStatusBySelect;
