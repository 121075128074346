import {
    Menu,
    MenuButton,
    MenuList,
    Radio, RadioGroup,Input
  
  } from "@chakra-ui/react";
  import "./ozonfilter.css"

  import React, {  useState } from "react";
  import { useDispatch, useSelector } from "react-redux";

  import axios from "axios";
import { getOzonPorductList } from "../../../../redux/reducer/ozonGetProductLIst/action/action";


  
  
  export default function OZfilter({prdvalue}) {
      const [min,setMin]=useState(0)
      const [max,setMax]=useState(200)
      const [value, setValue] = React.useState('catigory')
    const dispatch = useDispatch();
  
    const getLisProduct = useSelector(getLisProduct=>getLisProduct)
    const getDarkModoe = useSelector(darkModes => darkModes )
  const sendServerPrice=(e)=>{
    e.preventDefault()

      dispatch(getOzonPorductList(`/ozon/getByPriceFilter/api?min=${min}&max=${max}`))

    axios.get('/ozon/getByPriceFilter/api', {
     params:{
      min,max
     }
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  
  const handleResset=()=>{
    dispatch(getOzonPorductList("/ozon/getProduct/api"))
  }
  
    return (
      <div className="WBfilterCont" style={{zIndex:"7"}} >
      
        <Menu>
          <div className="filter">
            <MenuButton type="button">
              <span style={{ marginRight: "10px" }}>Фильтр</span>
              <span className="material-symbols-outlined">filter_list </span>
            </MenuButton>
          </div>
          <MenuList style={{zIndex:"7"}} >
                
             
               <RadioGroup onChange={setValue} value={value} >
                    <Radio  name="catigory" value={"catigory"}  defaultChecked>Категория</Radio>
                    <Radio name="namePorduct" value={"namePorduct"} >Наименование</Radio> 
                    <Radio name="arctioculozon" value={"arctioculozon"} >Артикул</Radio>
              
                 
            </RadioGroup>
             
              <div className="wbRangeSlider">
                  <input placeholder='Минимум' type="number" value={min} onChange={(e)=> setMin(e.target.value)}/>
                  <input placeholder='Максимум' type="number" value={max} onChange={(e)=> setMax(e.target.value)}/>
              </div>
            <div style={{display:"flex"}} className="filterBTn">
              {
                getLisProduct.getOzonisListReducer.loading?
                <button className="searchbtnozonPriceLoader" disabled>Загрузка</button>
                :
                <button className="searchbtnozonPrice" onClick={sendServerPrice}>Поиск</button>
              }

              {
                getLisProduct.getOzonisListReducer.loading?
                <div className="ressetLoad" >Загрузка</div>
                :
                <div className="resset" onClick={handleResset}>Сброс</div>
              }
            


              
            </div>
          
          </MenuList>
        </Menu>
      </div>
    );
  }
  