
import CommentButton from "../commentButton/commentButton"
import "./suppliedEmoticonsLight.css"
import "./suppliedEmoticonsDark.css"
import { useDispatch, useSelector } from "react-redux"
import { perexodChatMenu } from "../../../../redux/reducer/perexodChatMenu/actiom/action"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"
import { getMessageItem } from "../../../../redux/reducer/getMessageItem/action/action"
import { getCurrentSubMessageAction } from "../../../../redux/reducer/getCurrentSubMessage/action/action"
import sockets from "../../../../socket/socket"
const socket = sockets;
export default function SuppliedEmoticons({ item, child }) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams();
  const chatID = searchParams.get('chatID');
  const thread = searchParams.get('thread_message_id');


  const getDarkModoe = useSelector(darkModes => darkModes);
  const getMyInfo = useSelector(myinfo => myinfo.getMayInfoReducer.myinfo);
  // для обновления сокета
  const [chooseWokers, setChooseWokers] = useState([]);
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);

  const handleUpdateLike = (e) => {
    console.log(e);
    
    axios.put('/updateLike/api', {
      currentLike: e,
      messageItem: item,
      child
    })
      .then(function (response) {
        axiosGetWorkers("/getCurrendCompanyWorkers/api");
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // FOR SOCKET
  // useEffect(() => {
  //   function onFooEvent() {
  //         if (!child) {
  //           dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
  //         }else{
  //           dispatch(getCurrentSubMessageAction(`/getMessageByID/api?thread=${thread}`))
  //         }


  //   }

  //   if (socket) {
  //     socket.on('updateCreatMessage', onFooEvent);

  //     return () => {
  //       socket.off('updateCreatMessage', onFooEvent);
  //     };
  //   }
  // }, [socket]);

  // получение идентификатора пользователей этого чата
  function axiosGetWorkers(url) {
    axios
      .get(url)
      .then(function (response) {
        // handle success
        setChooseWokers([]);
        if (response.data.data) {
          const newChooseWokers = response.data.data.map(item => item.workerID);
          setChooseWokers(prevWorkers => [...prevWorkers, ...newChooseWokers]);
          const adminWorkerID = gerChekcedCompany?.rootReducer?.users?.adminInfo?.workerID;
          if (adminWorkerID) {
            setChooseWokers(prevWorkers => [...prevWorkers, adminWorkerID]);
          }

          if (newChooseWokers.length > 0 || adminWorkerID) {
            socket.emit("updateCreatMessage", [...newChooseWokers, adminWorkerID]);
          } else {
            alert("No workers found");
          }
        }
      })
      .catch(function (error) {
        // handle error
        console.error("Error fetching data:", error);
      });
  }

  function thread_message(e) {
    e.stopPropagation();
    navigate(`?chatID=${chatID}&thread_message_id=${item._id}`)
    dispatch(perexodChatMenu("checkedChatItem"))
  }

  return (
    <div c className={getDarkModoe.ToggleDarkModeReducer.chekced ? "suppliedEmoticonsDarkCont" : "suppliedEmoticonsLightCont"} >
      {
        item?.subMessage?.length > 0 && <CommentButton funcClick={thread_message} item={item} />
      }
      {
        item.emojisLikes?.map((item, index) => {

          if (item.userIDForEmojy.includes(getMyInfo._id)) {
            return (
              <div key={index} className="emojyContSubComment" style={{ background: "#3B79F6" }} onClick={() => handleUpdateLike(item)}>
                <div>{item.emojisType}</div>
                <p className="isMyIDEmojy">{item?.userIDForEmojy?.length}</p>
              </div>
            )
          } else {
            return (
              <div key={index} className="emojyContSubComment" onClick={() => handleUpdateLike(item)}>
                <div>{item.emojisType}</div>
                <p >{item?.userIDForEmojy?.length}</p>
              </div>
            )
          }


        })
      }
    </div>
  )
}