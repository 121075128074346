import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { filesize } from 'filesize';
import { Tooltip } from '@chakra-ui/react'

const CommentFileEditList = ({ item, handleDeleteFile, handleDeleteLocal, index }) => {
    const [status, setStatus] = useState('Готово');
    const [isDownloading, setIsDownloading] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [isCancelled, setIsCancelled] = useState(false);


    const [progress, setProgress] = useState(0);
    const handleDownload = async (e) => {
        e.stopPropagation();

        const fileURL = `/${item.filename}`; // Adjust path as needed
        setIsDownloading(true);

        setStatus('Загрузка...');

        const cancelToken = axios.CancelToken.source();
        setCancelTokenSource(cancelToken);

        try {
            const response = await axios.get(fileURL, {
                responseType: 'blob',
                cancelToken: cancelToken.token,
                onDownloadProgress: (progressEvent) => {
                    console.log(progressEvent);

                    const percent = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percent);


                },
            });

            const fileBlob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            const url = URL.createObjectURL(fileBlob);
            link.href = url;
            link.download = item.filename || 'downloaded_file';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            if (axios.isCancel(error)) {
                setStatus('Отменено пользователем');
            } else {
                setStatus('Ошибка при загрузке');
            }
        } finally {
            setIsDownloading(false);
        }
    };

    // Обработчик отмены загрузки
    const handleCancel = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel();
        }
        setIsCancelled(true);
        setStatus('Отменено пользователем');
        setIsDownloading(false);
    };

    useEffect(() => {
        console.log(progress, "progress");

    }, [progress])


    const safeDecodeURIComponent = (str) => {
        if (str) {
            // Проверяем, не является ли строка пустой или undefined
            try {
                // Попробуйте декодировать строку


                return decodeURIComponent(str);
            } catch (e) {
                // Если возникает ошибка, выводим сообщение и возвращаем оригинальную строку
                console.error('Failed to decode URI component:', e);
                return str; // Или вернуть пустую строку, если хотите
            }
        }
        return ''; // Возвращаем пустую строку, если str равно null или undefined

    };

    // В вашем компоненте


    return (
        <>
            <div >
                <div >

                    {
                        (() => {
                            if (!isDownloading) {
                                return (
                                    <Tooltip label={
                                        item.originalname ?
                                            <p>{safeDecodeURIComponent(item.originalname)}</p>

                                            :
                                            <p>{item.name}</p>
                                    } aria-label='A tooltip'>
                                        {
                                            item.originalname ?
                                                <p>{safeDecodeURIComponent(item.originalname)}</p>

                                                :
                                                <p>{item.name}</p>
                                        }
                                    </Tooltip>
                                )
                            } else {
                                return (
                                    <Tooltip label="Hey, I'm here!" aria-label='A tooltip'>
                                        {
                                            item?.mimetype ?
                                                <p> {progress + "%"}</p>
                                                :
                                                <p>локальный файл</p>
                                        }

                                    </Tooltip>
                                )
                            }
                        })()
                    }


                    <div>
                        <p>{filesize(item.size)}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <div>
                            {
                                item?.filename ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={(e) => handleDeleteFile(index, e)}>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.875 6V3.5H15.375V6H8.875ZM8.125 7.5H16.125H21.125C21.5392 7.5 21.875 7.16421 21.875 6.75C21.875 6.33579 21.5392 6 21.125 6H16.875V3.35C16.875 2.60444 16.2706 2 15.525 2H8.725C7.97942 2 7.375 2.60442 7.375 3.35V6H3.125C2.71079 6 2.375 6.33579 2.375 6.75C2.375 7.16421 2.71079 7.5 3.125 7.5H8.125ZM5.875 10.75C5.875 10.3358 5.53921 10 5.125 10C4.71079 10 4.375 10.3358 4.375 10.75V20.15C4.375 20.8956 4.97944 21.5 5.725 21.5H18.525C19.2706 21.5 19.875 20.8956 19.875 20.15V10.75C19.875 10.3358 19.5392 10 19.125 10C18.7108 10 18.375 10.3358 18.375 10.75V20H5.875V10.75ZM10.875 10.75C10.875 10.3358 10.5392 10 10.125 10C9.71079 10 9.375 10.3358 9.375 10.75V16.75C9.375 17.1642 9.71079 17.5 10.125 17.5C10.5392 17.5 10.875 17.1642 10.875 16.75V10.75ZM14.125 10C14.5392 10 14.875 10.3358 14.875 10.75V16.75C14.875 17.1642 14.5392 17.5 14.125 17.5C13.7108 17.5 13.375 17.1642 13.375 16.75V10.75C13.375 10.3358 13.7108 10 14.125 10Z" fill="#DD5E98" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={(e) => handleDeleteLocal(item, e)}>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.875 6V3.5H15.375V6H8.875ZM8.125 7.5H16.125H21.125C21.5392 7.5 21.875 7.16421 21.875 6.75C21.875 6.33579 21.5392 6 21.125 6H16.875V3.35C16.875 2.60444 16.2706 2 15.525 2H8.725C7.97942 2 7.375 2.60442 7.375 3.35V6H3.125C2.71079 6 2.375 6.33579 2.375 6.75C2.375 7.16421 2.71079 7.5 3.125 7.5H8.125ZM5.875 10.75C5.875 10.3358 5.53921 10 5.125 10C4.71079 10 4.375 10.3358 4.375 10.75V20.15C4.375 20.8956 4.97944 21.5 5.725 21.5H18.525C19.2706 21.5 19.875 20.8956 19.875 20.15V10.75C19.875 10.3358 19.5392 10 19.125 10C18.7108 10 18.375 10.3358 18.375 10.75V20H5.875V10.75ZM10.875 10.75C10.875 10.3358 10.5392 10 10.125 10C9.71079 10 9.375 10.3358 9.375 10.75V16.75C9.375 17.1642 9.71079 17.5 10.125 17.5C10.5392 17.5 10.875 17.1642 10.875 16.75V10.75ZM14.125 10C14.5392 10 14.875 10.3358 14.875 10.75V16.75C14.875 17.1642 14.5392 17.5 14.125 17.5C13.7108 17.5 13.375 17.1642 13.375 16.75V10.75C13.375 10.3358 13.7108 10 14.125 10Z" fill="#DD5E98" />
                                    </svg>
                            }

                        </div>
                        <div >
                            {
                                isDownloading === false ? (
                                    item?.mimetype ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={handleDownload}>
                                            <path d="M6.125 20H18.125" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.125 16L8.625 12.5M12.125 4V16V4ZM12.125 16L15.625 12.5L12.125 16Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={handleDownload}>
                                            <path d="M6.125 20H18.125" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M12.125 16L8.625 12.5M12.125 4V16V4ZM12.125 16L15.625 12.5L12.125 16Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    )
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={handleCancel} className='canchelDownload'>
                                        <path d="M11.0462 12L7.65021 8.60406C7.28326 8.23711 7.28326 7.64216 7.65021 7.27521C8.01716 6.90826 8.61211 6.90826 8.97906 7.27521L12.375 10.6712L15.7709 7.27521C16.1379 6.90826 16.7328 6.90826 17.0998 7.27521C17.4667 7.64216 17.4667 8.23711 17.0998 8.60406L13.7038 12L17.0998 15.3959C17.4667 15.7629 17.4667 16.3578 17.0998 16.7248C16.7328 17.0917 16.1379 17.0917 15.7709 16.7248L12.375 13.3288L8.97906 16.7248C8.61211 17.0917 8.01716 17.0917 7.65021 16.7248C7.28326 16.3578 7.28326 15.7629 7.65021 15.3959L11.0462 12Z" fill="#353535" />
                                    </svg>
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>




            {/* Показать кнопку отмены, если загрузка началась */}

        </>
    );

};

export default CommentFileEditList;
