import { NavLink, useNavigate } from "react-router-dom"
import "./navBottomLight.css"
import "./navBottomDark.css"
import { useSelector } from "react-redux"

export default function NavBottomForMobileAndPlamshet(params) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const navigate = useNavigate();
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "navBottomDarkCont" : "navBottomLightCont"}>
            <div>
                <NavLink to={"application"}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M7.5 5.5V7C7.5 7.27614 7.27614 7.5 7 7.5H5.5C5.22386 7.5 5 7.27614 5 7V5.5C5 5.22386 5.22386 5 5.5 5H7C7.27614 5 7.5 5.22386 7.5 5.5Z" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 10H7.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.5 10V12.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 15H12.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 10.0092L10.0083 10" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 10.0092L15.0083 10" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 12.5092L10.0083 12.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 12.5092L15.0083 12.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 15.0093L15.0083 15" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 7.50926L10.0083 7.5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 5.00926L10.0083 5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M7.5 13V14.5C7.5 14.7762 7.27614 15 7 15H5.5C5.22386 15 5 14.7762 5 14.5V13C5 12.7238 5.22386 12.5 5.5 12.5H7C7.27614 12.5 7.5 12.7238 7.5 13Z" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 5.5V7C15 7.27614 14.7762 7.5 14.5 7.5H13C12.7238 7.5 12.5 7.27614 12.5 7V5.5C12.5 5.22386 12.7238 5 13 5H14.5C14.7762 5 15 5.22386 15 5.5Z" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 2.5H17.5V5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M15 17.5H17.5V15" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 2.5H2.5V5" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 17.5H2.5V15" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    <p>QR</p>
                </NavLink>
                <NavLink to={"chat"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M5 10H15" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5 6H11" stroke="#919AA1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1 18.2895V3C1 1.89543 1.89543 1 3 1H17C18.1046 1 19 1.89543 19 3V13C19 14.1046 18.1046 15 17 15H5.96125C5.35368 15 4.77906 15.2762 4.39951 15.7506L2.06852 18.6643C1.71421 19.1072 1 18.8567 1 18.2895Z" stroke="#919AA1" strokeWidth="1.5" />
                        </svg>
                    </div>
                    <p>Мессенджер</p>
                </NavLink>
                <NavLink to={"task"}>
                    <div></div>
                    <p>Задачи</p>
                </NavLink>
                <NavLink to={"/settings/AddNewUser"}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                            <path d="M8.75065 9.16732C10.3615 9.16732 11.6673 7.86148 11.6673 6.25065C11.6673 4.63982 10.3615 3.33398 8.75065 3.33398C7.13982 3.33398 5.83398 4.63982 5.83398 6.25065C5.83398 7.86148 7.13982 9.16732 8.75065 9.16732Z" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M9.62572 14.9993C11.3969 14.9993 12.8891 13.3644 11.6367 12.112V12.112C10.8709 11.3462 9.83232 10.916 8.74935 10.916C7.66638 10.916 6.62777 11.3462 5.862 12.112V12.112C4.6096 13.3644 6.10182 14.9993 7.87298 14.9993H9.62572Z" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.4173 6.83333C13.9145 6.83333 14.4034 6.70625 14.8376 6.46415C15.2719 6.22205 15.637 5.87296 15.8984 5.45005C16.1598 5.02714 16.3087 4.54443 16.331 4.04777C16.3534 3.5511 16.2484 3.05697 16.0261 2.61229C15.8037 2.16762 15.4714 1.78715 15.0607 1.50703C14.6499 1.22691 14.1744 1.05643 13.6793 1.01179C13.1841 0.967138 12.6857 1.0498 12.2315 1.25193C11.7773 1.45406 11.3823 1.76894 11.084 2.16667" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M13.9993 12.6673H14.2924C16.0635 12.6673 17.5558 11.0324 16.3034 9.77997V9.77997C15.5376 9.01419 14.499 8.58398 13.416 8.58398" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6.41602 2.16667C6.11772 1.76894 5.7227 1.45406 5.26848 1.25193C4.81426 1.0498 4.31591 0.967138 3.82075 1.01179C3.32559 1.05643 2.85007 1.22691 2.43933 1.50703C2.02859 1.78715 1.69628 2.16762 1.47394 2.61229C1.2516 3.05697 1.14662 3.5511 1.16896 4.04777C1.19131 4.54443 1.34024 5.02714 1.60162 5.45005C1.86299 5.87296 2.22813 6.22205 2.66237 6.46415C3.0966 6.70625 3.58552 6.83333 4.08268 6.83333" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.08333 8.58398C3.00037 8.58398 1.96175 9.01419 1.19598 9.77997V9.77997C-0.0564186 11.0324 1.43581 12.6673 3.20697 12.6673H3.5" stroke="#919AA1" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <p>Сотрудники</p>
                </NavLink>
                <NavLink to={"othermenu"}>
                    <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.16667 14H15.8333C16.2 14 16.5 13.7 16.5 13.3333C16.5 12.9667 16.2 12.6667 15.8333 12.6667H5.16667C4.8 12.6667 4.5 12.9667 4.5 13.3333C4.5 13.7 4.8 14 5.16667 14ZM5.16667 10.6667H15.8333C16.2 10.6667 16.5 10.3667 16.5 10C16.5 9.63333 16.2 9.33333 15.8333 9.33333H5.16667C4.8 9.33333 4.5 9.63333 4.5 10C4.5 10.3667 4.8 10.6667 5.16667 10.6667ZM4.5 6.66667C4.5 7.03333 4.8 7.33333 5.16667 7.33333H15.8333C16.2 7.33333 16.5 7.03333 16.5 6.66667C16.5 6.3 16.2 6 15.8333 6H5.16667C4.8 6 4.5 6.3 4.5 6.66667Z" fill="#585858" />
                        </svg>
                    </div>
                    <p className="otherMenuP">Еще</p>
                </NavLink>

            </div>
            <button className="taskCneter" onClick={() => navigate("/task")}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                        <path d="M15.3346 1.91602H7.66797C4.49684 1.91602 1.91797 4.49489 1.91797 7.66602V20.1243C1.91797 20.3785 2.01894 20.6223 2.19866 20.802C2.37838 20.9817 2.62214 21.0827 2.8763 21.0827H15.3346C18.5058 21.0827 21.0846 18.5038 21.0846 15.3327V7.66602C21.0846 4.49489 18.5058 1.91602 15.3346 1.91602ZM19.168 15.3327C19.168 17.4468 17.4487 19.166 15.3346 19.166H3.83464V7.66602C3.83464 5.55193 5.55389 3.83268 7.66797 3.83268H15.3346C17.4487 3.83268 19.168 5.55193 19.168 7.66602V15.3327Z" fill="white" />
                        <path d="M10.5408 13.0191L8.34336 10.8216L6.98828 12.1767L10.5408 15.7292L16.4892 9.78086L15.1341 8.42578L10.5408 13.0191Z" fill="white" />
                    </svg>
                </div>
            </button>
        </div>
    )
}