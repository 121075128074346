import { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { io } from 'socket.io-client';
import './qrCodeGereateSocketOuth.css';
import sockets from '../../../socket/socket';

const socket = sockets;

export default function QRcodeGereateSocketOuth({ isScannerActive }) {
    const [qrVal, setQrVal] = useState(null);

    useEffect(() => {
        // Функция для обработки события "getSocketID"
        const getSocketID = (id) => {
            setQrVal(id); // Сохраняем socket.id
            console.log(id, "socketid");
        };

        // Подписываемся на событие "getSocketID"
        socket.on('getSocketID', getSocketID);

        // Отправляем запрос на получение socket.id
        socket.emit('getMySocket');

        // Убираем подписку при размонтировании компонента
        return () => {
            socket.off('`getSocketID`', getSocketID);
        };
    }, [isScannerActive]); // Зависимость от isScannerActive, чтобы обновить при изменении

    useEffect(() => {
        console.log(qrVal, "qrValqrVal");
    }, [qrVal]);

    return (
        <div className="qrCodeContOuth">

            {qrVal ? (
                <div>
                    <QRCodeCanvas
                        value={qrVal}
                        size={200}
                        bgColor="#ffffff"
                        fgColor="black"
                        level="Q"
                        imageSettings={{
                            width: 50,
                            height: 50,
                            excavate: true,
                        }}
                    />
                </div>
            ) : (
                <div>
                    <p>loading...</p>
                </div>
            )}
            
        </div>
    );
}
