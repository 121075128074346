
import React from "react"
import "./changeChatNameInfoLight.css"
 function ChangeChatNameInfo({item}) {


        return (
            <div className="changeChatNameInfoContLight">
                    <div>
                            <p>{item.senderInfo.name}</p>
                            <p>Изменил название группы на</p>
                            <p>{item.messageText}</p>
                    </div>
            </div>
        )
}
export default React.memo(ChangeChatNameInfo);
