import EmojiPicker from 'emoji-picker-react';
import { useEffect, useRef, useState } from 'react';
import Emoji from "react-emoji-render";
import Dropdown from 'react-bootstrap/Dropdown';
import "./emojiPicker.css"
import "./emojiPickerDark.css"
import { useSelector } from 'react-redux';


export default function EmojiDropDownPicker({ setValue, value }) {
  const [message, setMessage] = useState('');
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const dropOpen = useRef(null);
  const dropdownRef = useRef(null);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const [isDrak, setIsDark] = useState("light");
  const collection = document.getElementsByClassName("epr-emoji-category-label");
  const search = document.querySelectorAll('[aria-label="Type to search for an emoji"]');
  const setMesasge = (e) => {

    setMessage(message => message + e.emoji)
    setValue(value => value + e.emoji)
  }


  const opeDropFunc = () => {
    setIsopen(!isOpen)

  }

  useEffect(() => {
    if (isOpen) {
      dropOpen.current.style.display = "block"
    } else {
      dropOpen.current.style.display = "none"
    }

  }, [isOpen])


  const handleOutsideClick = (event) => {

    // Close the dropdown if the click is outside of it
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsopen(false);
    }
  };

  useEffect(() => {
    // Add event listener to the document to handle outside clicks
    document.addEventListener('click', handleOutsideClick);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };


  }, []);

  for (let i = 0; i < collection.length; i++) {
    if (collection[i].innerHTML == "Frequently Used") {
        collection[i].innerHTML = "Часто используемый"
    } else if (collection[i].innerHTML == "Smileys &amp; People") {
         collection[i].innerHTML = "Смайлики и люди"

    } else if (collection[i].innerHTML == "Animals &amp; Nature") {
          collection[i].innerHTML = "Животные и природа"
    }else if (collection[i].innerHTML == "Custom Emojis") { 
      collection[i].innerHTML = "Пользовательские смайлы"
    }else if (collection[i].innerHTML == "Food &amp; Drink") { 
      collection[i].innerHTML = "Еда и напитки"
    }else if (collection[i].innerHTML == "Travel &amp; Places") { 
      collection[i].innerHTML = "Места для путешествий"
    }else if (collection[i].innerHTML == "Activities") { 
      collection[i].innerHTML = "Деятельность"
    }else if (collection[i].innerHTML == "Objects") { 
      collection[i].innerHTML = "Объекты"
    }else if (collection[i].innerHTML == "Symbols") { 
      collection[i].innerHTML = "Символы"
    }else if (collection[i].innerHTML == "Flags") { 
      collection[i].innerHTML = "Флаги"
    }
  }
  if (search.length > 0) {
    search[0].placeholder = "Поиск"
  }
 

  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownEmojiDark" : "dropdownEmojiLight"} ref={dropdownRef} >
      <div ref={dropdownRef} onClick={opeDropFunc}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M4.77456 9.23145C4.63267 8.9578 4.2958 8.85099 4.02215 8.99288C3.7485 9.13478 3.64169 9.47164 3.78358 9.74529C4.07944 10.3159 4.63027 10.9411 5.34198 11.425C6.05877 11.9124 6.97236 12.2791 8 12.2791C9.02764 12.2791 9.94123 11.9124 10.658 11.425C11.3697 10.9411 11.9206 10.3159 12.2164 9.74529C12.3583 9.47164 12.2515 9.13478 11.9779 8.99288C11.7042 8.85099 11.3673 8.9578 11.2254 9.23145C11.0252 9.61768 10.6086 10.1088 10.0304 10.5019C9.45722 10.8916 8.75841 11.1628 8 11.1628C7.24159 11.1628 6.54278 10.8916 5.96964 10.5019C5.39144 10.1088 4.97483 9.61768 4.77456 9.23145Z" fill="#858C99" />
          <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM1.11628 8C1.11628 4.19823 4.19823 1.11628 8 1.11628C11.8018 1.11628 14.8837 4.19823 14.8837 8C14.8837 11.8018 11.8018 14.8837 8 14.8837C4.19823 14.8837 1.11628 11.8018 1.11628 8Z" fill="#858C99" />
          <path d="M6.51163 5.02326C6.51163 5.43426 6.17844 5.76744 5.76744 5.76744C5.35644 5.76744 5.02326 5.43426 5.02326 5.02326C5.02326 4.61225 5.35644 4.27907 5.76744 4.27907C6.17844 4.27907 6.51163 4.61225 6.51163 5.02326Z" fill="#858C99" />
          <path d="M10.9767 5.02326C10.9767 5.43426 10.6436 5.76744 10.2326 5.76744C9.82156 5.76744 9.48837 5.43426 9.48837 5.02326C9.48837 4.61225 9.82156 4.27907 10.2326 4.27907C10.6436 4.27907 10.9767 4.61225 10.9767 5.02326Z" fill="#858C99" />
        </svg>
      </div>
      <div className='dropdownEmojiCont-content' ref={dropOpen}>
        <EmojiPicker onEmojiClick={setMesasge} emojiStyle='apple' />

      </div>

    </div>
  );
}
