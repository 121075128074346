import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import { Provider } from 'react-redux'
import store from './redux/store/store';

import { ChakraProvider } from '@chakra-ui/react'
import NoPage from './component/page/noPage/noPage';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';  // <-- Добавьте эту строку



const theme = {
  fontSizes: {
    lg: '18px',
  },
  colors: {
    gray: {
      100: '#fafafa',
      200: '#f7f7f7',
    },
  },
}



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider >
          <App />

        </ChakraProvider>

      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorkerRegistration.register();
