import React, { useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useSelector } from 'react-redux';
import icon from "../../../img/LogoU.png"; // Adjust the path based on your folder structure
import axios from 'axios';


const QRGeneratorinterior = () => {
    const [qrValue, setQrValue] = useState('');
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
    const [load, setLoading] = useState(false);

    // useEffect(() => {
    //     setQrValue(getmyinfo.myinfo._id)
    // }, [getmyinfo])


    useEffect(() => {
        const fetchQrCode = async () => {
            setLoading(true)
            if (getmyinfo?.myinfo?._id) { // Проверяем, что _id существует
                try {
                    const response = await axios.get(`/generate-qr/${getmyinfo.myinfo._id}`);
                    setQrValue(response.data.encryptedID);
                    setLoading(false)
                } catch (error) {
                    console.error('Ошибка при загрузке QR-кода:', error);
                    setLoading(false)
                }
            }
        };

        fetchQrCode();
    }, [getmyinfo]);



    return (
        <>
            <div className='qrCodeContOuth'>
                <div >
                    {
                        load ? (
                            <div>загрузка...</div> // Рендерим сообщение "loading" во время загрузки
                        ) : (
                            qrValue && (
                                <div className="qr-code-wrapper">
                                    <div>
                                        <QRCodeCanvas
                                            value={qrValue}
                                            size={200}
                                            bgColor={"#ffffff"}
                                            fgColor={"black"}
                                            level="Q"
                                            imageSettings={{
                                                src: icon, // Иконка для QR-кода
                                                x: undefined,
                                                y: undefined,
                                                height: 50,
                                                width: 50,
                                                opacity: 1,
                                                excavate: true, // Вырезает область под изображением
                                            }}
                                        />
                                    </div>

                                </div>
                            )
                        )
                    }

                </div>
            </div>
        </>
    );
};

export default QRGeneratorinterior;
