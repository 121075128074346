import { useEffect } from "react";

export default function FilterDate({handleChangeEndDate,handleChangeStartdDate,startDate,setStartDate,endDate,setEndDate}) {
     function getCurrentDateTime() {
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяцы от 0 до 11
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          
          return `${year}-${month}-${day}T${hours}:${minutes}`;
      }


      function clear(params) {
          setStartDate("")
          setEndDate("")
      }
        return (
            <div className="filterDateCont">
                   <div>
                        <p>Сроки задачи</p>
                        <p onClick={clear}>Очистить</p>
                   </div>
                   <div>
                       <label htmlFor="startDateFilter">
                              <p>Начало:</p>
                           <input type="datetime-local" value={startDate} onChange={(e)=>handleChangeStartdDate(e.target.value)} id="startDateFilter"/>
                       </label>
                       <label htmlFor="endDateFilter">
                       <p>Конец:</p>
                       <input type="datetime-local" value={endDate}  onChange={(e)=>handleChangeEndDate(e.target.value)} id="endDateFilter"/>
                       </label>
                   </div>
            </div>
        )
}