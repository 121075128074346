import { Switch,FormControl,FormLabel} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCaompanyAllAction } from "../../../../../redux/reducer/getAllCompanyForAdmin/action/action";

export default function BlockCompany({data,currentPage}) {
  const dispatch = useDispatch()

  return (
    <>
      <FormControl style={{marginBottom:"10px",marginTop:"10px"}} key={data + "1234"}>
        <FormLabel htmlFor="email-alerts" mb="0">
        Заблокировать эту компанию
        </FormLabel>
        <Switch id="email-alerts" name="blockCompany" defaultChecked={data} key={data + "1234"}/>
      </FormControl>
    </>
  );
}
