import { useEffect, useState } from 'react';

import "./loopingTask.css"
import "./loopingTaskDark.css"
import { Switch } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ru';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure
} from '@chakra-ui/react'
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
];




export default function LoopingTask({ setChecked, checked, selectDays, setselectDays, loopingStartDate, setloopingStartDate, everythreedays, seteverythreedays, creatTaskadminInfo, taskName }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const getDarkModoe = useSelector(darkModes => darkModes)
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const sendForTreeDays = (e) => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();
    const localTime = new Date();

    if (e === "everythreedays") {
      localTime.setDate(localTime.getDate() + 3);

      seteverythreedays(localTime)
    }
  }
  const convertDate = () => {
    return moment(loopingStartDate).format('D MMMM, HH:mm');
}


  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "loopingCreatTaskDark" : "loopingCreatTaskLight"}>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      <div >
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path d="M8 1.66797H13" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5 8.33203V11.6654" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.5026 18.3333C14.1845 18.3333 17.1693 15.3486 17.1693 11.6667C17.1693 7.98477 14.1845 5 10.5026 5C6.8207 5 3.83594 7.98477 3.83594 11.6667C3.83594 15.3486 6.8207 18.3333 10.5026 18.3333Z" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <p>Повтор задачи</p>


      </div>
      <div>
        <Switch size='md' isChecked={checked} onChange={() => [setChecked(check => !check), onOpen()]} />
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "loopingTaskDark" : "loopingTaskLight"}>

          <ModalBody>

            <div>
              <div>
                <p>Повтор задачи</p>
                <div onClick={onClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M10.6712 12.5L7.27521 9.10406C6.90826 8.73711 6.90826 8.14216 7.27521 7.77521C7.64216 7.40826 8.23711 7.40826 8.60406 7.77521L12 11.1712L15.3959 7.77521C15.7629 7.40826 16.3578 7.40826 16.7248 7.77521C17.0917 8.14216 17.0917 8.73711 16.7248 9.10406L13.3288 12.5L16.7248 15.8959C17.0917 16.2629 17.0917 16.8578 16.7248 17.2248C16.3578 17.5917 15.7629 17.5917 15.3959 17.2248L12 13.8288L8.60406 17.2248C8.23711 17.5917 7.64216 17.5917 7.27521 17.2248C6.90826 16.8578 6.90826 16.2629 7.27521 15.8959L10.6712 12.5Z" fill="#353535" />
                  </svg>
                </div>
              </div>


            </div>
            <div>
              <label htmlFor="">
                <p>Название задачи</p>
                <input type="text" name="" id="" value={taskName} />
              </label>
            </div>
            <div>
              <label htmlFor="">
                <p>Создатель:</p>
                <input type="text" name="" id="" value={creatTaskadminInfo?.name ? creatTaskadminInfo?.name : "Без имени"} />
              </label>
            </div>
            <div>
              <label htmlFor="">
                <p>Дата создания</p>
                <div>
                  <p>{convertDate()}</p>
                </div>
                {/* <input type="text" name="" id="" value={new Date().getMonth()} {months[new Date().getMonth()]}  /> */}
              </label>
            </div>
            <div>
              <div>
                <p>Дата создания:</p>
                <div>  <input type="datetime-local" name="datetime" value={loopingStartDate} onChange={(e) => setloopingStartDate(e.target.value)} /></div>
              </div>
              <div>
                <p>Повторения</p>
                <select name="" id="" defaultValue={selectDays} onChange={(e) => [setselectDays(e.target.value), console.log(e.target.value), sendForTreeDays(e.target.value)]}>
                  <option value="everyDay">Каждый день</option>
                  <option value="EveryWeek">Каждую неделю</option>
                  <option value="everymonth">Каждый месяц</option>
                  <option value="EveryYear">Каждый год</option>
                  <option value="everythreedays">Каждые три дня</option>
                </select>
              </div>
            </div>
            <div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                  <path d="M7.5 2.16406H12.5" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10 8.83594V12.1693" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M10.0026 18.8333C13.6845 18.8333 16.6693 15.8486 16.6693 12.1667C16.6693 8.48477 13.6845 5.5 10.0026 5.5C6.3207 5.5 3.33594 8.48477 3.33594 12.1667C3.33594 15.8486 6.3207 18.8333 10.0026 18.8333Z" stroke="#0F172A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p>Повтор задачи</p>
              </div>
              <div>
                <Switch size='md' isChecked={checked} onChange={() => setChecked(check => !check)}

                />
              </div>

            </div>
            <div>
              <button onClick={onClose}>Закрыть</button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
