import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddProduct from "../../addProduct/addproduct";
import "./wildberriesModal.css"
import "./wildberriesModalDark.css"
import { useSelector } from "react-redux"


export default function WildberriesModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showModalRef = useRef(null)
  const getDarkModoe = useSelector(darkModes => darkModes )


  useEffect(()=>{
    showModalRef.current.style.display = "none"
  },[])

const showModalWB=()=>{
  showModalRef.current.style.display = "flex"
  setShow(true)
}


const closeModalWB=()=>{
  showModalRef.current.style.display = "none"
  setShow(false)
}

  return (
    <div className="addProdcutBtnWbCont">
      <Button
        variant="primary"
        onClick={showModalWB}
        className="addProdcutBtnWb"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M12.3698 0.180176H1.62961C1.24521 0.180631 0.876676 0.333536 0.604862 0.60535C0.333047 0.877165 0.180142 1.24569 0.179688 1.6301V3.13372C0.179688 3.23342 0.219292 3.32903 0.289788 3.39952C0.360284 3.47002 0.455897 3.50962 0.555593 3.50962H0.824097V12.5851C0.824495 12.9125 0.954751 13.2264 1.18629 13.458C1.41784 13.6895 1.73176 13.8198 2.05922 13.8202H11.9402C12.2676 13.8198 12.5815 13.6895 12.8131 13.458C13.0446 13.2264 13.1749 12.9125 13.1753 12.5851V3.50962H13.4438C13.5435 3.50962 13.6391 3.47002 13.7096 3.39952C13.7801 3.32903 13.8197 3.23342 13.8197 3.13372V1.6301C13.8192 1.24569 13.6663 0.877165 13.3945 0.60535C13.1227 0.333536 12.7542 0.180631 12.3698 0.180176ZM5.22756 0.931987H8.77181V2.75781H5.22756V0.931987ZM7.9126 3.50962V6.72738L7.20826 6.25782C7.1465 6.21663 7.07393 6.19464 6.99969 6.19464C6.92545 6.19464 6.85288 6.21663 6.79111 6.25782L6.08677 6.72738V3.50962H7.9126ZM0.931499 2.75781V1.6301C0.931726 1.44502 1.00535 1.26758 1.13622 1.13671C1.26709 1.00584 1.44453 0.932214 1.62961 0.931987H4.47575V2.75781H0.931499ZM12.4235 12.5851C12.4233 12.7132 12.3723 12.836 12.2817 12.9266C12.1911 13.0172 12.0683 13.0682 11.9402 13.0684H2.05922C1.93109 13.0682 1.80825 13.0172 1.71765 12.9266C1.62705 12.836 1.57608 12.7132 1.57591 12.5851V3.50962H5.33496V7.42978C5.33496 7.49783 5.35343 7.5646 5.38839 7.62297C5.42336 7.68135 5.47352 7.72914 5.53352 7.76124C5.59351 7.79334 5.6611 7.80856 5.72907 7.80527C5.79703 7.80197 5.86283 7.78029 5.91944 7.74254L6.99969 7.02166L8.07993 7.74168C8.13648 7.77939 8.2022 7.80107 8.27009 7.8044C8.33799 7.80773 8.40551 7.79259 8.46548 7.76059C8.52545 7.72858 8.57562 7.68092 8.61065 7.62267C8.64568 7.56442 8.66426 7.49776 8.66441 7.42978V3.50962H12.4235V12.5851ZM13.0679 2.75781H9.52363V0.931987H12.3698C12.5548 0.932214 12.7323 1.00584 12.8632 1.13671C12.994 1.26758 13.0676 1.44502 13.0679 1.6301V2.75781Z" fill="white"/>
</svg>
        <span style={{ marginRight: "10px" }}> Добавить товар</span>
        {/* <span className="material-symbols-outlined">add_box</span> */}
      </Button>

      <div id="myModal" className={getDarkModoe.ToggleDarkModeReducer.chekced?"modalContWaldberisDark":"modalContWaldberis"} ref={showModalRef}>


      <div class="modal-contentWaldberis">
        <div class="modal-headerWaldberis">
          <span class="closeWaldberis" onClick={closeModalWB}>&times;</span>
          <h2>Редактировать продукт</h2>
        </div>
        <div class="modal-bodyWaldberis">
             <AddProduct />
        </div>
       
      </div>

</div>


      {/* <Modal show={show} onHide={handleClose} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>Добавить новый товар</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddProduct />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}
