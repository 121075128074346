// actions.js
// // для того чтобы открыть остальные функционал для редактирования сообщений и ответа сообщений
export const IS_EDIT_MESSAGE = 'IS_EDIT_MESSAGE';
export const REPLY_TO_MESSAGE = 'REPLY_TO_MESSAGE';
export const UPDATE_DATA = 'UPDATE_DATA';
export const ENABLED_ALL = 'ENABLED_ALL';

export const editMessage = (value) => ({
  type: IS_EDIT_MESSAGE,
  value: value
});

export const replyToMessage = (value) => ({
  type: REPLY_TO_MESSAGE,
  value: value
});

export const updateData = (value) => ({
    type: UPDATE_DATA,
    value: value
});

export const enabledAll = (value) => ({
    type: ENABLED_ALL,
    value: value
});
