import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useToast } from "@chakra-ui/react";
import {  useDispatch } from "react-redux";
import { getAdminAllAction } from "../../../../../redux/reducer/getAllAdmin/action/action";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Input
} from '@chakra-ui/react'



export default function EditMyDataAdmin({ shows, setShows, data, currentPage }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShows(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();



  useEffect(() => {
    setShow(shows);
      if (shows === true) {
        onOpen()
      }else{
        onClose()
      }
  }, [shows, setShows]);

  const editUser = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .patch("/findUpdatemyadmindataAdmin/api", {
        data: {
          name: e.target.name.value,
          tel: e.target.tel.value,
          email: e.target.email.value,
          userid: data._id,
         
        },
      })
      .then((rezult) => {
        dispatch(getAdminAllAction(`/getalladmin/api?page=${currentPage}`));
        setLoading(false);
        setShows(false);
        toast({
          position: "top",
          title: "Account created.",
          description: rezult.data.message,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        toast({
          position: "top",
          title: "Ошибка.",
          description: error.response.data.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };
  function handleCloses() {
    onClose()
    setShows(false)
  }
  return (
    <>
   
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={"auto"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Изменить мои данные</ModalHeader>
          <ModalBody>
          <div className="adminSettingsCont">
            <form onSubmit={editUser}>
              <div className="formchildCont1">
                <label htmlFor="">Имя</label>
                <Input
                  placeholder="Имя"
                  name="name"
                  defaultValue={data.name}
                  key={data.tel + "1"}
                />
                <label htmlFor="">Тел.</label>
                <Input
                  placeholder="Тел."
                  name="tel"
                  defaultValue={data.tel}
                  key={data.tel + "2"}
                  type="tel"
                />
                <div className="chekcbox" color="#5E80E4">
                  {" "}
                </div>
              </div>

              <div className="formchildCont1">
                <label htmlFor="">Электронная почта</label>
                <Input
                  placeholder="Электронная почта"
                  name="email"
                  defaultValue={data.email}
                  key={data.email + "5"}
                />

               

                <div className={"loadingbtn"} style={{marginTop:"20px"}}>
                  {loading ? (
                    <Button
                      variant="primary"
                      disabled
                    
                    >
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      загрузка...
                    </Button>
                  ) : (
                    <Button
                      colorScheme="blue"
                      type="submit"
                     
                      className="creatCompany"
                    >
                      Редактировать
                    </Button>
                  )}
                </div>
              </div>
            </form>
            {/* <button>Редактировать данные продавца</button> */}
          </div>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleCloses}>
            Закрывать
            </Button>
           
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
