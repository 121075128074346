import { Input ,Text,InputGroup,InputLeftAddon} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import "./ozonEditInput.css"
export default function OzonEditInput(item) {
    
  const [value,setValue]=useState([])
  let atributeParams =  {
    complex_id: 0,
    id: item.item.id,
    values: [
    {
      value: "Комплект защитных плёнок для X3 NFC. Темный хлопок"
    }
    ]
    }

      useEffect(()=>{
        
        if (item.item.currentinfo) {
           
            atributeParams.values[0].value = item.item.currentinfo.values[0].value
           
            setValue(
              atributeParams
            )
            
        }
       
      },[])

      const handleChange=(e)=>{
        
        atributeParams.values[0].value = e;
        setValue(atributeParams);
       
       
  }   
            if (item.item.currentinfo) {
               
                if (item.item.type == "String") {
                    return (
                        <div className='inputOzon'>
                        <label > {item.item.name}</label>
                        <input type="hidden" name="inputOzonEdit"  defaultValue={JSON.stringify(value)}/>
                        <input
                       
                          defaultValue={item.item.currentinfo.values[0].value}
                            onChange={(e)=> handleChange(e)}
                          placeholder='Here is a sample placeholder'
                          size='sm'
                        />
                      </div>
                    )
                }
                if (item.item.type == "Integer") {
                    return (
                        <div className='inputOzon'>
                        <label> {item.item.name}</label>
                        <input type="hidden" name="inputOzonEdit"  defaultValue={JSON.stringify(value)}/>
                        <input
                      
                          onChange={(e)=> handleChange(Number(e.target.value))}
                          defaultValue={Number(item.item.currentinfo.values[0].value)}
                          type={"number"}
                          placeholder={item.item.name}
                          size='sm'
                        />
                      </div>
                    )
                }
                if (item.item.type == "URL") {
                    return (
                        <div className='inputOzon'>
                            <label htmlFor="">{item.item.name}</label>
                            <input type="hidden" name="inputOzonEdit"  placeholder={item.item.name}  defaultValue={JSON.stringify(value)}/>
                            <InputGroup  >
                            <InputLeftAddon children='https://'/>
                            <Input placeholder='mysite'  defaultValue={item.item.currentinfo.values[0].value}   onChange={(e)=> handleChange(e)} />
                            <input type="hidden" name="inputOzon"  />
                           
                        </InputGroup>
                        </div>
                    )
                }
                
            }else{
                if (item.item.type == "String") {
                    return (
                        <div className='inputOzon'>
                        <label > {item.item.name}</label>
                        <input type="hidden" name="inputOzonEdit"  defaultValue={JSON.stringify(value)}/>
                        <Input
                         defaultValue={""}
                         onChange={(e)=> handleChange(e)}
                         placeholder={item.item.name}
                          size='sm'
                          
                        />
                      </div>
                    )
                }
                if (item.item.type == "Integer") {
                    return (
                        <div className='inputOzon'>
                        <label> {item.item.name}</label>
                        <input type="hidden" name="inputOzonEdit"  defaultValue={JSON.stringify(value)}/>
                        <Input
                       
                        onChange={(e)=> handleChange(Number(e.target.value))}
                         
                          type={"number"}
                          placeholder={item.item.name}
                          size='sm'
                        />
                      </div>
                    )
                }
                if (item.item.type == "URL") {
                    return (
                        <div className='inputOzon'>
                            <label htmlFor="">{item.item.name}</label>
                            <input type="hidden" name="inputOzonEdit"  defaultValue={JSON.stringify(value)}/>
                            <InputGroup  >
                            <InputLeftAddon children='https://'/>
                            <input  placeholder={item.item.name}  defaultValue={""}   onChange={(e)=> handleChange(e)} />
                            
                           
                        </InputGroup>
                        </div>
                    )
                }
            }
}