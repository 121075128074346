import { useEffect, useRef, useState } from "react"
import "./selectOzon.css"
import { Select } from '@chakra-ui/react'
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'



export default function SelectOzon(item) {
    const divref = useRef(null)
    const refinput = useRef(null)
    const contDiv = useRef(null)
    const bool = useRef(false)
  const [value,setValue] = useState([])
  const [isshow,setisshow] = useState(false)
  const [inputvalue,setinputvalue] = useState("")


  useEffect(()=>{
    setisshow(false)
      
 
  },[])





    let atributeParams =  {
        complex_id: 0,
        id: item.id,
        values: [
          {
            dictionary_value_id: "",
            value: ""
          }
        ]
      }

    const handleChange=(e)=>{
        console.log("vaxa ara");
        let prs = JSON.parse(e)
           
        atributeParams.id = item.item.id
            atributeParams.values[0].dictionary_value_id = prs.id;
            atributeParams.values[0].value = prs.value;
           
            setValue([atributeParams])
            
           
    }




    
    function filterFunction(e) {
     
      divref.current.style.display = 'block';
    let  input = document.getElementById("myInput");
     let filter = input.value.toUpperCase();
     let div = document.getElementById("myDropdown");
     let a = div.getElementsByTagName("p");


      for (let i = 0; i < a.length; i++) {
let        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
         
          a[i].style.display = "";
         
        } else {
          a[i].style.display = "none";
        }
      }
    }



   window.addEventListener("click",(e)=>{
    e.stopPropagation();
       if (e.target.id && e.target.id =="myInput" ) {
        bool.current = true
        divref.current.style.display = 'block';
       }else{
       
        bool.current = false
        divref.current.style.display = 'none';
       }
   })

const shows=(e)=>{


  e.stopPropagation();
  bool.current = !bool.current
    if (bool.current) {
      divref.current.style.display = 'block';
    }else{
      divref.current.style.display = 'none';
    }
 
 
}

const changeSelec=(e)=>{
      console.log(e);
      setinputvalue(e)
}


   return(
    <div className="selectsozon"  key={item}>
        <h1>{item.item.name}</h1>
    {/* <Select defaultValue={item.childs[0].value} onChange={handleChange} required={item.is_required}>
    {<option value={""}>Выберите {item.item.name}</option>}
        {item.childs?.map((item,index)=>{
            return  <option value={JSON.stringify(item)} key={index}>{item.value}</option>
        })}
    
   
 </Select> */}
 
 <input type="hidden" name="selectsozon" value={JSON.stringify(value)} key={JSON.stringify(atributeParams)}/>




 <div className="dropdown" >
  
         <input type="text" placeholder="Выберите" id="myInput" name="selectunput" onKeyUp={(e)=>filterFunction(e)} onClick={shows}  ref={refinput} autoComplete="off" defaultValue={inputvalue} key={"asd" + inputvalue} required={item.item.is_required} />
  
      <div id="myDropdown" className="dropdown-content"  ref={divref} >
        
      {item.childs?.map((item,index)=>{

                   return   (
                    <p  key={index} onClick={()=>[ handleChange(JSON.stringify(item)),changeSelec(item.value)]}>{item.value}</p>
                  )
          
        })}
      
      </div>
</div>

</div>
   )
}


