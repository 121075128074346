import { useDispatch, useSelector } from "react-redux";
import "./navbarTopLight.css"
import "./navbarTopDark.css"
import { toggleDarkModeAction } from "../../../redux/reducer/toggleDarkMode/action/action";
import { useEffect, useRef, useState } from "react";
import { toggleMenuAction } from "../../../redux/reducer/toggleMenu/action/action";
import { NavLink, useNavigate } from "react-router-dom";
import NotifyToggle from "../notifyToggle/notifyToggle";
import ChangeCompanyDrower from "../changeCompany/changeCompany";
import SearchToggleForTop from "./searchToggleForTop";
import NavBarForPlanshet from "./navBarTopForPLanshet";
import NavBarTopMobile from "./navBarTopMobile";


const isDarkstorage = JSON.parse(localStorage.getItem('isDark'));

export default function NavBarTop() {

     const getDarkModoe = useSelector(darkModes => darkModes)
     const dipatch = useDispatch()
     const toggleMenu = useSelector(toggleMenu => toggleMenu);
     const navigate = useNavigate();
     const [toggleSearchCont, setToggleSearchCont] = useState(false);

     const handleChangetema = () => {

          dipatch(toggleMenuAction(!toggleMenu.ToggleMenuReducer.toggleMenu))

     }

     const hanldetoggleSearch = () => {
          setToggleSearchCont(!toggleSearchCont)
     }
     return (
          <div className={getDarkModoe.ToggleDarkModeReducer.chekced?"navbarTopDarkCont":"navbarTopLightCont"}>
               {toggleSearchCont ? (
                    <SearchToggleForTop hanldetoggleSearch={hanldetoggleSearch} toggleSearchCont={toggleSearchCont} />
               ) : (
                    <>
                         <NavBarForPlanshet hanldetoggleSearch={hanldetoggleSearch} />
                         <NavBarTopMobile  hanldetoggleSearch={hanldetoggleSearch} />
                         <div className="navbarContForDekstop">
                              {/* <div className="marketplaceTopMenuCont">
                                   <div className="Frame226">
                                        <div className="menu">
                                             <NavLink to={"wildberries"}>WB</NavLink>
                                             <NavLink to={"ozon"}>OZON</NavLink>
                                             <NavLink to={"yandex"}>ЯМ</NavLink>
                                        </div>
                                   </div>
                              </div> */}
                              <label className="searchTopMenu">
                                   <input type="text" placeholder="Поиск" />
                                   <div className="searchTopMenuIconCont">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                                             <path fillRule="evenodd" clipRule="evenodd" d="M10.5 0.25C5.11522 0.25 0.75 4.61522 0.75 10C0.75 15.3848 5.11522 19.75 10.5 19.75C15.8848 19.75 20.25 15.3848 20.25 10C20.25 4.61522 15.8848 0.25 10.5 0.25ZM2.25 10C2.25 5.44365 5.94365 1.75 10.5 1.75C15.0563 1.75 18.75 5.44365 18.75 10C18.75 14.5563 15.0563 18.25 10.5 18.25C5.94365 18.25 2.25 14.5563 2.25 10Z" fill="#A8A8A8" />
                                             <path d="M19.0304 17.4698C18.7375 17.1769 18.2626 17.1769 17.9697 17.4698C17.6768 17.7626 17.6768 18.2375 17.9697 18.5304L21.9696 22.5304C22.2625 22.8233 22.7374 22.8233 23.0303 22.5304C23.3232 22.2375 23.3232 21.7626 23.0303 21.4697L19.0304 17.4698Z" fill="#A8A8A8" />
                                        </svg>
                                   </div>
                              </label>
                              <div className="rightOtherComponent">
                                   <div className="Frame229">
                                        <div className="Frame49">
                                             <div className="minimizeSearchCont">
                                                  <div className="logoForMobile"></div>
                                                  <div className="minimizeSearch">
                                                       <div onClick={hanldetoggleSearch}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                                                                 <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#353535" />
                                                                 <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#353535" />
                                                            </svg>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className="dropContTop">
                                                  <ChangeCompanyDrower />
                                             </div>
                                             <button className="addCompanyBTNTopNav" onClick={() => navigate("/sellertnewuserpage")}>
                                                  <div>
                                                       <div className="svgContBTN">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                                                 <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                                                 <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                                                            </svg>
                                                       </div>
                                                       <p>Добавить компанию</p>
                                                  </div>
                                             </button>
                                             <div className="questionMark">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                       <path d="M7.90039 8.07955C7.90039 3.30679 15.4004 3.30683 15.4004 8.07956C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                       <path d="M12 19.0101L12.01 18.999" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                  </svg>
                                             </div>
                                             <NotifyToggle />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </>
               )}

          </div>

     )
}