
import "./forMobileAndTableLight.css"
import "./forMobileAndTableDark.css"
import ForMobileAndTableDekstopList from "./forMobileAndTableDekstopList";
import { useSelector } from "react-redux";
export default function ForMobileAndTable({ getAllTaskRed, update, data, handleGetNotify, onlineUsers }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    
    return (
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "forMobileAndPlanshetContDark" : "forMobileAndPlanshetContLight"}>
            {
                getAllTaskRed.users?.map((item, index) => {


                    return (
                            <ForMobileAndTableDekstopList  getAllTaskRed={getAllTaskRed} update={update} data={data} handleGetNotify={handleGetNotify} onlineUsers={onlineUsers} item={item} index={index}/>
                    )

                })
            }

        </div>
    )
}