import axios from 'axios';

export const getOnlineUserViaRouterFunc = (url) => {
  return (dispatch) => {
      
    dispatch({ type: 'FETCH_ONLINEUSERVIAROUTER_REQUEST' });
    axios.get("/getOnlineUser/api")
      .then(response => {

        dispatch({
          type: 'FETCH_ONLINEUSERVIAROUTER_SUCCESS',
          payload: response.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'FETCH_ONLINEUSERVIAROUTER_FAILURE',
          payload: error.message
        });
      });
  };
};