import axios from "axios";
import wildbersJson  from "./setWildberis.json"
import datajson  from "./category.json"
import jsonSend from "./jsonForSend.json"
import { useEffect, useRef, useState } from "react";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import "./addproduct.css"
import Upload from "./settings/addimg/upload";
import { useToast } from '@chakra-ui/react'

let categorys = JSON.stringify(datajson)
let randomNumber;
let barkod;

export default function AddProduct(params) {
  

    const showandclose = useRef(null)
    const resetValue = useRef(null)
    const [data, setData] = useState([]);
    const [itemName, setitemName] = useState("");
    const [country, setCountry] = useState([]);
    const formChild = useRef(null)
    const [isSending,setisSending]=useState(false)
    const [islod,setislod] = useState(false)
    const [islodBtn,setislodBtn] = useState(false)
    const [showForm,setshowForm] = useState(false)
    const [loadlist,setloadList]=useState(false)
    const [header,setHeader] = useState("Здесь вы можете добавить товар")
    const toast = useToast()

    useEffect(()=>{



    },[data])
   
    
    const getUrls = (e) => {
      //Здесь мы получаем Рандомный баркод
       randomNumber = Math.floor(Math.random() * 60000000000).toString()
       barkod = Math.floor(Math.random() * 1000000000).toString()
       
       setloadList(true)
      axios
        .get(
          `/wildberis/api`,
          {
            params: {
              name: e.target.value,
            },
           
          }
        )
        .then((resp) => {  
          setData(resp.data.rezultss.data);

          setCountry(resp.data.count.data)
          setislodBtn(true)
          setshowForm(true)
          setloadList(false)
        }).catch(error=>{
        
          setloadList(false)
          setHeader(error.response.data.message)
        }).finally(fin=>{
          setloadList(false)
        })
    };
  
    const sendServer=(e)=>{
      //Здесь мы получаем данные из полей и записываем в файл jsonForSend для отправления Wildberries
    setislod(true)
      e.preventDefault()
      jsonSend[0][0].characteristics =[]
        data.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          jsonSend[0][0].characteristics.push({"Предмет": `${itemName}`})

          for (let i = 0; i < data.length; i++) {

               
            if ( data[i].maxCount > 0) {
              
                
                    if (data[i].charcType == 0 || data[i].charcType ==1) {
                         let x =  e.target[i].value.split(',')
                       
                            
                            jsonSend[0][0].characteristics.push(JSON.parse(`{"${data[i].name}": ${JSON.stringify(x)}${data[i].unitName}}`))
                         
                       
                    }else{
                    let x =  e.target[i].value.split(',')
                        
                        jsonSend[0][0].characteristics.push(JSON.parse(`{"${data[i].name}": ${JSON.stringify(x)}}`))
                     
                    }
                   
                   
            }else{
                if (data[i].charcType == 0 || data[i].charcType ==1) {
                    jsonSend[0][0].characteristics.push(JSON.parse(`{"${data[i].name}": "${e.target[i].value}${data[i].unitName}"}`))
                }else{
                    jsonSend[0][0].characteristics.push(JSON.parse(`{"${data[i].name}":${e.target[i].value}}`))
                }
                    // JSON.parse(`{"${data[i].name}": ["${e.target[i].value}${data[i].unitName}"]}`)
                   
                  
            }
           
        
               
    }
    jsonSend[0][0].vendorCode = randomNumber;
    jsonSend[0][0].sizes[0].skus = [barkod]
  
    setisSending(true)
//здесь мы отправляем уже файл
    axios.post('/wildberis/api', {
        jsonSend,
      
      })
      .then(function (response) {

        setisSending(true)
      })
      .catch(function (error) {
          if (error) {
            setisSending(false)
            
          }
          toast({
            position:"top",
            title: 'Ошибка',
            description:error.response.data.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
          setisSending(false)
      })
  

    }
   
  //Здесь через цикла определяем полей
    return (
      <div className="WildberisCont">
            <div  className="WildberisformCont" ref={showandclose}>
            <div className="selecInpt">
                <label>
                <input list="data" name="data" ref={resetValue} onClick={()=> resetValue.current.value = ""}  onChange={(E)=> [getUrls(E),setitemName(E.target.value)]} className="searchWildbersiProduct" placeholder="Выбрать товар"/>
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
  <path d="M18.6555 38.3935H8.51381C8.17759 38.3935 7.85514 38.5271 7.6174 38.7648C7.37966 39.0025 7.24609 39.325 7.24609 39.6612C7.24609 39.9974 7.37966 40.3199 7.6174 40.5576C7.85514 40.7954 8.17759 40.9289 8.51381 40.9289H18.6555C18.9918 40.9289 19.3142 40.7954 19.552 40.5576C19.7897 40.3199 19.9233 39.9974 19.9233 39.6612C19.9233 39.325 19.7897 39.0025 19.552 38.7648C19.3142 38.5271 18.9918 38.3935 18.6555 38.3935ZM18.6555 33.3226H8.51381C8.17759 33.3226 7.85514 33.4562 7.6174 33.6939C7.37966 33.9317 7.24609 34.2541 7.24609 34.5903C7.24609 34.9266 7.37966 35.249 7.6174 35.4868C7.85514 35.7245 8.17759 35.8581 8.51381 35.8581H18.6555C18.9918 35.8581 19.3142 35.7245 19.552 35.4868C19.7897 35.249 19.9233 34.9266 19.9233 34.5903C19.9233 34.2541 19.7897 33.9317 19.552 33.6939C19.3142 33.4562 18.9918 33.3226 18.6555 33.3226Z" fill="white"/>
  <path d="M41.1102 0H4.88976C3.59339 0.00153372 2.35055 0.517196 1.43387 1.43387C0.517196 2.35055 0.00153372 3.59339 0 4.88976V9.96063C0 10.2968 0.133563 10.6193 0.371306 10.857C0.609049 11.0948 0.931497 11.2283 1.26772 11.2283H2.17323V41.8346C2.17457 42.939 2.61385 43.9977 3.39471 44.7785C4.17558 45.5594 5.23427 45.9987 6.33858 46H39.6614C40.7657 45.9987 41.8244 45.5594 42.6053 44.7785C43.3862 43.9977 43.8254 42.939 43.8268 41.8346V11.2283H44.7323C45.0685 11.2283 45.391 11.0948 45.6287 10.857C45.8664 10.6193 46 10.2968 46 9.96063V4.88976C45.9985 3.59339 45.4828 2.35055 44.5661 1.43387C43.6495 0.517196 42.4066 0.00153372 41.1102 0ZM17.0236 2.53543H28.9764V8.69291H17.0236V2.53543ZM26.0787 11.2283V22.08L23.7034 20.4964C23.4951 20.3575 23.2504 20.2834 23 20.2834C22.7496 20.2834 22.5049 20.3575 22.2966 20.4964L19.9213 22.08V11.2283H26.0787ZM2.53543 8.69291V4.88976C2.5362 4.26559 2.78449 3.6672 3.22585 3.22585C3.6672 2.78449 4.26559 2.5362 4.88976 2.53543H14.4882V8.69291H2.53543ZM41.2913 41.8346C41.2908 42.2668 41.1189 42.681 40.8133 42.9865C40.5078 43.2921 40.0935 43.464 39.6614 43.4646H6.33858C5.90648 43.464 5.49223 43.2921 5.18669 42.9865C4.88114 42.681 4.70924 42.2668 4.70866 41.8346V11.2283H17.3858V24.4488C17.3858 24.6783 17.4481 24.9035 17.566 25.1003C17.6839 25.2972 17.8531 25.4584 18.0554 25.5666C18.2578 25.6749 18.4857 25.7262 18.7149 25.7151C18.9441 25.704 19.166 25.6309 19.3569 25.5036L23 23.0724L26.6431 25.5007C26.8338 25.6279 27.0554 25.701 27.2844 25.7122C27.5133 25.7234 27.741 25.6724 27.9433 25.5644C28.1455 25.4565 28.3147 25.2958 28.4329 25.0993C28.551 24.9029 28.6137 24.6781 28.6142 24.4488V11.2283H41.2913V41.8346ZM43.4646 8.69291H31.5118V2.53543H41.1102C41.7344 2.5362 42.3328 2.78449 42.7742 3.22585C43.2155 3.6672 43.4638 4.26559 43.4646 4.88976V8.69291Z" fill="white"/>
</svg>
                </label>
               
                <datalist id="data">
                
                    {datajson.data.map((op,index)=>{     
                        return (
                            <option key={index}>{op.objectName}</option>
                        )
                    })}
                </datalist>
              
    
            </div>
            
            {showForm?
              <form onSubmit={sendServer} ref={formChild}>
              {data &&
              data.sort(function (a, b) {
                  if (a.name < b.name) {
                  return -1;
                  }
                  if (a.name > b.name) {
                  return 1;
                  }
                  return 0;
              }).map((item,index)=>{
                console.log(item,"item");
  
                  if (data[index].charcType == 0 || data[index].charcType ==1) {
                            if (item.name ==="Страна производства") {
                                    return(
                                      <div key={index} className="paramsCont">
                          
                                      <label >{item.name} </label>
                                      
                                          <select name="" id="" required={item.required}>
                                         
                                            {country.map((country,countryIndex)=>
                                            
                                            <option key={countryIndex} value={country.name}>{country.name}</option>
                                            
                                            )}
                                              
                                          </select>
                                      </div>
                                    )
                            }else{
                              return(
                                <div key={index} className="paramsCont">
                                
                            <label >{item.name} </label>
                                <input type="text" name={item.name} placeholder={`${item.name} 0/${item.maxCount}`} required={item.required} defaultValue={item.name} />
                                
                            </div>
                            )
                            }
                    
                  }else{
                      return(
                          <div key={index} className="paramsCont">
                          
                      <label >{item.name} </label>
                          <input  type="number" name={item.name}  min="1" max="1000" placeholder={`${item.name} 0/${item.maxCount}`} required={item.required} defaultValue={20} />
                      </div>
                      )
                      
                  }
                 
              })
              
              }
              {/* это компонент для загрузки изображений */}
                <Upload setislod={setislod} setisSending={setisSending} isSending={isSending} vendor = {jsonSend[0][0].vendorCode}/>
              
                {islod?
                 <Button variant="primary" disabled>
                 <Spinner
                   as="span"
                   animation="border"
                   size="sm"
                   role="status"
                   aria-hidden="true"
                 />
                 <span className="visually-hidden">Loading...</span>
               </Button>
                : <button type="submit">создать товар</button>}
                {/* <button type="submit">send</button> */}
               
                   <div>
                 
                   </div>
              
             
              </form>
            :
              <div>
               <h1>{loadlist?
               <Spinner animation="border" role="status">
               <span className="visually-hidden">Loading...</span>
             </Spinner>
               :header}</h1>
              </div>
            }
          
            </div>
           
      </div>
    );
}