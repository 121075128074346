import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { useToast } from '@chakra-ui/react'
import axios from "axios"

export default function OzonEditInput({item,nameis,allitem}) {

    const [inpvalue,setinpvalue] = useState("")
    const [show,setShow] = useState(false)
        const inpRef = useRef(null)
        const inpRefColor = useRef(null)
        const toast = useToast()

        const dispatch = useDispatch()
    useEffect(()=>{
        inpRef.current.style.visibility = "hidden";
        inpRefColor.current.style.color="#5E88D9"
        inpRefColor.current.style.outline="1px solid white"
        setinpvalue(item)
       
    },[])

    const handleShow=()=>{
        inpRef.current.style.visibility = "visible";
        inpRefColor.current.style.color="#DB5F95"
        inpRefColor.current.style.outline="1px solid #DB5F95"
       
    }


    const handleClose=()=>{
        inpRef.current.style.visibility = "hidden";
        inpRefColor.current.style.color="#5E88D9"
        inpRefColor.current.style.outline="1px solid white"
    }


    const handleSendServer=()=>{
           if (nameis == "productName") {
            allitem.attributs.name = inpvalue
            allitem.attributs.price = allitem.price.toString()
            allitem.attributs. offer_id = allitem.attributs.offer_id

            
                
                            axios.post('/ozon/updateProductName/api', {
                                attributs:allitem.attributs
                              })
                              .then(function (response) {
                                console.log(response);
                                toast({
                                  position:"top",
                                  title: response.data.message,
                                  description: "",
                                  status: 'success',
                                  duration: 2000,
                                  isClosable: true,
                                })
                                handleClose()
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
// price=================================================================================
           }else if (nameis == "pirce") {
            allitem.attributs.name =  allitem.attributs.name
            allitem.attributs.price = inpvalue.toString()
            allitem.attributs. offer_id = allitem.attributs.offer_id

            
                
                            axios.post('/ozon/updateProductName/api', {
                                attributs:allitem.attributs
                              })
                              .then(function (response) {
                                console.log(response);
                                toast({
                                  position:"top",
                                  title: response.data.message,
                                  description: "",
                                  status: 'success',
                                  duration: 2000,
                                  isClosable: true,
                                })
                                handleClose()
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
                   
                       
           }
           else if (nameis == "arcticule") {
            allitem.attributs.name =  allitem.attributs.name
            allitem.attributs.price = allitem.attributs.price.toString()
            allitem.attributs. offer_id = inpvalue
            
                
                            axios.post('/ozon/updateProductName/api', {
                                attributs:allitem.attributs
                              })
                              .then(function (response) {
                                console.log(response);
                                toast({
                                  position:"top",
                                  title: response.data.message,
                                  description: "",
                                  status: 'success',
                                  duration: 2000,
                                  isClosable: true,
                                })
                                handleClose()
                              })
                              .catch(function (error) {
                                console.log(error);
                              });
           }
    }

            return (
                <div className="ContINput" >
                <textarea  defaultValue={item} onClick={()=> handleShow()} ref={inpRefColor} onChange={(e)=> setinpvalue(e.target.value)} key={item + "132456465"} />
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}} ref={inpRef}>
                <span className="material-symbols-outlined spaiShow" onClick={handleSendServer}>check</span>
                <span className="material-symbols-outlined spaiClose" onClick={handleClose} >close</span>
                </div>
            </div>
            )
}