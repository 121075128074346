import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import "./ModalForEditChatImageLight.css"
import "./ModalForEditChatImageDark.css"
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import sockets from '../../../../socket/socket';
const socket = sockets;
export default function ModalForEditChatImage() {
    const [files, setFiles] = useState(null);
    const [load, setLoad] = useState(false);
    const modalRef = useRef(null);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
    const toast = useToast();

    const handleShow = () => {
        setFiles(null)
        modalRef.current.style.display = "flex"
    }
    const handleCloseCross = (event) => {
        if (event.target.className == "modal-ModalForEditChatImage") {
            setFiles(null)

            modalRef.current.style.display = "none"

        }

    }
    const handleClose = () => {

    
        setFiles(null)
        modalRef.current.style.display = "none"

    }
    const handleChange = (e) => {
        setFiles(e.target.files[0])
    }

    const handleChangeSendServer = () => {
        if (files) {
            setLoad(true)
            const formData = new FormData();
            formData.append('image', files);
            formData.append('chat', getChatInfo.data._id);
            axios.post('/updateChatimage/api', formData)
                .then(function (response) {
                    setLoad(false)
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 1000,
                        isClosable: true,
                    })
                    let workers=[...getChatInfo.data?.chatOptions.chatParticipantsID]
                    socket.emit("updateChatSocketWorkers",workers);
                    handleClose();
                })
                .catch(function (error) {
                    console.log(error);
                    setLoad(false)
                }).finally(Finf=>{
                    setLoad(true)
                })
        } else {
            handleClose()
        }

    }



    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "ModalForEditChatImageContDark" : "ModalForEditChatImageContLight"}>

            <div className='imgCont' onClick={handleShow}>
                <Avatar name={getChatInfo.data?.chatOptions?.chatName} src={`/${getChatInfo.data?.chatOptions?.chatImg}`} />
                <div className='editpencil'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M11.0237 1.72199L11.1137 1.77399C11.8918 2.2232 12.5407 2.59779 13.0158 2.96443C13.5173 3.35143 13.9153 3.79562 14.082 4.4178C14.2487 5.03999 14.1261 5.62369 13.8853 6.20957C13.6572 6.76465 13.2826 7.41348 12.8333 8.19157L8.95779 14.9043C8.66085 15.4196 8.42606 15.827 8.0687 16.1341C7.71134 16.4411 7.27319 16.6119 6.71906 16.8279L6.56932 16.8863C5.5411 17.2881 4.69355 17.6192 3.99949 17.7814C3.2736 17.951 2.57274 17.9721 1.91947 17.5949C1.2662 17.2177 0.93404 16.6002 0.717969 15.8868C0.51137 15.2046 0.374389 14.3051 0.208204 13.2137L0.183946 13.0548C0.0939171 12.4669 0.0227298 12.0021 0.109964 11.5391C0.120869 11.4812 0.134089 11.4242 0.149458 11.3678C0.257039 10.9726 0.469884 10.6047 0.730447 10.1542L4.60606 3.44157C5.05528 2.66345 5.42987 2.0146 5.79651 1.53949C6.1835 1.038 6.6277 0.639974 7.24988 0.473261C7.87207 0.306547 8.45576 0.429152 9.04165 0.669953C9.59673 0.89809 10.2456 1.27272 11.0237 1.72199ZM5.5041 4.88613L11.1333 8.13613L7.70983 14.0657C7.33687 14.7117 7.23023 14.8769 7.09115 14.9964C6.95206 15.1159 6.77271 15.1964 6.07794 15.4679C4.98147 15.8962 4.2347 16.186 3.65823 16.3207C3.10135 16.4508 2.84866 16.3993 2.66947 16.2959C2.49027 16.1924 2.31933 15.9993 2.15357 15.452C1.98198 14.8854 1.85954 14.0938 1.6823 12.93C1.56999 12.1926 1.55008 11.997 1.58403 11.8168C1.61798 11.6366 1.70771 11.4617 2.08067 10.8157L5.5041 4.88613ZM12.498 5.63936C12.3654 5.96179 12.1628 6.34092 11.8817 6.83618L6.25567 3.588C6.54406 3.09696 6.77105 2.73188 6.98403 2.45589C7.26849 2.08728 7.4601 1.96985 7.63811 1.92215C7.81613 1.87445 8.04078 1.88034 8.47143 2.05734C8.92223 2.24262 9.48383 2.56502 10.3187 3.04703C11.1535 3.52903 11.7136 3.85419 12.0994 4.15195C12.468 4.43641 12.5854 4.62802 12.6331 4.80603C12.6808 4.98405 12.675 5.2087 12.498 5.63936Z" fill="#FAFAFA" />
                        <path d="M9.66064 17.2503C9.66064 16.8361 9.99643 16.5003 10.4106 16.5003H16.4106C16.8249 16.5003 17.1606 16.8361 17.1606 17.2503C17.1606 17.6645 16.8249 18.0003 16.4106 18.0003H10.4106C9.99643 18.0003 9.66064 17.6645 9.66064 17.2503Z" fill="#FAFAFA" />
                    </svg>
                </div>
            </div>
            {/* modal====================================================================================================================== */}
            <div className="modal-ModalForEditChatImage" ref={modalRef} onClick={(e) => handleCloseCross(e)}>
                <div className="modal-ModalForEditChatImagecontent">
                    <div>
                        <div>
                            <p>Изменить изображение</p>
                            <div onClick={handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#64748B" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label htmlFor="uploadChatImageEdit" className='label1'>
                                {
                                    (() => {
                                        if (files == null && !getChatInfo.data?.chatOptions?.chatImg) {
                                            return <Avatar size='2xl' name={getChatInfo.data?.chatOptions?.chatName} />;
                                        } else if (files && !getChatInfo.data?.chatOptions?.chatImg) {
                                            return <Avatar size='2xl' name={getChatInfo.data?.chatOptions?.chatName} src={URL.createObjectURL(files)} />;
                                        } else if (getChatInfo.data?.chatOptions?.chatImg) {
                                            return <Avatar size='2xl' name={getChatInfo.data?.chatOptions?.chatName} src={files ? URL.createObjectURL(files) : `/${getChatInfo.data?.chatOptions?.chatImg}`} />;
                                        } else {
                                            return null; // Возвращаем null, если нет условий
                                        }
                                    })()
                                }



                            </label>
                            <input type="file" name="image" id="uploadChatImageEdit" placeholder="asd" onChange={handleChange} />
                            <label htmlFor="uploadChatImageEdit" className='label2'>
                                <p>Загрузить новое изображение</p>
                            </label>
                        </div>
                    </div>
                    <div>
                        <button onClick={handleClose}>Отменить</button>
                        <button onClick={handleChangeSendServer} disabled={load}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="13" viewBox="0 0 18 13" fill="none">
                                    <path d="M17.5762 1.48016C17.8414 1.16195 17.7984 0.689026 17.4802 0.423853C17.1619 0.15868 16.689 0.201673 16.4239 0.519881L11.0332 6.98869C9.95034 8.2881 9.18861 9.19936 8.52791 9.79597C7.88262 10.3787 7.43728 10.5639 7.00002 10.5639C6.56276 10.5639 6.11742 10.3787 5.47213 9.79597C4.81143 9.19935 4.04969 8.28809 2.96686 6.98869L1.57618 5.31988C1.31101 5.00167 0.838088 4.95868 0.519881 5.22385C0.201673 5.48903 0.15868 5.96195 0.423853 6.28016L1.85312 7.99528C2.88839 9.23764 3.71748 10.2326 4.46684 10.9092C5.24089 11.6082 6.03216 12.0639 7.00002 12.0639C7.96788 12.0639 8.75914 11.6082 9.5332 10.9092C10.2826 10.2326 11.1116 9.23766 12.1469 7.99532L17.5762 1.48016Z" fill="#FAFAFA" />
                                </svg>
                            </div>
                            Соханить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}