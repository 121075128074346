
  


  export const  toggleMenuAction=(a)=>{
       
    return{
        type:"SET_TOGGLE_MENU_USER",
        value:a
    }
}