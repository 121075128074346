import { useDispatch, useSelector } from 'react-redux'

import "./wbTable.css"
import "./wbTableDark.css"
import { useEffect, useRef, useState } from 'react';

import { getWaldberisListAction } from '../../../../redux/reducer/getwildberrieslIST/action/action';
import { Spinner } from '@chakra-ui/react'

import EditInputWB from '../edidInput/edidInput';
import EditModalwb from '../wbeditmodal/wbeditmodal';
import { useToast } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'

export default function WBtable() {
  const toast = useToast()

  const dispatch = useDispatch()
  
  const getLisProduct = useSelector(getLisProduct=>getLisProduct)
    const allList = getLisProduct.getWaldberisListReducer.wblist
    const ladouding = getLisProduct.getWaldberisListReducer
    const [data,setData] = useState([])
    const [name,setname] = useState([])
    const [catigory,setcatigory] = useState([])
    const getDarkModoe = useSelector(darkModes => darkModes )

    useEffect(()=>{
      dispatch(getWaldberisListAction("/wildberis/getprodcutlist/api"))
          if (getLisProduct.getWaldberisListReducer.error) {
            toast({
              position:"top",
              title: 'Ошибка',
              description: getLisProduct.getWaldberisListReducer.error,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          }
    },[dispatch])



const handleEditModal=(e)=>{
      console.log(e);
}


return(
<div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"wbTableDark":"wbTable"}>
  {ladouding.loading?
<div className='loadingContwb'>
<div className="custom-loader"></div>
</div>
  :
  <div className="containerwbTable">
        <table id="customers" className='customers' style={{borderRadius:"10px"}}>
          {/* for Dekstop */}
        <thead className='ForDekstopWbTheadTr'>
        <tr >
          
            <th>Индекс</th>
            <th>Аватар</th>
            <th>Категория</th>
            <th>Цена</th>
            <th>Артикул продавца</th>
            <th>Скидка</th>
            <th>Артикул WB</th>
            <th>Баркод товара</th>
            <th>Редактировать</th>
            
        
          </tr>
        </thead>
        
          <tbody className='ForDekstopWbTBodyTr'>
            {allList.getCompany&&allList.getCompany.product?.map((item,index)=>{
       
                return (
                  <tr key={index} >
                    <td className='index'>{index + 1}</td>
                    <td ><img src={item.alllistss.mediaFiles[0]} alt=""   className='tableimg'/></td>
                    <td className='category' >{<EditInputWB itemVal={item.alllistss.object} name={"object"} vendorCodes={item.alllistss.vendorCode}  key={index}/>}</td>
                    {/* <td className='category' >{item.alllistss.object}</td> */}
                    <td  ><EditInputWB itemVal={item.price.price}  name={"price"}  vendorCodes={item.alllistss.vendorCode} nmID={item.alllistss.nmID}  key={index}/></td>
                    <td ><EditInputWB itemVal={item.alllistss.vendorCode}   name={"vendorCode"}  vendorCodes={item.alllistss.vendorCode}  key={index}/></td>
                    <td  ><EditInputWB itemVal={item.price.discount}  name={"skidka"}  vendorCodes={item.alllistss.vendorCode} nmID={item.alllistss.nmID}  key={index}/></td>
                    <td  >
                          <div className='ContINput' >
                              <textarea name="" id="" >
                            {item.alllistss.nmID}
                            </textarea>
                            <div style={{height:"30px"}}></div>
                          </div>
                    </td>
                
                    <td ><EditInputWB itemVal={item.alllistss.sizes[0].skus}  name={"skus"} vendorCodes={item.alllistss.vendorCode}  key={index}/></td>
                  <td className='edit'><EditModalwb  vendorCodes={item.alllistss.vendorCode} price={item.price.price} nmID={item.alllistss.nmID} /></td>
            </tr>
                )
            })}  
          </tbody>


          {/* formobile */}

        </table>
        <TableContainer className='MobileContWb'>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Индекс</Th>
              <Th>Аватар</Th>
              <Th >Категория</Th>
              <Th >Редактировать</Th>
            </Tr>
          </Thead>
          <Tbody>
            
          {allList.getCompany&&allList.getCompany.product?.map((item,index)=>{
          
          return (
          <Tr key={index} >
            <Td className='index'>{index + 1}</Td>
            <Td ><img src={item.alllistss.mediaFiles[0]} alt=""   className='tableimg'/></Td>

            <Td className='category' >{item.alllistss.object}</Td>
            
          
            
            <Td className='edit'><EditModalwb  vendorCodes={item.alllistss.vendorCode} price={item.price.price} nmID={item.alllistss.nmID} /></Td>
      </Tr>
          )
      })}  
            
          
          </Tbody>
        </Table>
      </TableContainer>
        
      </div>
  }

</div>
);
}