import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Avatar,
    AvatarBadge,
    AvatarGroup
} from '@chakra-ui/react'
import { useEffect } from 'react';
import "./whoReadThisCommentLight.css"
import moment from 'moment';


export default function WhoReadThisComment({ item }) {
    const { onOpen, onClose, isOpen } = useDisclosure();
    useEffect(() => {
        console.log(`%c ${item}`, 'background: #222; color: #bada55', item);
    }, [])
    return (
        <>

            <AvatarGroup size='sm' max={3} onClick={onOpen}>
                {
                    item?.whoReadThisComment?.map((items, index) => {
                        if (items.img) {
                            return <Avatar key={index} name='Ryan Florence' src={`/${items.img}`} />
                        } else {
                            return <Avatar key={index} src='https://bit.ly/broken-link' />
                        }

                    })
                }


            </AvatarGroup>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className='whoReadThisMessageModalContLight'>
                    <ModalHeader>Кто прочитал это сообщение</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='whoReadThisMessageContScroll'>
                            {
                                item?.whoReadThisComment?.map((items, index) => {
                                    if (items.img) {
                                        return (
                                            <div >
                                                <div>
                                                    <Avatar size='sm' key={index} name='Ryan Florence' src={`/${items.img}`} />
                                                    <div>
                                                        <p>{items.name}</p>
                                                        <p>{moment(items.date).subtract(10, 'days').calendar()}</p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <div>
                                                    <Avatar size='sm' key={index} src='https://bit.ly/broken-link' />
                                                    <div>
                                                        <p>{items.name}</p>
                                                        <p>{moment(items.date).subtract(10, 'days').calendar()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                })
                            }
                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}