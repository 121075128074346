import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
} from '@chakra-ui/react'

import QRScannerOuth from '../qrScannerOuth/qrsCannerOuth';
import QRcodeGereateSocketOuth from '../qrCodeGereateSocketOuth/qrCodeGereateSocketOuth';
import "./lendingQrScannModalBTN.css"
import { useState, useEffect, useMemo } from 'react';


export default function LendingQrScannModalBTN(params) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isScannerActive, setIsScannerActive] = useState(false);



    const toggleQR = () => {

        setIsScannerActive(false);
    };

    const toggleScann = () => {
        setIsScannerActive(true);
    };

    const openModal = () => {
        onOpen();
    };

    return (
        <>
            <button onClick={openModal} className='scannerBTN'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M11 11.0732H7V7.07324H11V11.0732ZM17 7.07324H15V9.07324H17V7.07324ZM13 17.0732H17V13.0732H13V17.0732ZM7 17.0732H9V15.0732H7V17.0732Z" stroke="#3B79F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 3.07324H4C3.73478 3.07324 3.48043 3.1786 3.29289 3.36614C3.10536 3.55367 3 3.80803 3 4.07324V8.07324" stroke="#3B79F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21 8.07324V4.07324C21 3.80803 20.8946 3.55367 20.7071 3.36614C20.5196 3.1786 20.2652 3.07324 20 3.07324H16" stroke="#3B79F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3 16.0732V20.0732C3 20.3385 3.10536 20.5928 3.29289 20.7803C3.48043 20.9679 3.73478 21.0732 4 21.0732H8" stroke="#3B79F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16 21.0732H20C20.2652 21.0732 20.5196 20.9679 20.7071 20.7803C20.8946 20.5928 21 20.3385 21 20.0732V16.0732" stroke="#3B79F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className='lendingQrScannModalBTNCont'>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className='modalheader'>
                            <h1>
                                {
                                    isScannerActive?
                                    `Остсканируйте , QR-код в разделе "приложение" для авторизации`
                                    :
                                    "Ваш QR-код для авторизации"
                                }
                               
                                </h1>
                           
                        </div>
                        <div className='modalToggelBTNCont'>
                            <button className={isScannerActive ? "" : "active"} onClick={toggleQR}>QR-код</button>
                            <button className={isScannerActive ? "active" : ""} onClick={toggleScann}>Сканировать</button>
                        </div>
                        <div className='modalToggleCont'>
                            {isScannerActive ? <QRScannerOuth /> : <QRcodeGereateSocketOuth isScannerActive={isScannerActive}/> }
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}



    