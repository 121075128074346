import { useEffect, useState, useTransition } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddNewUserTable from "./addNewUserTable/addNewUserTable";
import "./addNewUser.css";
import "./addNewUserDark.css";
import axios from "axios";
import "react-phone-number-input/style.css";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action";
import AddemployeeplusLigth from "../../../nav/addemployeeplus/addemployeeplusLigth";
import sockets from "../../../../socket/socket";
import { Helmet } from 'react-helmet';


export default function AddNewUser() {
  const getDarkModoe = useSelector(darkModes => darkModes)
  const socket = sockets;
  const dipatch = useDispatch()
  const [number, setNumber] = useState(null);
  const [show, setShow] = useState(false);
  const [load, setload] = useState(false);
  const [rols, setrols] = useState("");
  const [workers, setworkers] = useState([])
  const getDefaulyCheckedSession = useSelector(company => company.checkedSessionReducer)
  const handleShow = () => setShow(true);
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition();

  useEffect(() => {
    dipatch(fetchUsers())
    dipatch(getMyCompany("/mycopany/getallcompany"))
    setworkers(gerChekcedCompany.rootReducer.users.data)
  }, [dipatch, getDefaulyCheckedSession])

  const searchPerfomer = (e) => {
    startTransition(() => {
      dispatch(fetchUsers(e))
    });

  }



  useEffect(() => {
    // SOCKET UPDATE IN CLIENT
    socket.on('updateemployee', (users) => {
      dipatch(fetchUsers())
    });



    return () => {
      socket.off('updateemployee');
      socket.off('disconnect');


    };
  }, [])
  //---------------------------------------------------------------------------------------------------
  //эта функция добавляет нового сотрудника
  const addemployee = async () => {
    let replcaePhone = number.replace(/\+/g, '');
    console.log(rols, "rols");
    if (number && rols !== "Контент менеджер") {
      dipatch(fetchUsers())
      if (number && rols) {
        dipatch(fetchUsers())
        //здесь мы проверяем тип поля, который должен быть числом
        if (!number) return

        //токен для авторизации
        const token = await localStorage.getItem("token");
        setload(true)

        axios
          .post("/addemploye/api", {
            number: replcaePhone,
            rols
          })
          .then(function (response) {
            console.log(response);
            swal(response.data.message, response.data.message, "success");
            setShow(false);
            setNumber("")
            dipatch(fetchUsers())
            dipatch(getMyCompany("/mycopany/getallcompany"))
            setworkers(gerChekcedCompany.rootReducer.users.data)
          })
          .catch(function (error) {
            setload(false)
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }).finally(rezult => {
            setload(false)
            dipatch(fetchUsers())
          })
      } else {
        toast.error("вы не набрали номер сотрудника или не выбрали роль для сотрудника", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }


    // redux


  };
  useEffect(() => {
    console.log(number);
  }, [number])



  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "employeesContDark" : "employeesContLight"}>
      <Helmet>
        <title>Управление сотрудниками — Добавляйте и удаляйте пользователей</title>
        <meta name="description" content="Простой способ добавить или удалить сотрудников в таск-трекере. Настраивайте роли и распределяйте задачи для эффективной командной работы. Бесплатное подключение!"

        />
      </Helmet>
      <div>
        < AddemployeeplusLigth />
        <label htmlFor="searchPerfomerList" className="searchEmplotDiv">
          <input type="text" name="searchPerfomerList" id="searchPerfomerList" placeholder="Поиск cотрудников" onChange={(e) => searchPerfomer(e.target.value)} />
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
              <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
            </svg>
          </div>
        </label>
      </div>
      <div>
        <AddNewUserTable handleShow={handleShow} workers={gerChekcedCompany.rootReducer.users.data} />
      </div>
      <ToastContainer />
    </div>
  );
}
