const getDefaultState = {
    users: [],
    loading: false,
    error: null
  };
  
  const getDefaultCompanyReducer = (state = getDefaultState, action) => {
    switch (action.type) {
      case 'FETCH_DEFAULTCOMPANY_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_DEFAULTCOMPANY_SUCCESS':
    
        return {
          ...state,
          loading: false,
          users: action.payload
        };
        
      case 'FETCH_DEFAULTCOMPANY_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getDefaultCompanyReducer;
  