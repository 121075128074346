const getOnlineUserViaRouter= {
    users: [],
    loading: false,
    error: null
  };
  
  const getOnlineUserViaRouterReducer = (state = getOnlineUserViaRouter, action) => {
    switch (action.type) {
      case 'FETCH_ONLINEUSERVIAROUTER_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_ONLINEUSERVIAROUTER_SUCCESS':
    
        return {
          ...state,
          loading: false,
          users: action.payload
        };
        
      case 'FETCH_ONLINEUSERVIAROUTER_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getOnlineUserViaRouterReducer;
  