import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ozonTree from "./ozonTree.json"
import { Select } from '@chakra-ui/react'
import "./addProdcutOzon.css"

export default function AddProductOzon({setisdisable,setCategory_ID,getatribut}) {
    const [category_id,setCategory_id] = useState(null)
    const [children ,setChildren ] = useState([])
    const [children2,setChildren2 ] = useState([])
    const [children3,setChildren3 ] = useState([])
   
       
    


const handeCatigory1=(e)=>{
      
      if (e.target.value != "Выберите категорию") {
        setChildren2(JSON.parse(e.target.value))
        setisdisable(true)
        setChildren3([])
      }else{
        setisdisable(true)
        setChildren3([])
        setChildren2([])
      }
   
}

const handeCatigory2=(e)=>{
 
    if (e.target.value != "Выберите категорию") {
        setChildren3(JSON.parse(e.target.value))
        setisdisable(true)
    }else{
        setisdisable(true)
        setChildren3([])
    }
   
}

const handeCatigory3=async(e)=>{
       
        if (e.target.value == "Выберите категорию") {
          await  setisdisable(true)
        }else{
           await setCategory_id(e.target.value)
          await  setCategory_ID(e.target.value)
             
          
         
            await   getatribut(e.target.value)

        }
  
   
}
      return(
            <div className='OzonAddProductCont'>
                        <select  defaultValue={"Выберите категорию"}  onChange={(e)=>  handeCatigory1(e)} >
                        <option selected>Выберите категорию</option>
                                {
                                ozonTree.result&&ozonTree.result?.sort((a,b)=> a.title.localeCompare(b.title)).map((item,index)=>{
                                                    
                                        return <option value={JSON.stringify(item.children)} name={"1"} key={index} >{item.title}</option>    
                                                
                                                                
                                })
                                        
                            }
                            
                            
                        </select>

                    {children2.length > 0?
                        <select  defaultValue={"Выберите категорию"}  onChange={(e)=> handeCatigory2(e)}>
                             <option selected>Выберите категорию</option>
                        {
                        children2.sort((a,b)=> a.title.localeCompare(b.title)).map((item,index)=>{
                            
                                return  <option  value={JSON.stringify(item.children )} id={item.category_id} key={index} >{item.title} </option>  
                                    
                                        
                                                        
                        })
                                
                    }
                    
                    
                        </select>
                        
                    :""}

                    {
                        (children3.length > 0)?
                        <select   defaultValue={"Выберите категорию"} onChange={(e)=>handeCatigory3(e)}>
                            <option selected>Выберите категорию</option>
                        {
                        children3.sort((a,b)=> a.title.localeCompare(b.title)).map((item,index)=>{
                            
                                return  <option  value={item.category_id}  key={index}>{item.title}</option>    
                                        
                                                        
                        })
                                
                    }
                    
                    
                </select>
                        :
                        ""
                    }

              
              </div>
        )
}