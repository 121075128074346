import { useDispatch, useSelector } from "react-redux";
import { updateOptions } from "../../../redux/reducer/option/action/action";
import "./toggleAllMyProjectLight.css";
import "./toggleAllMyProjectDark.css";
import { Switch } from "@chakra-ui/react";

export default function ToggleAllMyProject() {
    const myProject = useSelector((state) => state.optionReducer.myProject); // Получаем значение из Redux
    const dispatch = useDispatch();
    const getDarkModoe = useSelector(darkModes => darkModes);

    const handleToggle = (e) => {
        dispatch(updateOptions(e.target.checked, "UPDATE_MY_PROJECT_COMPANY")); // Передаем новое значение
    };
    // console.log(myProject);

    return (
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "ToggleAllMyProjectContDark" : "ToggleAllMyProjectContLight"}>
            <div>
                <p>Все проекты</p>
            </div>
            <div>
                <Switch
                    size="sm"
                    isChecked={myProject} // Связываем с состоянием
                    onChange={handleToggle} // Обновляем состояние при изменении
                />
            </div>
        </div>
    );
}
