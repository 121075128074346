// UrlPreview.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g;

const UrlPreview = ({ url }) => {
  const [data, setData] = useState(null);
  const [urls, setUrls] = useState([]);
  
  useEffect(() => {
    // Use the match() method to find all links
    const urlMatches = url.match(urlRegex);
    console.log(urlMatches);
    
    if (urlMatches) {
      setUrls(urlMatches); // Store all found URLs in state
      const fetchUrlData = async (url) => {
        try {
          if (!url) return; // Ensure there's a valid URL before making the request
          
          const response = await axios.get(`/preview/api?url=${url}`);
          setData(response.data.parseInf);
          console.log(response.data.parseInf);
        } catch (error) {
          console.error('Error fetching URL preview:', error);
        }
      };
      
      // Fetch data for each URL found
      urlMatches.forEach(fetchUrlData);
    }
  }, [url]);

  return (
    <div>
      {/* Display text without URLs */}
      <p>{url.replace(urlRegex, '')}</p>
      
      {/* Display found URLs */}
      {urls.map((foundUrl, index) => (
        <div key={index}>
          <a href={foundUrl} style={{color:"#0056b3"}} target="_blank" rel="noopener noreferrer">{foundUrl}</a>
        </div>
      ))}

      {data ? (
        <div>
          {data.imageUrl && (
            <img src={data.imageUrl} style={{ width: "100px", height: "50px", objectFit: "cover" }} alt="Preview" />
          )}
          <h2>{data.title}</h2>
          <p>{data.description}</p>
          <p>{data.name}</p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UrlPreview;
