import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    Table,
    Thead,
    Tbody,

    Tr,
    Th,
    Td,
    TableContainer,
    Avatar,
    

} from '@chakra-ui/react'





export default function MdalEditWorkers({ data }) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Button onClick={onOpen}>{data?.workers.length}</Button>

            <Modal isOpen={isOpen} onClose={onClose} size={"full"} scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer>
                            <Table variant='simple'>
                                
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Avatar</Th>
                                        <Th >Тел.</Th>
                                        <Th>Почта</Th>
                                        <Th >Роль</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        data?.workers?.map((item, indx) => {
                                            return <Tr key={indx}>
                                                <Td>{item.workerID}</Td>
                                                <Td><Avatar src={item?.img?`/${item.img}`:"https://bit.ly/broken-link"} /></Td>
                                                <Td>{item.tel}</Td>
                                                <Td>{item.email}</Td>
                                                <Td>{item.rols}</Td>
                                              
                                               
                                            </Tr>
                                        })
                                    }


                                </Tbody>

                            </Table>
                        </TableContainer>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Закрывать
                        </Button>
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}