import { Switch } from '@chakra-ui/react'
export default function ComplitedTaskFilter({completedTask,setcompletedTask}) {
    const handleToggle = () => {
        // Переключение значения completedTask
        setcompletedTask(!completedTask);
      };
    
        return (
            <div className="complitedTaskFilterCont">
                <div>
                    <div>
                        <div>
                            <p>Показывать выполненные задачи</p>
                        </div>
                    </div>
                    <Switch size='md'  isChecked={completedTask} onChange={handleToggle}/>
                </div>
            </div>
        )
}