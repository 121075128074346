import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
export default function PerFormerListForModal({ index, item, setWorkersForChat }) {
    // Boolean выключатель для выброса сотрудник
    const [isChecked, setIsChecked] = useState(false);

    function checkedFunc() {
        setWorkersForChat(item); // Вызов setWorkersForChat с элементом
        setIsChecked(prevIsChecked => !prevIsChecked); // Переключить состояние isChecked

    }

    return (
        <label htmlFor={"choosePerformer" + index} className="ddingParticipantsPerformersList" onClick={checkedFunc}>
            {
                isChecked ?
                    <div className="inputContChecked">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                            <path d="M11.7149 0.988074C11.8916 0.775935 11.863 0.460653 11.6508 0.283871C11.4387 0.107089 11.1234 0.135751 10.9466 0.347889L7.35285 4.66043C6.63096 5.5267 6.12313 6.13421 5.68267 6.53195C5.25247 6.92042 4.95558 7.04388 4.66408 7.04388C4.37257 7.04388 4.07568 6.92042 3.64548 6.53195C3.20502 6.1342 2.69719 5.5267 1.9753 4.66043L1.04819 3.54789C0.871404 3.33575 0.556121 3.30709 0.343983 3.48387C0.131844 3.66065 0.103182 3.97593 0.279964 4.18807L1.23281 5.33149C1.92299 6.15973 2.47571 6.82302 2.97529 7.27413C3.49133 7.74012 4.01883 8.04388 4.66408 8.04388C5.30932 8.04388 5.83682 7.74012 6.35286 7.27413C6.85243 6.82302 7.40515 6.15974 8.09532 5.33151L11.7149 0.988074Z" fill="white" />
                        </svg>
                    </div>
                    :
                    <div className="inputContNoChecked">

                    </div>
            }

            {/* <Avatar size='xs' src={item.img ? "http://localhost:5000/" + item.img : "https://bit.ly/broken-link"} /> */}
            <Avatar size='xs' src={item.img ? `/${item.img }`: "https://bit.ly/broken-link"} />
            <p className="perfomer-name">{item.name}</p>
            <p className="perfomer-nameDogs">{item.tel}</p>
        </label>
    )
}