import axios from "axios"
import { useDispatch,useSelector } from "react-redux"
import { getCaompanyAllAction } from "../../../../../redux/reducer/getAllCompanyForAdmin/action/action"
import { useState } from "react"
import Spinner from 'react-bootstrap/Spinner';
import { getUsersAllAction } from "../../../../../redux/reducer/getAllUsersForAdmin/action/action";
import { useToast } from '@chakra-ui/react'
export default function DeleteUsersByAdmin({item,size,currentPage}) {

    const dispatch = useDispatch()

    const [loading,setLoading] = useState(false)
    const toast = useToast()

    const handleDelete=(e)=>{
        setLoading(true)
        axios.delete('/users/delete/byadmin/api', {
           data:{
            user:e
           }
          })
          .then(function (response) {
            console.log(response);
            dispatch(getUsersAllAction(`/getallusers/api?page=${currentPage || 1}`));
            setLoading(false)
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false)
            toast({
              position:"top",
              title: 'Ошибка.',
              description: error.response.data.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          });
    }
    

           if (loading) {
             return(
                <Spinner animation="border" style={{color:"#DB5F98"}} size="sm" />
             )
           }
           return  <span className="material-symbols-outlined deletButtonComp" style={{color:"#DB5F98"}} onClick={()=> handleDelete(item)}>delete</span>
}