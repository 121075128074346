import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import sockets from '../../../../../socket/socket';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure
} from '@chakra-ui/react'

const socket = sockets
const DropdownMobilePioritet = ({ isChild, prioritetGet, allitemForStatus, index, update }) => {
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
    const getDarkModoe = useSelector(darkModes => darkModes)
    const toast = useToast()

    const { onOpen, onClose, isOpen } = useDisclosure();
    const [prioritetColor, setPrioritetColor] = useState("red")

    const handleGetNotify = () => {
        socket.emit('getNotifyEmit');
    }
    async function sendInfoNotify(allitemForStatus, isChild, whatIsNotofy, index, currentTask) {
        axios.post('/sendInfoNotify/api', {
            allitemForStatus,
            isChild,
            whatIsNotofy,
            index,
            currentTask
        })
            .then(function (response) {
                console.log(response);
                handleGetNotify()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const handleUpdateStatus = (e) => {
        if (isChild) {

            allitemForStatus.alltask[0].child[index].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response.data.message);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet", index, allitemForStatus.alltask[0].child[index])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        } else {
            allitemForStatus.alltask[0].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet", index, allitemForStatus.alltask[0])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }

    }

    useEffect(()=>{
            if (prioritetGet === "Критический") {
                setPrioritetColor("red")
            }else if (prioritetGet === "Важно") {
                setPrioritetColor("#56D4FC")
            }else if (prioritetGet === "Срочно") {
                setPrioritetColor("#4DFF30")
            }else if (prioritetGet === "Низший") {
                setPrioritetColor("#FB50FF")
            }
            
        
    },[prioritetGet])

    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPrioritetDark" : "selectPrioritetLight"}>


            <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}

            >
                <PopoverTrigger>
                    <button disabled={getmyinfo.myinfo._id !== allitemForStatus?.creatorTasks}>
                        <div>
                            <div>
                                <div>
                                    <p>Приоритет:</p>
                                    <div>
                                        <p style={{ color: prioritetColor }}>{prioritetGet}</p>
                                    </div>
                                </div>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </button>
                </PopoverTrigger>
                <PopoverContent borderRadius={"0 0 12px 12px"} overflow={"hidden"} width={"240px"}>

                    <PopoverBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverForCreatPrioritetDark" : "popoverForCreatPrioritetLight"}>
                        <div onClick={() => [handleUpdateStatus("Критический"), onClose()]}>
                            <div>
                                <div>
                                    <p>Критический</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => [handleUpdateStatus("Важно"), onClose()]}>
                            <div>
                                <div>
                                    <p>Важно</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => [handleUpdateStatus("Срочно"), onClose()]}>
                            <div>
                                <div>
                                    <p>Срочно</p>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => [handleUpdateStatus("Низший"), onClose()]}>
                            <div>
                                <div>
                                    <p>Низший</p>
                                </div>
                            </div>
                        </div>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default DropdownMobilePioritet;
