// Добавление участников modal

import { useDispatch, useSelector } from "react-redux";
import { Avatar } from '@chakra-ui/react'
import { useEffect, useRef, useState, useTransition } from "react";
import axios from "axios";
import "./privateChatModalLight.css"
import "./privateChatModalDark.css"
import { useLocation } from "react-router";
import sockets from "../../../../socket/socket";
import { useSearchParams } from "react-router-dom";
import { getChatInfoByIDAction } from "../../../../redux/reducer/getChatInfoByID/action/action";
import CreatChatButton from "../creatChatButton/creatChatButton";
import { getItemChatAction } from "../../../../redux/reducer/getChatItem/action/action";
import { getWorkersForChat } from "../../../../redux/reducer/получить сотрудников для чата/action/action";
const socket = sockets;

export default function CreatPrivateChat({
    openModalForAddEmploys,
}) {
    const openModal = useRef();
    const getDarkModoe = useSelector((darkModes) => darkModes);
    const [getThisCompanyWorkers, setGetThisCompanyWorkers] = useState([]);
    const [loadWorkers, setloadWorkers] = useState(false);
    const [laodAddBTN, setlaodAddBTN] = useState(false);
    const [chooseWokers, setChooseWokers] = useState({});
    const [isPending, startTransition] = useTransition();
    const [searchInputValue, setSearchInputValue] = useState("");
    const dispatch = useDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const chatID = searchParams.get('chatID');
    const getWorkersForChatinfo = useSelector(getWorkersForChatS => getWorkersForChatS)
    const [workersID,setWorkersID]=useState([]);
    // открыть модальное окно
    const openModalFunc = () => {
        dispatch(getWorkersForChat("/alldeploy/api"))
        openModal.current.style.display = "flex";
        
    };
    // Закрыть модальное окно
    const closeModalFunc = () => {
        openModal.current.style = "none";
    };
    // после создания нового чата откроется модальное окно для выбор участников
    // openModalForAddEmploys == true тогда откроется модальное окно
    useEffect(() => {
        if (openModalForAddEmploys) {
            openModal.current.style.display = "flex";
        } else {
            openModal.current.style.display = "none";
        }
    }, [openModalForAddEmploys]);

    // эта функция для того чтобы закрыть модальное окно когда не в точке
    const closeModal = (e) => {
        if (e.target.className) {
            if (
                e.target.className == "addingParticipantsLightCont" ||
                e.target.className == "addingParticipantsDarkCont"
            ) {
                closeModalFunc();
            }
        }
    };
    // динамическая функция для Get запроса через AXIOS
    function axiosGetWorkers(url) {
        setloadWorkers(true);
        axios
            .get(url)
            .then(function (response) {
                // handle success

                setGetThisCompanyWorkers(response.data.data);

                setloadWorkers(false);
            })
            .catch(function (error) {
                // handle error
                console.error("Error fetching data:", error);
                setloadWorkers(false);
            })
            .finally(function () {
                setloadWorkers(false);
            });
    }

    // добавить идентификатор сотрудников в массив
   
    // поиск сотрудника(useTransition)
    const searchPerformer = (e) => {
        startTransition(() => {
            setSearchInputValue(e);
        });
    };
    // этот useEffect обновляется когда изменяется поле для ввода пользователей (searchInputValue)
    useEffect(() => {
        if (searchInputValue.length > 0) {
            // если в поле есть какое-то значение тогда на сервере работает после сотрудников
            // axiosGetWorkers(
            //     `/getCurrendCompanyWorkersSearch/api?searchInputValue=${searchInputValue}`
            // );
            dispatch(getWorkersForChat(`/alldeploy/api?searchWorkers=${searchInputValue}`))
        } else {
            // если нет значения в поле тогда мы получаем всех сотрудников
            // axiosGetWorkers("/getCurrendCompanyWorkers/api");
            dispatch(getWorkersForChat("/alldeploy/api"))
        }
    }, [searchInputValue]);

    useEffect(() => {
        function updateChat() {
            if (location.pathname == "/chat/conversations") {
                dispatch(getItemChatAction("/getItemChat/api?filter=Conversations"));
                if (chatID !== null) {
                    dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
                }
            } else if (location.pathname == "/chat/public") {
                dispatch(getItemChatAction("/getItemChat/api?filter=Public"));
                if (chatID !== null) {
                    dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
                }
            }else if (location.pathname == "/chat/private") {
                dispatch(getItemChatAction("/getItemChatPrivate/api?filter=private"));   
                if (chatID !== null) {
                    dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
                  }
            }

        }
        socket.on("updateChatSocketWorkers", updateChat);

        return () => {
            socket.off("connect", updateChat);
        };
    }, []);

    async function creatChatAndAddPerformer(params) {
              
                axios.post('/creatPrivateChat/api', {
                    data:chooseWokers
                  })
                  .then(function (response) {
                    
                    getThisCompanyWorkers?.forEach((item) => {
                        setWorkersID(prevState => [...prevState, item.workerID]);
                           
                     
                    });
                    
                    closeModalFunc();
                    socket.emit("updateChatSocketWorkers", [chooseWokers.workerID,chooseWokers.adminID]);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
    }
   
    useEffect(() => {
        dispatch(getWorkersForChat("/alldeploy/api"))
        
      }, []);
    return (
        <>
            <CreatChatButton openModalFunc={openModalFunc} />
            <div

                className={
                    getDarkModoe.ToggleDarkModeReducer.chekced
                        ? "creatPrivateChatDark"
                        : "creatPrivateChatLight"
                }
                onClick={closeModal}
                ref={openModal}
            >

                <div className="ddingParticipants-content">
                    <div className="ddingParticipants-header">
                        <div className="textAndClose">
                            <p>Добавление участников</p>
                            <div
                                className="closeIconCont"
                                onClick={(e) =>
                                    closeModalFunc(e)
                                }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                >
                                    <path
                                        d="M1 1L15 15M1.00003 15L8.00003 8L15 1"
                                        stroke="#393939"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <label
                            htmlFor="searchPerformer"
                            className="ddingParticipantsSearchPerformerCont"
                        >
                            <input
                                type="text"
                                name=""
                                id="searchPerformer"
                                placeholder="Например,  “Маркетинг”"
                                value={searchInputValue}
                                onChange={(e) => searchPerformer(e.target.value)}
                            />
                            <div>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M15.7539 14.2549H14.9639L14.6839 13.9849C15.6639 12.8449 16.2539 11.3649 16.2539 9.75488C16.2539 6.16488 13.3439 3.25488 9.75391 3.25488C6.16391 3.25488 3.25391 6.16488 3.25391 9.75488C3.25391 13.3449 6.16391 16.2549 9.75391 16.2549C11.3639 16.2549 12.8439 15.6649 13.9839 14.6849L14.2539 14.9649V15.7549L19.2539 20.7449L20.7439 19.2549L15.7539 14.2549ZM9.75391 14.2549C7.26391 14.2549 5.25391 12.2449 5.25391 9.75488C5.25391 7.26488 7.26391 5.25488 9.75391 5.25488C12.2439 5.25488 14.2539 7.26488 14.2539 9.75488C14.2539 12.2449 12.2439 14.2549 9.75391 14.2549Z"
                                        fill="#CED2D9"
                                    />
                                </svg>
                            </div>
                        </label>
                    </div>
                    <div className="ddingParticipants--body">
                        {loadWorkers ? "loading" : ""}
                        {getWorkersForChatinfo.userReducerGetWorkersForChat.workres?.map((item, index) => {
                                    if (getWorkersForChatinfo.userReducerGetWorkersForChat.loading) {
                                        return <p className="loading">Загрузка...</p>
                                    }else{
                                        return (
                                            <label key={index} className="preformerList" htmlFor={item._id} onClick={()=> setChooseWokers(item)}>
                                                <input type="radio" name="11" id={item._id} />
                                                <div>
                                                    <Avatar name={item.name?item.name:item.tel} size={"sm"}  src={item.img ? `/${item.img }`: "https://bit.ly/broken-link"}  />
                                                    
                                                    <p>{item.name?item.name:item.tel}</p>
                                                </div>
                                            </label>
                                        );
                                    }
                           
                        })}
                    </div>
                    <div className="ddingParticipants--footer">

                        {laodAddBTN ? (
                            "load"
                        ) : (
                            <button

                                className="addPerformer"
                                style={{
                                    backgroundColor: chooseWokers.length > 0 ? "#3B79F6" : "",
                                    color: chooseWokers.length > 0 ? "white" : "",
                                }}
                                onClick={creatChatAndAddPerformer}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                                </svg>
                                Создать чат
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
