// Добавление участников modal

import { useDispatch, useSelector } from "react-redux";
import PerFormerListForModal from "../perFormerListForModal/perFormerListForModal";
import "./addingParticipantsDark.css";
import "./addingParticipantsLight.css";
import { useEffect, useRef, useState, useTransition } from "react";
import axios from "axios";
import { getItemChatAction } from "../../../../redux/reducer/getChatItem/action/action";
import { useLocation } from "react-router";
import sockets from "../../../../socket/socket";
import { useSearchParams } from "react-router-dom";
import { getChatInfoByIDAction } from "../../../../redux/reducer/getChatInfoByID/action/action";
import { getWorkersForChat } from "../../../../redux/reducer/получить сотрудников для чата/action/action";
const socket = sockets;

export default function AddingParticipants({
  openModalForAddEmploys,
  data,
  setOpenModalForAddEmploys,
}) {
  const openModal = useRef();
  const getDarkModoe = useSelector((darkModes) => darkModes);
  const [getThisCompanyWorkers, setGetThisCompanyWorkers] = useState([]);
  const [loadWorkers, setloadWorkers] = useState(false);
  const [laodAddBTN, setlaodAddBTN] = useState(false);
  const [chooseWokers, setChooseWokers] = useState([]);
  const [isPending, startTransition] = useTransition();
  const [searchInputValue, setSearchInputValue] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const chatID = searchParams.get('chatID');
  const checkedSession = useSelector(checkedSession => checkedSession)
  const getWorkersForChatinfo = useSelector(getWorkersForChatS => getWorkersForChatS)
  // открыть модальное окно
  const openModalFunc = () => {
    openModal.current.style.display = "flex";
  };
  // Закрыть модальное окно
  const closeModalFunc = () => {
    openModal.current.style = "none";
  };
  // после создания нового чата откроется модальное окно для выбор участников
  // openModalForAddEmploys == true тогда откроется модальное окно
  useEffect(() => {
    if (openModalForAddEmploys) {
      openModal.current.style.display = "flex";
    } else {
      openModal.current.style.display = "none";
    }
  }, [openModalForAddEmploys]);

  // эта функция для того чтобы закрыть модальное окно когда не в точке
  const closeModal = (e) => {
    if (e.target.className) {
      if (
        e.target.className == "addingParticipantsLightCont" ||
        e.target.className == "addingParticipantsDarkCont"
      ) {
        closeModalFunc();
      }
    }
  };
  // динамическая функция для Get запроса через AXIOS
  function axiosGetWorkers(url) {
    setloadWorkers(true);
    axios
      .get(url)
      .then(function (response) {
        // handle success

        setGetThisCompanyWorkers(response.data.data);

        setloadWorkers(false);
      })
      .catch(function (error) {
        // handle error
        console.error("Error fetching data:", error);
        setloadWorkers(false);
      })
      .finally(function () {
        setloadWorkers(false);
      });
  }
  // здесь мы получаем всех сотрудников с сервера
  useEffect(() => {
    setChooseWokers([]);
    dispatch(getWorkersForChat("/alldeploy/api"))
    
  }, []);
  // добавить идентификатор сотрудников в массив
  function setWorkersForChat(e) {
    const workerID = e.workerID;

    if (chooseWokers.includes(workerID)) {
      // Если идентификатор работника существует в массиве ChooseWokers, удалите его.
      setChooseWokers((prevWorkers) =>
        prevWorkers.filter((id) => id !== workerID)
      );
    } else {
      // Если идентификатор работника не существует в массиве ChooseWokers, добавьте его.
      setChooseWokers((prevWorkers) => [...prevWorkers, workerID]);
    }
  }
  // поиск сотрудника(useTransition)
  const searchPerformer = (e) => {
    startTransition(() => {
      setSearchInputValue(e);
    });
  };
  // этот useEffect обновляется когда изменяется поле для ввода пользователей (searchInputValue)
  useEffect(() => {
    if (searchInputValue.length > 0) {
        // если в поле есть какое-то значение тогда на сервере работает после сотрудников
        // axiosGetWorkers(
        //     `/getCurrendCompanyWorkersSearch/api?searchInputValue=${searchInputValue}`
        // );
        dispatch(getWorkersForChat(`/alldeploy/api?searchWorkers=${searchInputValue}`))
    } else {
        // если нет значения в поле тогда мы получаем всех сотрудников
        // axiosGetWorkers("/getCurrendCompanyWorkers/api");
        dispatch(getWorkersForChat("/alldeploy/api"))
    }
}, [searchInputValue]);

  useEffect(() => {
    function updateChat() {
      if (location.pathname == "/chat/conversations") {
        dispatch(getItemChatAction("/getItemChat/api?filter=Conversations"));
        if (chatID !== null) {
          dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
        }
      } else if (location.pathname == "/chat/public") {
        dispatch(getItemChatAction("/getItemChat/api?filter=Public"));
        if (chatID !== null) {
          dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
        }
      }else if (location.pathname == "/chat/") {
        dispatch(getItemChatAction("/getItemChat/api?filter=Public"));
        if (chatID !== null) {
          dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
        }
      }

    }
    socket.on("updateChatSocketWorkers", updateChat);

    return () => {
      socket.off("connect", updateChat);
    };
  }, []);

  async function addPerformerChat(params) {
    if (chooseWokers.length > 0) {
      try {
        // Assuming you have an endpoint '/addPerformerIDToChat/api/:chatID' in your Express.js backend
        await axios.put(`/addPerformerIDToChat/api/${data._id}`, chooseWokers);
        if (socket) {
          socket.emit("updateChatSocketWorkers", chooseWokers);
        }

        setOpenModalForAddEmploys(!openModalForAddEmploys);
        if (chatID !== null) {
          dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
        }

      } catch (error) {
        console.error("Error updating data:", error);
      }
    } else {
      console.log("not choose worker");
    }
  }
  

  return (
    <>
      <div
        className={
          getDarkModoe.ToggleDarkModeReducer.chekced
            ? "addingParticipantsDarkCont"
            : "addingParticipantsLightCont"
        }
        onClick={closeModal}
        ref={openModal}
      >
        <div className="ddingParticipants-content">
          <div className="ddingParticipants-header">
            <div className="textAndClose">
              <p>Добавление участников</p>
              <div
                className="closeIconCont"
                onClick={() =>
                  setOpenModalForAddEmploys(!openModalForAddEmploys)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M1 1L15 15M1.00003 15L8.00003 8L15 1"
                    stroke="#393939"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <label
              htmlFor="searchPerformer"
              className="ddingParticipantsSearchPerformerCont"
            >
              <input
                type="text"
                name=""
                id="searchPerformer"
                placeholder="Например,  “Маркетинг”"
                value={searchInputValue}
                onChange={(e) => searchPerformer(e.target.value)}
              />
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15.7539 14.2549H14.9639L14.6839 13.9849C15.6639 12.8449 16.2539 11.3649 16.2539 9.75488C16.2539 6.16488 13.3439 3.25488 9.75391 3.25488C6.16391 3.25488 3.25391 6.16488 3.25391 9.75488C3.25391 13.3449 6.16391 16.2549 9.75391 16.2549C11.3639 16.2549 12.8439 15.6649 13.9839 14.6849L14.2539 14.9649V15.7549L19.2539 20.7449L20.7439 19.2549L15.7539 14.2549ZM9.75391 14.2549C7.26391 14.2549 5.25391 12.2449 5.25391 9.75488C5.25391 7.26488 7.26391 5.25488 9.75391 5.25488C12.2439 5.25488 14.2539 7.26488 14.2539 9.75488C14.2539 12.2449 12.2439 14.2549 9.75391 14.2549Z"
                    fill="#CED2D9"
                  />
                </svg>
              </div>
            </label>
          </div>
          <div className="ddingParticipants--body">
            {loadWorkers ? "loading" : ""}
            {getWorkersForChatinfo.userReducerGetWorkersForChat.workres?.map((item, index) => {
                
              return (
                <PerFormerListForModal
                  key={index}
                  index={index}
                  item={item}
                  setWorkersForChat={setWorkersForChat}
                />
              );
            })}
          </div>
          <div className="ddingParticipants--footer">
            <button
              className="Skip"
              onClick={() => setOpenModalForAddEmploys(!openModalForAddEmploys)}
            >
              Пропустить
            </button>
            {laodAddBTN ? (
              "load"
            ) : (
              <button
                className="addPerformer"
                style={{
                  backgroundColor: chooseWokers.length > 0 ? "#3B79F6" : "",
                  color: chooseWokers.length > 0 ? "white" : "",
                }}
                onClick={addPerformerChat}
              >
                Добавить
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
