import axios from 'axios';

export const getWorkersForChat = (url) => {
  return (dispatch) => {
      
    dispatch({ type: 'GET_WORKRES_FORCHAT_REQUEST' });
    axios.get(url)
      .then(response => {

        dispatch({
          type: 'GET_WORKRES_FORCHAT_SUCCESS',
          payload:response.data.data
        });
       
      })
      .catch(error => {
        dispatch({
          type: 'GET_WORKRES_FORCHAT_FAILURE',
          payload: error.message
        });
      });
  };
};