import { useEffect, useRef, useState } from "react"
import "./selectOzonEdit.css"
import { Select } from '@chakra-ui/react'
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'



export default function SelectOzonEdit(item) {
    const divref = useRef(null)
    const refinput = useRef(null)
    const contDiv = useRef(null)
    const bool = useRef(false)
  const [value,setValue] = useState([])
  const [isshow,setisshow] = useState(false)
  const [inputvalue,setinputvalue] = useState("")
  const [devalue,setdevalue] = useState("")
  let atributeParams =  {
    complex_id: 0,
    id: item.id,
    values: [
      {
        dictionary_value_id: "",
        value: ""
      }
    ]
  }

  useEffect(()=>{
    setisshow(false)

          if (item.item.currentinfo) {
                 
                  setValue(item.item.currentinfo)
                 
                  setdevalue(item.item.currentinfo.values[0].value)
             
          }
         
  },[])


    
    function filterFunction(e) {
     
      divref.current.style.display = 'block';
    let  input = document.getElementById("ozonEditInputSelect");
     let filter = input.value.toUpperCase();
     let div = document.getElementById("myDropdown");
     let a = div.getElementsByTagName("p");


      for (let i = 0; i < a.length; i++) {
let        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
         
          a[i].style.display = "";
         
        } else {
          a[i].style.display = "none";
        }
      }
    }



   window.addEventListener("click",(e)=>{
    e.stopPropagation();
       if (e.target.id && e.target.id =="myInput" ) {
        bool.current = true
        divref.current.style.display = 'block';
       }
   })

const shows=(e)=>{


  e.stopPropagation();
  bool.current = !bool.current
    if (bool.current) {
      divref.current.style.display = 'block';
    }else{
      divref.current.style.display = 'none';
    }
 
 
}

const handleChange=(e)=>{
      
  let prs = JSON.parse(e)
    
  atributeParams.id = item.item.id
      atributeParams.values[0].dictionary_value_id = prs.id;
      atributeParams.values[0].value = prs.value;
     
      setValue(atributeParams)
   

  //  console.log(atributeParams);
}   


const changeSelec=(e)=>{
  setdevalue(e)
 
      setinputvalue(e)
    
}


   return(
    <div className="selectsozon"  key={item}>
        <h1>{item.item.name}</h1>
   
 
 <input type="hidden" name="selectsozonEdit" value={JSON.stringify(value)} key={value}/>




 <div className="dropdown" >
  
         <input type="text"  placeholder="Выберите" id="ozonEditInputSelect" name="selectunput" onKeyUp={(e)=>filterFunction(e)} onClick={shows}  defaultValue={devalue} ref={refinput} autoComplete="off"  key={"asd" + devalue}  />
  
      <div id="myDropdown" className="dropdown-content"  ref={divref} >
        
      {item.item.values.result?.map((item,index)=>{

                   return   (
                    <p  key={index} onClick={()=>[ handleChange(JSON.stringify(item)),changeSelec(item.value)]}>{item.value}</p>
                  )
          
        })}
      
      </div>
</div>

</div>

   )


    }
