import { Helmet } from "react-helmet"


export function EmployeeTemplateComand(params) {

    return <>
        <Helmet>
            <title>Добавляйте компании и управляйте задачами на маркетплейсах</title>
            <meta name="description" content="
                 Создайте профиль компании в таск-трекере, чтобы организовать задачи для маркетплейсов. Все компании в одном интерфейсе для удобного управления.
                " />
        </Helmet>

        <h1 style={{ textAlign: "center", fontSize: "2em" }}>Страница находится в разработке</h1>
    </>
}