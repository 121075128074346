import { useEffect, useRef, useState } from "react"
import "./selectOzonEditTag.css"
import { Select } from '@chakra-ui/react'
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'

import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
} from '@chakra-ui/react'

let allarray =[]

export default function TagInputOzonEdit(item) {
    const divrefRef = useRef(null)
    const refinputEditTag = useRef(null)
    const contDiv = useRef(null)
    const bool = useRef(false)
  const [value,setValue] = useState([])
  const [isshow,setisshow] = useState(false)
  const [inputvalue,setinputvalue] = useState("")
  const [inputvalueArray,setinputvalueArray] = useState([])
  const [defvalue,setdefvalue] = useState("")
  const [attributeVal,setattributeVal] = useState([])

  let atributeParams =  {
    complex_id: 0,
    id: item.item.id,
    values: [
      
    ]
  }


  useEffect(()=>{
    setisshow(false)
        
        if (item.item.currentinfo)  {
            console.log(item.item.currentinfo);
         
        
          setinputvalueArray(item.defval.values)
          setdefvalue(inputvalueArray[0]&&inputvalueArray[0].value)
       
   
          atributeParams.values = item.defval.values
         
          setValue(atributeParams)
        }
        setattributeVal(item.item.values.result)
       
  },[])

  useEffect(()=>{
    atributeParams.values =  inputvalueArray
    setValue(atributeParams)
   console.log(inputvalueArray);
  },[inputvalueArray])






    const handleChange=async(e)=>{
     
     
    let findTag =   inputvalueArray.find(item=> item.id === e.id || item.dictionary_value_id === e.id)
          if (!findTag) {
            atributeParams.id = item.item.id

            setinputvalueArray(inputvalueArray => [...inputvalueArray,e])
          
           
          }
         
        
    }   




    
    function filterFunction(e) {
       
      const regex = new RegExp(e.target.value, 'i' )
   

     
      const filteredResults = item.item.values.result?.filter(item => regex.test(item.value))

setattributeVal(filteredResults)


  
    
      
    }

const handleDelete=(e)=>{
  
            let vv = inputvalueArray.filter((item, i) => item.id !== e.id  || item.dictionary_value_id !== e.dictionary_value_id)
        setinputvalueArray(vv);
        
        atributeParams.values = vv
      setValue(atributeParams)
}

   window.addEventListener("click",(e)=>{
    e.stopPropagation();

       if (e.target.id && e.target.id =="myInput" ) {
        bool.current = true
        divrefRef.current.style.display = 'block';
       }
   })

const shows=(e)=>{


  e.stopPropagation();
  bool.current = !bool.current
    if (bool.current) {
      divrefRef.current.style.display = 'block';
    }else{
      divrefRef.current.style.display = 'none';
    }
 
 
}

const setinpValue=(e)=>{
      
        setdefvalue(e.value)
}


   return(
    <div className="taginput"  key={item}>
        <h1>{item.item.name}</h1>
   
 
 <input type="hidden" name="selectsozonEditTag" value={JSON.stringify(value)} key={JSON.stringify(atributeParams)}/>



{inputvalueArray?.map((item,index)=>{

        if (item.value != "") {
          return (
            <Tag
            onClick={()=> handleDelete(item)}
      size={item}
      key={index}
      borderRadius='full'
      variant='solid'
      colorScheme='green'
    >
      <TagLabel >{item.value}</TagLabel>
      <TagCloseButton />
    </Tag>
    )
        }
        return <p></p>
    
})}
      



 <div className="dropdown" >
  
         <input type="text"  placeholder="Выберите" id="ozonEditInputSelect" name="selectunput" onKeyUp={(e)=>filterFunction(e)} onClick={shows}  value={defvalue} ref={refinputEditTag} autoComplete="off"  key={defvalue + "asdasd"}  />
  
      <div id="myDropdown" className="dropdown-content"  ref={divrefRef} >
        
      {attributeVal?.map((item,index)=>{
             
                   return   (
                    <p  key={index} onClick={(e)=>[ handleChange(item),setinpValue(item),shows(e)]}>{item.value}</p>
                  )
          
        })}
      
      </div>
</div>

</div>

   )

return<>asdsd</>
    }
