import { useEffect, useRef } from "react"
import "./modAlForTutorilaVideo.css"
import video from './myvideo.mp4'

export default function ModAlForTutorilaVideo() {
  const modalRed = useRef(null)
  const spanRef = useRef(null)
  useEffect(() => {
    modalRed.current.style.display = "none"
  }, [])
  const handleCloseModal = () => {
    modalRed.current.style.display = "none"
  }
  const handleOpenModal = () => {
    modalRed.current.style.display = "flex"
  }


  const close = (e) => {
    if (e.target.id == "myModal") {
      modalRed.current.style.display = "none"
    }
  }

  return (
    <>



      <button className='videoTutDiv' id="myBtn" onClick={handleOpenModal}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 11.5V16.5" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 7.50916L12.01 7.49805" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#3B79F6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <p >Как пользоваться трекером?</p>
        </div>

      </button>


      <div className="modalForTutorial" id="myModal" ref={modalRed} onClick={close}>


        <div className="modal-content" onClick={close}>
          <div className="modal-header">
            <span className="close" onClick={handleCloseModal}>&times;</span>
          </div>
          <div className="modal-body">

            <video width="750" height="500" controls  >
              <source src={video} type="video/mp4" />
            </video>
          </div>

        </div>

      </div>
    </>
  )
}   