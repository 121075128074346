import { Input,InputGroup,InputLeftAddon,InputRightAddon,Stack } from '@chakra-ui/react'
import "./inputOzon.css"
import { useState } from 'react'

export default function ImputOzon({type,item}) {
    const [value,setValue]=useState([])
    let atributeParams =  {
        complex_id: 0,
        id: item.id,
        values: [
          {
                value:null
          }
        ]
      }

      const handleChange=(e)=>{
           
        atributeParams.values[0].value = e.target.value
        setValue([atributeParams])
        console.log(atributeParams);
  }
    
                if (item.type == "String") {
                    return(
                        <div className='inputOzon'>
                            <label htmlFor="">{item.name}</label>
                            <Input placeholder={item.name} type={"text"} defaultValue={""} onChange={handleChange} required={item.is_required}/>
                            <input type="hidden" name="inputOzon"  value={JSON.stringify(value)}  />
                        </div>
                    )
                }else if(item.type == "Integer"){
                    return(
                        <div className='inputOzon'>
                            <label htmlFor="">{item.name}</label>
                            <Input placeholder={item.name} type={"number"} defaultValue={""} onChange={handleChange} required={item.is_required}/>
                            <input type="hidden" name="inputOzon"  value={JSON.stringify(value)}/>
                        </div>
                    )
                }
                else if(item.type == "URL"){
                    return(
                        <div style={{width:"100%",display:"flex",flexDirection:"column"}}>
                            <label htmlFor="">{item.name}</label>
                            <InputGroup  >
                            <InputLeftAddon children='https://'/>
                            <Input placeholder='mysite' defaultValue={""} onChange={handleChange} required={item.is_required}/>
                            <input type="hidden" name="inputOzon"  value={JSON.stringify(value)}/>
                           
                        </InputGroup>
                        </div>
                    )
                }
           
}