// reducer.js
const getAllTaskInitialState = {
    users: [],
    loading: false,
    error: null
  };
  
  const getAllTaskReducer = (state = getAllTaskInitialState, action) => {
           
    switch (action.type) {
      case 'FETCH_GETALLTASK_REQUEST':
        return {
          ...state,
          loading: true,
          error: null
        };
      case 'FETCH_GETALLTASK_SUCCESS':
        return {
          ...state,
          loading: false,
          users: action.payload
        };
      case 'FETCH_GETALLTASK_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload
        };
      default:
        return state;
    }
  };
  
  export default getAllTaskReducer;
  