import axios from "axios"
import { useDispatch } from "react-redux"
import { useState } from "react"
import Spinner from 'react-bootstrap/Spinner';
import { getAdminAllAction } from "../../../../../redux/reducer/getAllAdmin/action/action";
import { useToast } from '@chakra-ui/react'
export default function DeleteAdmin({item,size,currentPage}) {

    const dispatch = useDispatch()

    const [loading,setLoading] = useState(false)
    const toast = useToast()

    const handleDelete=(e)=>{
        setLoading(true)
        axios.delete('/deleteAdmin/api', {
           data:{
            adminId:e._id
           }
          })
          .then(function (response) {
            console.log(response);
            dispatch(getAdminAllAction(`/getalladmin/api?page=${1}`));
            setLoading(false)
            toast({
              position: 'top',
              title: 'Account created.',
              description: response.data.message,
              status: 'success',
              duration: 3000,
              isClosable: true,
            })
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false)
            toast({
              position: 'top',
              title: 'Ошибка.',
              description: error.response.data.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          });
    }
    

           if (loading) {
             return(
                <Spinner animation="border" style={{color:"#DB5F98"}} size="sm" />
             )
           }
           return   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" onClick={()=> handleDelete(item)}>
           <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 6.5V4H15V6.5H8.5ZM7.75 8H15.75H20.75C21.1642 8 21.5 7.66421 21.5 7.25C21.5 6.83579 21.1642 6.5 20.75 6.5H16.5V3.85C16.5 3.10444 15.8956 2.5 15.15 2.5H8.35C7.60442 2.5 7 3.10442 7 3.85V6.5H2.75C2.33579 6.5 2 6.83579 2 7.25C2 7.66421 2.33579 8 2.75 8H7.75ZM5.5 11.25C5.5 10.8358 5.16421 10.5 4.75 10.5C4.33579 10.5 4 10.8358 4 11.25V20.65C4 21.3956 4.60444 22 5.35 22H18.15C18.8956 22 19.5 21.3956 19.5 20.65V11.25C19.5 10.8358 19.1642 10.5 18.75 10.5C18.3358 10.5 18 10.8358 18 11.25V20.5H5.5V11.25ZM10.5 11.25C10.5 10.8358 10.1642 10.5 9.75 10.5C9.33579 10.5 9 10.8358 9 11.25V17.25C9 17.6642 9.33579 18 9.75 18C10.1642 18 10.5 17.6642 10.5 17.25V11.25ZM13.75 10.5C14.1642 10.5 14.5 10.8358 14.5 11.25V17.25C14.5 17.6642 14.1642 18 13.75 18C13.3358 18 13 17.6642 13 17.25V11.25C13 10.8358 13.3358 10.5 13.75 10.5Z" fill="#DD5E98"/>
         </svg>

          
}