import { useEffect } from "react";
import DownloadButton from "../test/dowNloadYesy"
import FileUploadTes from "../test/upload multiple files with progress indicator/uploadTestProgress"
import "./homeLight.css"
import { Helmet } from "react-helmet";


export default function Home() {
    const fileUrl = 'https://example.com/sample-video.mp4';
    const fileName = 'sample-video.mp4';
  
    // return       <DownloadButton downloadUrl={fileUrl} filename={fileName} />
    
    useEffect(()=>{
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Часовой пояс пользователя
        const userLocale = Intl.DateTimeFormat().resolvedOptions().locale; // Локализация пользователя
        console.log(userTimeZone,"userTimeZone");
        console.log(userLocale,"userLocale");
        
        
        
    },[])
    return (
        <div className="glavimg">
                  <Helmet>
        <title> Бесплатный таск-трекер для управления задачами и командой | Упрости.рф</title>
        <meta name="description" content="Управляйте задачами и командой на маркетплейсах легко и бесплатно. Простой и интуитивный таск-трекер с внутренним чатом и поддержкой мобильной версии. Начните работать эффективнее уже сегодня!" />
      </Helmet>
            <img src="/glav.svg" alt="Glav SVG" />
            {/* <FileUploadTes /> */}
        </div>
    )
}