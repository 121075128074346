import React, { useCallback, useRef } from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import './index.css';
import CustomNode from './customMode';

// Стиль для фона графа
const rfStyle = {
  backgroundColor: '#B8CEFF',
};

// Начальные узлы графа
const initialNodes = [
  {
    id: '0',
    type: 'input', // Тип узла, можно изменить на 'custom' для использования пользовательского узла
    data: {
      label: `Node `,
      description: 'Описание нового узла',
      additionalInfo: 'Дополнительная информация',
      value: 1234,
    },
    position: { x: 0, y: 50 }, // Начальная позиция узла
  },
];

// Генератор уникальных идентификаторов для узлов
let id = 1;
const getId = () => `${id++}`;
const nodeOrigin = [0.5, 0]; // Центр узла

// Определяем пользовательские типы узлов
const nodeTypes = { custom: CustomNode };

const GraphCompany = () => {
    const reactFlowWrapper = useRef(null); // Ссылка на обертку React Flow

    // Хук для управления состоянием узлов
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    // Хук для управления состоянием ребер
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const { screenToFlowPosition } = useReactFlow(); // Функция для преобразования координат экрана в координаты графа

    // Обработчик подключения новых узлов
    const onConnect = useCallback(
      (params) => setEdges((eds) => addEdge(params, eds)),
      [],
    );

    // Обработчик завершения подключения (при отпускании мыши)
    const onConnectEnd = useCallback(
      (event, connectionState) => {
        if (!connectionState.isValid) {
          const id = getId(); // Получаем новый идентификатор для узла
          const { clientX, clientY } =
            'changedTouches' in event ? event.changedTouches[0] : event; // Получаем координаты курсора

          const newNode = {
            id,
            type: 'custom', // Указываем тип узла
            position: screenToFlowPosition({
              x: clientX,
              y: clientY,
            }), // Преобразуем координаты
            data: {
              label: `Node ${nodes.length + 1}`,
              description: 'Описание нового узла',
              additionalInfo: 'Дополнительная информация',
              value: 1234,
            },
            origin: [0.5, 0.0], // Центр узла
          };

          // Обновляем состояние узлов и ребер
          setNodes((nds) => nds.concat(newNode));
          setEdges((eds) =>
            eds.concat({ id, source: connectionState.fromNode.id, target: id }),
          );
        }
      },
      [screenToFlowPosition, nodes.length],
    );

    // Функция удаления узла по его идентификатору
    const deleteNode = useCallback((nodeId) => {
      setNodes((nds) => nds.filter((node) => node.id !== nodeId)); // Удаляем узел
      setEdges((eds) => eds.filter((edge) => edge.source !== nodeId && edge.target !== nodeId)); // Удаляем связанные ребра
    }, []);

    return (
      <div className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes.map((node) => ({
            ...node,
            data: { ...node.data, onDelete: () => deleteNode(node.id) }, // Передаем функцию удаления в данные узла
          }))}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectEnd={onConnectEnd}
          fitView // Автоматическая подстройка под вид
          fitViewOptions={{ padding: 2 }} // Опции подстройки
          nodeOrigin={nodeOrigin} // Центр узлов
          nodeTypes={nodeTypes} // Типы узлов
          style={rfStyle} // Стиль графа
        />
      </div>
    );
};
export default () => (
    <ReactFlowProvider>
      <GraphCompany />
    </ReactFlowProvider>
  );
  