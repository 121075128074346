import axios from "axios"
import { useDispatch,useSelector } from "react-redux"
import { getCaompanyAllAction } from "../../../../../redux/reducer/getAllCompanyForAdmin/action/action"
import { useState } from "react"
import Spinner from 'react-bootstrap/Spinner';
import { useToast } from '@chakra-ui/react'
export default function DeleteCompanyByAdmin({item,size}) {

    const dispatch = useDispatch()
    const getAllCompany = useSelector(compnay => compnay)
    const [loading,setLoading] = useState(false)
    const toast = useToast()

    const handleDelete=(e)=>{
        setLoading(true)
        axios.delete('/mycopany/adminPanel/delete', {
           data:{
            companyId:e._id
           }
          })
          .then(function (response) {
            console.log(response);
            dispatch(getCaompanyAllAction("/getAllCompant/admin/api"))
            setLoading(false)
          })
          .catch(function (error) {
            console.log(error);
            setLoading(false)
            toast({
              position:"top",
              title: 'Ошибка.',
              description: error.response.data.message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            })
          });
    }
    

           if (loading) {
             return(
                <Spinner animation="border" style={{color:"#DB5F98"}} size="sm" />
             )
           }
           return  <span className="material-symbols-outlined deletButtonComp" style={{color:"#DB5F98"}} onClick={()=> handleDelete(item)}>delete</span>
}