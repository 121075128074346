import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure
} from '@chakra-ui/react';
import "./firstVisitLight.css"
import "./firstVisitDark.css"

import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';


export default function FirstVisit(params) {
    const [firstVisitHandled, setFirstVisitHandled] = useState(false);  // Отслеживаем, был ли уже открыт модал и отправлен запрос
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const sendPatchRequest = async () => {
        try {
            axios.post('updateVisit/api', {
                firstName: 'Fred',
                lastName: 'Flintstone'
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.error(error);

        }
    };
    useEffect(() => {
        if (getmyinfo.myinfo instanceof Object) {
            // Проверяем, если это первый визит и запрос еще не был обработан
            if (getmyinfo.myinfo.firstVisit === false && !firstVisitHandled) {
                onOpen();
                sendPatchRequest();
                setFirstVisitHandled(true);  // Устанавливаем флаг, что модальное окно было открыто и запрос отправлен
            }
        }
    }, [getmyinfo.myinfo, firstVisitHandled, onOpen]);
    if (getDarkModoe.ToggleDarkModeReducer) {
        return (
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent className={getDarkModoe.ToggleDarkModeReducer.chekced ? "firstVisitDark" : "firstVisitLight"}>
                    <ModalHeader>
                        <p>Завершите регистрацию</p>
                        <div  onClick={onClose}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M10.6712 12L7.27521 8.60406C6.90826 8.23711 6.90826 7.64216 7.27521 7.27521C7.64216 6.90826 8.23711 6.90826 8.60406 7.27521L12 10.6712L15.3959 7.27521C15.7629 6.90826 16.3578 6.90826 16.7248 7.27521C17.0917 7.64216 17.0917 8.23711 16.7248 8.60406L13.3288 12L16.7248 15.3959C17.0917 15.7629 17.0917 16.3578 16.7248 16.7248C16.3578 17.0917 15.7629 17.0917 15.3959 16.7248L12 13.3288L8.60406 16.7248C8.23711 17.0917 7.64216 17.0917 7.27521 16.7248C6.90826 16.3578 6.90826 15.7629 7.27521 15.3959L10.6712 12Z" fill="#DDD6D6" />
                            </svg>
                        </div>
                    </ModalHeader>

                    <ModalBody>
                        <p>Введите все доступные данные для создания профиля </p>
                    </ModalBody>

                    <ModalFooter>
                        <button onClick={onClose}>
                            <p>Отменить</p>
                        </button>

                        <button onClick={() => [navigate("/settings/mydata"), onClose()]}>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                                    <path d="M15 11.5L11.5 15M7 11.5H15H7ZM15 11.5L11.5 8L15 11.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M11 21.5C16.5228 21.5 21 17.0228 21 11.5C21 5.97715 16.5228 1.5 11 1.5C5.47715 1.5 1 5.97715 1 11.5C1 17.0228 5.47715 21.5 11 21.5Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <p>Заполнить данные</p></button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

}