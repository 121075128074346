
import { Helmet } from "react-helmet"
import ChtatLeftSide from "../chtatLeftSide/chtatLeftSide"





export default function ChatContainer(params) {
    return (
        <>
            <Helmet>
                <title>Чат для команд в таск-трекере — Общайтесь эффективно</title>
                <meta name="description" content="
                Встроенный чат для оперативного общения и обмена файлами. Поддерживайте связь с командой, обсуждайте задачи и принимайте решения в реальном времени. Бесплатно и удобно!
                " />
            </Helmet>
            <ChtatLeftSide />
        </>

    )
}